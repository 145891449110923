import React from 'react';
import {controlApi, homeWorkApi} from '@/api';
import {jsonConvert} from '@/helpers/jsonConvert';
import {useLocation} from 'react-router-dom';

export const useTaskHomeWork = (
    params,
    {children_id, homeWork_id, id, type = 'text'},
    noRightAnswer,
    files = [],
    multiple = false,
    setIsEditing = () => { }
) => {

    const [answer, setAnswer] = React.useState(() => {
        const answerParam = params?.result?.answer;

        if (typeof answerParam === 'string' && answerParam) {
            try {
                return JSON.parse(answerParam);
            } catch (e) {
                return answerParam;
            }
        }

        return type === 'arr' ? [] : '';
    });
    
    const [hint, setHint] = React.useState(params?.result?.prompt ?? '');
    const [video, setVideo] = React.useState(
        (params?.result?.video_source_type == 'youtube' || !params?.result?.video_source_type) 
                                ? params?.result?.video_solution : (params?.result?.video_source_type == 'platform') 
                                ? params?.result?.video_solution_platform_media : (params?.result?.video_source_type == 'iframe') 
                                ? params?.result?.video_solution_iframe : '' 
    );
    const [videoType, setVideoType] = React.useState(
        params?.result?.video_source_type ?? ''
    );
    const [loading, setLoading] = React.useState(false);
    const [correctAnswer, setCorrectAnswer] = React.useState(
        jsonConvert(params?.result?.correct_answer ?? '', 'json') ?? ''
    );
    const loc = useLocation()
    const setRowValue = (value) =>
        params?.setStatRowValues?.((prev) => {
                return prev.map((item) => (item.id === id ? {...item, value} : item))
            }
        );

    const getAnswer = ()=>{
        try {
            if(type === 'arr' || multiple){
                const res = answer.map(item => {
                    for (let key in item) {
                        if(typeof item[key] === 'string'){
                            item[key] = item[key]?.trim() ?? item[key]
                        }
                    }
                    return item
                });
                return jsonConvert(res)
            }else{
                if(typeof answer === 'string'){
                    return answer?.trim()
                }
                return answer
            }
        } catch (error) {
            return answer
        }
    }

    const onAnswer = (e) => {
        e.preventDefault();
        setLoading(true);
        
        const data = new FormData();
        data.append('answer', getAnswer());
        data.append('children_id', children_id);
        //data.append('answer', type === 'arr' ? jsonConvert(answer) : answer);
        data.append('answer_type', type === 'file' ? 'file' : 'text');
        data.append('task_id', id);

        files?.forEach((file => {
            data.append('answer_files[]', file);
        }))

        const api = () => (params.type === 'control' ? controlApi : homeWorkApi);

        api()
            .sendAnswer({id: homeWork_id, data})
            .then((res) => {
                setLoading(false);
                setIsEditing(false);
                if (res.data?.status) {
                    if (params.type === 'control') {
                        if (res.data.data.decided) {
                            if (noRightAnswer) params.status.set(5);
                            else params.status.set(6);
                            setRowValue(100);
                        } else {
                            params.status.set(7);
                            setRowValue(20);
                        }
                    } else {
                        if (res.data.data?.correct_answer)
                            setCorrectAnswer(
                                jsonConvert(res.data.data?.correct_answer, 'json')
                            );

                        if (res.data.data?.decided) setRowValue(100);
                        else setRowValue(20);

                        if (res.data.data.decided) {
                            if (noRightAnswer) params.status.set(5);
                            else params.status.set(6);
                        } else if (res.data.data.attempt === 1) {

                            params.status.set(7);
                        } else if (res.data.data.attempt > 1) {
                            params.status.set(2);
                        } else if (res.data.data.attempt === 1 && loc.search === '?redo=ok') {
                            params.status.set(7);
                        } else if (res.data.data.attempt < 1 && loc.search === '?redo=ok') {
                            params.status.set(2);
                        }
                        setHint(res.data.data?.prompt);
                        setVideo(
                            (res.data.data?.video_source_type == 'youtube' || !res.data.data?.video_source_type) 
                                ? res.data.data?.video_solution : (res.data.data?.video_source_type == 'platform') 
                                ? res.data.data?.video_solution_platform_media : (res.data.data?.video_source_type == 'iframe') 
                                ? res.data.data?.video_solution_iframe : ''
                        ); 
                        setVideoType(res.data.data?.video_source_type)
                    }
                } else console.error('status error');
            });
    };

    return {
        onAnswer: {
            handler: onAnswer,
            loading,
            isAnswer: !!answer,
        },
        answer: {get: answer, set: setAnswer},
        status: params?.status?.get,
        hint,
        video,
        videoType,
        correctAnswer,
    };
};
