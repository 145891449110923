import { useState } from 'react';
import s from './index.module.css';

const Range = ({ value = '', onChange = () => {}, min = 0, max = 1000, step = 1 }) => {
    const handleChange = (event) => {
        const currentValue = parseInt(event.target.value);

        // Округляем значение до ближайшего кратного step
        let newValue = Math.round(currentValue / step) * step;

        // Если значение близко к максимуму (в пределах шага), устанавливаем максимум
        if (max - newValue < step) {
            newValue = max;
        }

        // Ограничиваем значение в пределах min и max
        newValue = Math.min(Math.max(newValue, min), max);

        onChange(newValue);
    };

    // const fillWidth = (value / max) * 100;
    const fillWidth = ((value - min) / (max - min)) * 100;

    return (
        <div className={s.inputWrapper}>
            <input
                type="range"
                min={min}
                max={max}
                step={1}
                value={value}
                onChange={handleChange}
                style={{ background: `linear-gradient(-90deg, #F9EDFF, #F9EDFF ${100 - fillWidth}%, #9747FF ${100 - fillWidth}%, #9747FF 100%)` }}
                className={s.input}
            />
            <span className={s.start}>{min}</span>
            <span className={s.end}>{max}</span>
            <span className={s.value}>{value}</span>
        </div>
    );
};

export default Range;
