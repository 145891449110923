import s from './index.module.css'
import style from '../../index.module.css'
import commonStyle from '../Common/common.module.css'
import { useInputNew } from '../../../../../UI/InputNew/useInputNew'
import { InputNew } from '../../../../../UI/InputNew'
import CheckboxViolet from '@UI/New/ChecboxViolet/CheckboxViolet'
import Textarea from '@UI/Textarea'
import { usersAPI } from "@/api"
import { useEffect, useState } from 'react'
import classNames from 'classnames'
import Search from "@UI/Search/new"
import Preloader from '@UI/Preloader'
import { useAddAlert } from '@/hooks/useAddAlert'

export const Video = ({ data, edit }) => {
  const addAlert = useAddAlert();

  const [bindName] = useInputNew({
    name: 'video_solution',
    value: data?.video_solution,
    onChange: e => edit(prev => ({ ...prev, 'video_solution': e })),
    placeholder: 'Ссылка на видео',
  })

  const switchVideoSourceType = (type) => edit(prev => ({ ...prev, 'video_source_type': type }))

  const [mediaContent, setMediaContent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState('');

  const filterParams = []
  filterParams.push(`pageSize=500&filter_sort=type_file&filter_name=Видео`)
  if(typeof search === 'string' && search.trim() !== ''){
      filterParams.push(`search=${search.trim()}`)
  }
  
  useEffect(()=>{
     const filters = `?${filterParams.join('&')}`;

      usersAPI.getUserFiles(filters)
      .then(res => {
          if (!res || !res.data) {
             addAlert({title: 'Ошибка', text: 'При загрузке файлов произошла ошибка', type: 'error'})
          }
          else{
            setMediaContent(res.data.files);
          }
      })
      .catch(error => {
          console.error('Ошибка при получении файлов:', error.message);
      })
      .finally(() => {
          setIsLoading(false);
      });
      
  }, [search])

  const selectingMaterialFromLibrary = (elem) => {
    if(data?.video_solution_platform_media === elem?.id && elem?.id){
      edit(prev => ({ ...prev, 'video_solution_platform_media': '' }))
    }
    else{
      edit(prev => ({ ...prev, 'video_solution_platform_media': elem.id }))
    }
  }

  const chengeSwitchVideoSourceType = (type) => {
    if(type === data.video_source_type){
      switchVideoSourceType('');
    }
    else{
      switchVideoSourceType(type);
    }
  }

  return <div className={s.wrapper}>
    <span className={style.heading}>Показать решение</span>
    <div className={s.content}>
      <div className={s.checkboxWrapper}>
          <CheckboxViolet 
            text='Ссылка на видеорешение с YouTube' 
            onClick={() => chengeSwitchVideoSourceType('youtube')} 
            value = {data.video_source_type === 'youtube'} />
          <CheckboxViolet 
            text='Выбор из платформ медиа' 
            onClick={ () => chengeSwitchVideoSourceType('platform') } 
            value = {data.video_source_type === 'platform'} />
          <CheckboxViolet 
            text='Вставить iframe' 
            onClick = { () => chengeSwitchVideoSourceType('iframe') }
            value = {data.video_source_type === 'iframe'} />
      </div>
      {
        data.video_source_type === 'youtube' && <div className={s.optionWrapper}>
          <span className={classNames(style.heading, s.text)}>Ссылка на видеорешение с YouTube:</span>
          <InputNew classNameForBox={commonStyle.inputBox} className={style.inputNew} {...bindName} />
        </div>
      }
      {
        data.video_source_type === 'iframe' && <div className={s.optionWrapper}>
          <span className={classNames(style.heading, s.text)}>Вставьте iframe:</span>
          <Textarea
            className={commonStyle.inputBox}
            value={data.video_solution_iframe ?? ''} 
            onChange={e => {
              edit(prev => ({ ...prev, 'video_solution_iframe': e.target.value }))
            }} 
            placeholder='Вставьте iframe' 
            style={{width:'100%'}}
            />
        </div>
      }
      {
        data.video_source_type === 'platform' && <div className={s.optionWrapper}>
          <span className={classNames(style.heading, s.text)}>Выбор из платформ медиа:</span>
          <div className={classNames(s.mediaWrapper, 'customScroll')}>
          <Search className={classNames('searchNewDesign', 'backgroundNone')} {...{setSearch}} />
          {
            isLoading ? <Preloader /> : mediaContent?.map((el)=>{
              return(
                <div
                  key={el.id} 
                  onClick={() => selectingMaterialFromLibrary(el) }
                  className={classNames(s.optionMedia, {[s.optionMediaActive]: data.video_solution_platform_media === el.id })}>
                    { el.name }
                </div>
              )
            })
          }
             
          </div>
        </div>
      }
    </div>
   
  </div>
}
