
import { checkFile } from '../../../../helpers/checkFile'
import s from './Images.module.css'


export const Images = ({ images, imageSize = 100 }) => {
  const check = item => checkFile.img(item.file_path)
  return <ul className={s.list}>
    {images?.map((file, i) =>
      <li key={i} className={s.item}>
        <a href={process.env.REACT_APP_PATH_FILE + file.file_path} className={s.link} target="_blank" rel="noreferrer">
          { check(file)
            ? <img src={process.env.REACT_APP_PATH_FILE + file.file_path} alt='' style={{ width: `${imageSize}%` }} />
              : "Открыть вложенный файл"
          }
        </a>
      </li>
    )}
  </ul>
}
