import cn from 'classnames'
import React from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { jsonConvert } from '../../../../../../helpers/jsonConvert'
import { ButtonDel } from '../../../../../UI/Button'
import { InputFormate } from '../../../../../UI/InputNew'
import { useInputNew } from '../../../../../UI/InputNew/useInputNew'
import s from './index.module.css'
import { ButtonRemove } from '../Common/Buttons'
import commonStyle from '../Common/common.module.css'
import classNames from 'classnames';

// логика
export const DragItem = ({ id, index, moveCard, ...props }) => {
  const ref = React.useRef(null)
  const [{ handlerId }, drop] = useDrop({
    accept: 'item',
    collect(monitor) { return { handlerId: monitor.getHandlerId() } },
    hover(item, monitor) {
      if (!ref.current) return
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) return
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return
      moveCard(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
  })

  const [{ opacity }, drag] = useDrag({
    type: 'item',
    item: () => ({ id, index }),
    collect: (monitor) => ({ opacity: monitor.isDragging() ? 0.2 : 1 })
  })

  drag(drop(ref))

  return <Item dRef={ref} {...{ opacity, handlerId, id, index, ...props }} />
}



// сама карточка
const Item = ({ dRef, opacity, handlerId, index, ...props }) => {

  const settingsItem = { ref: dRef, style: { opacity } }

  const [value, setValue] = React.useState(typeof jsonConvert(props?.text, 'json') === 'object' ? jsonConvert(props?.text, 'json') : props?.text ? {
    "type": "doc",
    "content": [{ "type": "paragraph", "content": [{ "type": "text", "text": props?.text }] }]
  } : { "type": "doc", "content": [{ "type": "paragraph", }] })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => { props.editVal({ key: 'text', val: value, id: props.id }) }, [value])

  const [bindName] = useInputNew({
    name: `answer`,
    value: value,
    onChange: setValue,
    placeholder: 'Введите вариант',
  })

  if (props?.img)
    return <div {...settingsItem} className={s.inputWrapper}>
      <div className={cn('booble', s.input)}>{props.img}</div>
      <ButtonDel onClick={() => props.editVal({ key: 'img', val: '', id: props.id })} />
    </div>

  return (
    <div className={s.row}>
      <div {...settingsItem} className={s.inputWrapper}>


        <InputFormate 
          tiptapClassContent={commonStyle.tiptapClassContent}
          tiptapClassContentProseMirror={commonStyle.tiptapClassContentProseMirror}
          className={classNames(s.inputNew, commonStyle.inputFormate)}
          {...bindName} />

        {process.env.REACT_APP_DEV_MODE === '1' && <span className="error">{props.id} </span>}

        {/* <label className={s.btnImg}>
          <input disabled type="file" className='hide' onChange={e => props.editVal({ key: 'img', val: e.target.value, id: props.id })} />
          <GetIco icon='picture' />
        </label> */}
        {props.remove && <ButtonRemove onClick={() => props.remove(index)} /> }
      </div>
    </div>
  )
}
