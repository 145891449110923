import "./template/styles";
import React from "react";
import Routing from "./routes/Routes";
import Alerts from "@common/Alerts";
import Modals from "@/components/modals";
import { Provider } from "react-redux";
import store from "@store/redux.store";
import { ConfigProvider } from "antd";
import locale from "antd/es/locale/ru_RU";
import "dayjs/locale/ru";
import { useScreenWidth } from "@hooks/useScreenWidth";
import Confirm from "./components/UI/Confirm/Confirm";
import axios from "axios";
import {API} from "@/api";

const App = () => {
  const isMobile = useScreenWidth(992);
    API.get('/setting/get-app-name').then(res => {
      document.title = res.data || (process.env.REACT_APP_PROJECT_NAME || 'Impulse');
    })
    .catch(error => {
      console.error('Error loading config:', error);
      document.title = process.env.REACT_APP_PROJECT_NAME || 'Impulse'; // Fallback title
    });
  const theme = {
    token: {
      colorPrimary: "#6e368c",
      colorLink: "inherit",
      fontFamily: `Montserrat, -apple-system`,
      borderRadius: isMobile ? 12 : 16,
      controlHeight: isMobile ? 42 : 54,
    },
  };

  return (
    <Provider {...{ store }}>
      <ConfigProvider {...{ locale, theme }}>
        <Alerts />
        <Modals />
        <Confirm />
        <Routing />
      </ConfigProvider>
    </Provider>
  );
};

export default App;
