import s from './index.module.css'
import style from '../../index.module.css'
import commonStyle from '../Common/common.module.css'
import UploadFile from '../../../../../UI/UploadFile'
import { ButtonAdd, ButtonRemove } from '../Common/Buttons'
import { useEffect, useState } from 'react'
import { homeTaskApi } from '../../../../../../api'
import { Input } from 'antd'

export const Images = ({ data, edit }) => {
  const [fileList, setFilterList] = useState(data?.homeTaskFiles?.filter(item => item.type === 'image'))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { setFilterList(data?.homeTaskFiles?.filter(item => item.type === 'image')) }, [])
  const removeFile = id => homeTaskApi.removeFile(id).then(res => {
    if (res.data.status === 'success') setFilterList(prev => [...prev.filter(item => item.id !== id)])
  })

  const add = () => edit(prev => ({ ...prev, images: [...prev.images, { file: {} }] }))
  const remove = i => edit(prev => ({ ...prev, images: [...prev.images.filter((_, index) => index !== i)] }))
  const input = (val, i) => edit(prev => ({
    ...prev, images: [...prev.images?.map((item, index) => ((i === index) ? { file: val } : item))]
  }))
  const updateSize = (e) => {
    let value = e.target.value;
    if (value === '' || Number(value) >= 1) {
      edit((prev) => ({
        ...prev,
        image_size: value === '' ? '' : Number(value)
      }));
    }
  }

  const handleSizeBlur = () => {
    edit((prev) => ({
      ...prev,
      image_size: prev.image_size === '' ? 100 : prev.image_size,
    }));
  }

  return <div className={s.wrapper}>
    <span className={style.heading}>Изображения:</span>
    <div className={s.list}>
      {fileList?.map((item, index) =>
        <Item
          key={index}
          index={index}
          value={item.file_path}
          input={input}
          showMode={true}
          remove={() => removeFile(item.id)}
          size={data?.image_size}
          setSize={updateSize}
          handleBlur={handleSizeBlur}
          isFirstImage={index === 0}
        />
      )}
      {data.images?.map((item, index) =>
        <Item
          key={index}
          index={index}
          value={item.file}
          input={input}
          remove={() => remove(index)}
          size={data?.image_size}
          setSize={updateSize}
          handleBlur={handleSizeBlur}
          isFirstImage={index === 0 && (!fileList || fileList.length === 0)}
        />
      )}
       <ButtonAdd onClick={add} />
    </div>
  </div>
}

const Item = ({ index, value, input, remove, showMode = false, size, setSize, handleBlur, isFirstImage }) => {
  return <div className={s.row}>
    <UploadFile className={commonStyle.file} value={value} onChange={val => input(val, index)} showMode={showMode} />
    <ButtonRemove onClick={remove} />

    {isFirstImage && (
      <div className={s.setSizeWpapper}>
        <span className={s.sizeSpan}>Размер</span>
        <div className={s.sizeContainer}>
          <Input
            type="number"
            className={s.sizeInput}
            value={size === '' ? '' : size}
            onBlur={handleBlur}
            onChange={setSize}
            min="1"
            max="100"
            step="1"
          />
          <span>%</span>
        </div>
      </div>
    )}
  </div>
}
