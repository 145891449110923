import { generateHTML } from '@tiptap/core'
import TextStyle from '@tiptap/extension-text-style'
import Color from '@tiptap/extension-color'
import StarterKit from '@tiptap/starter-kit'
import { jsonConvert } from '../../../helpers/jsonConvert'
import Underline from '@tiptap/extension-underline'
import Math from '../Tiptap/extension-math'
import { BlockMath } from 'react-katex';
import Link from '@tiptap/extension-link'

const JsonPars = ({ json }) => {
  const data = jsonConvert(json, 'json')
  if (typeof data === 'string') return data
  if (typeof data === 'number') return data
  else if (data?.type === undefined) return ''
  return <Html data={data} />
}

const Html = ({ data }) => {
  const formatString = (text) => {
    let newText = text.replace(/ /g, '\\,').replace(/\n/g, '\\\\'); // заменяем пробелы и переносы на те, которые будут здесь работать
  
    const mathSymbolsRegex = /([+=*:<>∩∪≤≥∈∥-])/g; // Обрабатываем +, =, *, -, <, >, ∩, ∪,
    newText = newText.replace(mathSymbolsRegex, '\\text{$1}');

    // Обрабатываем \cap и \cup
    newText = newText.replace(/\\cap/g, '~\\cap~');
    newText = newText.replace(/\\cup/g, '~\\cup~');
    newText = newText.replace(/\\leqslant/g, '~\\leqslant~');
    newText = newText.replace(/\\geqslant/g, '~\\geqslant~');
    newText = newText.replace(/\\parallel/g, '~\\parallel~');
    newText = newText.replace(/\\notin/g, '~\\notin~');
    newText = newText.replace(/\\in/g, '~\\in~');
    newText = newText.replace(/\\cdot/g, '~\\cdot~');
    newText = newText.replace(/%/g, '\\%');

    return newText;
  };
  return <>
    {data?.content?.map((item, i) => {
      if (item?.type === 'math') {
        const formattedText = formatString(item?.attrs?.value);
        return <BlockMath key={i}>{formattedText}</BlockMath>
      }
      return <span className='tiptapStyle' key={i} dangerouslySetInnerHTML={{ __html: generateHTML({ type: 'doc', content: [item] }, [StarterKit, Color, Underline, TextStyle, Math, Link]) }} />
    })}
  </>
}

export default JsonPars;
