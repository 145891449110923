import s from './index.module.css'
import style from '../../index.module.css'
import commonStyle from '../Common/common.module.css'
import { ButtonDel } from '../../../../../UI/Button'
import classNames from 'classnames'
import { useInputNew } from '../../../../../UI/InputNew/useInputNew'
import { InputTextarea } from '../../../../../UI/InputNew'
import { ButtonAdd, ButtonRemove } from '../Common/Buttons'

export const Weight = ({ data, edit, setErrorsList, showErrors }) => {

  const add = () => edit(prev => ({ ...prev, 'exam_weight': [...prev.exam_weight, { score: prev.exam_weight.length, description: '' }] }))
  const remove = i => {
    edit(prev => ({ ...prev, 'exam_weight': [...prev.exam_weight.filter((_, index) => index !== i)] }))
    setErrorsList(prev => ({ ...prev, items: { ...prev.items, [`${i}_weight-option`]: { value: false } } }))
  }

  const input = (e, i) => edit(prev => ({
    ...prev, 'exam_weight': [...prev.exam_weight?.map((item, index) => ((i === index) ? { ...item, description: e } : item))]
  }))

  if (typeof data?.exam_weight !== 'object') edit(prev => ({ ...prev, 'exam_weight': [] }))


  return <div className={s.wrapper}>
    <span className={style.heading}>Вес задания на ЕГЭ:</span>
    <div className={s.list}>
      {typeof data?.exam_weight === 'object' && data?.exam_weight?.map((el, index) =>
        <Item
          key={el.score}
          el={el}
          index={index}
          last={index === data['exam_weight'].length - 1}
          edit={e => input(e, index)}
          remove={() => remove(index)}
          setErrorsList={setErrorsList} showErrors={showErrors}
        />)}
      <ButtonAdd onClick={add} />
    </div>
  </div>
}

const Item = ({ el, edit, last, index, remove, setErrorsList, showErrors }) => {
  const [bindName] = useInputNew({
    name: `${el.devID}_weight`,
    value: el?.description,
    onChange: edit,
    onError: setErrorsList,
    placeholder: `Напишите описание`,
    validate: showErrors,
    errorText: 'Удалите или заполните описание',
  })
  return <div key={index} className={s.row}>
    <span className={s.input}>{el.score}</span>
    <InputTextarea classNameTextarea={commonStyle.inputBox} className={classNames(style.inputNew, s.textarea)} {...bindName} />

    {/* <Textarea onInput={e => edit(e, index)} placeholder='Напишите описание' disabled={index === 0 ? true : false} /> */}
    {last && index !== 0 && <ButtonRemove onClick={remove} />}
  </div>
}
