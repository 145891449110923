import * as C from './components';

export const listOptions = (type, flagEasily, params = {}) => {
	if (flagEasily === null) {
		flagEasily = 1;
	}
	switch (type) {
		case 'exact-answer':
			const resultExactAnswer = [
				C.Question,
				C.Images,
				C.Audios,
				C.CorrectAnswerMulti,
				C.NumberOfAttempts,
				C.Hint,
				C.Video,
			];
			
			if (flagEasily === 0) {
				resultExactAnswer.push(
					C.ExamNumber,
					C.Theme,
					C.Subtheme,
					C.Complexity,
					C.Key,
					C.Weight,
					C.IsTimedTask,
					C.DisplayHintAfterAllAttempts,
					C.IsNumberAnswer
				);
			} 
			else {
				if (params.themeVisible) {
					resultExactAnswer.push(C.Theme);
				}
				if (params.subThemeVisible) {
					resultExactAnswer.push(C.Subtheme);
				}
				resultExactAnswer.push(
					C.Key,
					C.IsTimedTask,
					C.DisplayHintAfterAllAttempts,
					C.IsNumberAnswer);
			}
			return resultExactAnswer;

		case 'order':
			const resultOrder = [
				C.Question,
				C.Images,
				C.Audios,
				C.Sequence,
				C.SequenceType,
				C.NumberOfAttempts,
				C.Hint,
				C.Video,
			];
			if (flagEasily === 0) {
				resultOrder.push(
					C.ExamNumber,
					C.Theme,
					C.Subtheme,
					C.Complexity,
					C.Key,
					C.Weight,
					C.IsTimedTask,
					C.DisplayHintAfterAllAttempts
				);
			} 
			else {
				if (params.themeVisible) {
					resultOrder.push(C.Theme);
				}
				if (params.subThemeVisible) {
					resultOrder.push(C.Subtheme);
				}
				resultOrder.push(
					C.Key,
					C.IsTimedTask,
					C.DisplayHintAfterAllAttempts
				);
			}
			return resultOrder;
			
		case 'multiple-choice':
			const resultMultipleChoice = [
				C.Question,
				C.Images,
				C.Audios,
				C.Sequence,
				C.OrderImportant,
				C.NumberOfAttempts,
				C.Hint,
				C.Video,
			];
			if (flagEasily === 0) {
				resultMultipleChoice.push(
					C.ExamNumber,
					C.Theme,
					C.Subtheme,
					C.Complexity,
					C.Key,
					C.Weight,
					C.IsTimedTask,
					C.DisplayHintAfterAllAttempts
				);
			} 
			else {
				if (params.themeVisible) {
					resultMultipleChoice.push(C.Theme);
				}
				if (params.subThemeVisible) {
					resultMultipleChoice.push(C.Subtheme);
				}
				resultMultipleChoice.push(
					C.Key,
					C.IsTimedTask,
					C.DisplayHintAfterAllAttempts
				);
			}
			return resultMultipleChoice;

		case 'test':
			const resultTest = [
				C.Question,
				C.Images,
				C.Audios,
				C.OptionsAnswer,
				C.CorrectAnswer,
				C.NumberOfAttempts,
				C.Hint,
				C.Video,
			];
			if (flagEasily === 0) {
				resultTest.push(
					C.ExamNumber,
					C.Theme,
					C.Subtheme,
					C.Complexity,
					C.Key,
					C.Weight,
					C.IsTimedTask,
					C.DisplayHintAfterAllAttempts
				);
			} 
			else {
				if (params.themeVisible) {
					resultTest.push(C.Theme);
				}
				if (params.subThemeVisible) {
					resultTest.push(C.Subtheme);
				}
				resultTest.push(
					C.Key,
					C.IsTimedTask,
					C.DisplayHintAfterAllAttempts
				);
			}
			return resultTest;

		case 'pass-words':
			const resultPassWords = [
				C.Question,
				C.Omissions,
				C.Images,
				C.Audios,
				C.NumberOfAttempts,
				C.Hint,
				C.Video,
			];
			if (flagEasily === 0) {
				resultPassWords.push(
					C.ExamNumber,
					C.Theme,
					C.Subtheme,
					C.Complexity,
					C.Key,
					C.Weight,
					C.IsTimedTask,
					C.DisplayHintAfterAllAttempts
				);
			} 
			else {
				if (params.themeVisible) {
					resultPassWords.push(C.Theme);
				}
				if (params.subThemeVisible) {
					resultPassWords.push(C.Subtheme);
				}
				resultPassWords.push(
					C.Key,
					C.IsTimedTask,
					C.DisplayHintAfterAllAttempts
				);
			}
			return resultPassWords;

		case 'match':
			const resultMatch = [
				C.Question,
				C.Images,
				C.Audios,
				C.MatchList,
				C.NumberOfAttempts,
				C.Hint,
				C.Video,
			];
			if (flagEasily === 0) {
				resultMatch.push(
					C.ExamNumber,
					C.Theme,
					C.Subtheme,
					C.Complexity,
					C.Key,
					C.Weight,
					C.IsTimedTask,
					C.DisplayHintAfterAllAttempts
				);
			} 
			else {
				if (params.themeVisible) {
					resultMatch.push(C.Theme);
				}
				if (params.subThemeVisible) {
					resultMatch.push(C.Subtheme);
				}
				resultMatch.push(
					C.Key,
					C.IsTimedTask,
					C.DisplayHintAfterAllAttempts
				);
			}
			return resultMatch;

		case 'probe-c':
			if (flagEasily === 0) {
				return [
					C.Question,
					C.Images,
					C.ExamNumber,
					C.Theme,
					C.Subtheme,
					C.Key,
					C.Weight,
				];
			} else {
				return [
					C.Question,
					C.Images,
					C.Key
				];
			}
		case 'detail-answer':
			if (flagEasily === 0) {
				return [
					C.Question,
					C.Images,
					C.Audios,
					C.Hint,
					C.Video,
					C.ExamNumber,
					C.Theme,
					C.Subtheme,
					C.Complexity,
					C.Key,
					C.Weight,
					C.CantUploadFiles,
					C.IsTimedTask,
				];
			} 
			else {
				const resultDetailAnswer = [
					C.Question,
					C.Images,
					C.Audios,
					C.Hint,
					C.Video
				]
				if (params.themeVisible) {
					resultDetailAnswer.push(C.Theme);
				}
				if (params.subThemeVisible) {
					resultDetailAnswer.push(C.Subtheme);
				}
				resultDetailAnswer.push(
					C.Key, 
					C.CantUploadFiles, 
					C.IsTimedTask
				);
				
				return resultDetailAnswer;
			}
		default:
			if (flagEasily === 0) {
				return [
					C.Question,
					C.Images,
					C.Audios,
					C.Hint,
					C.Video,
					C.ExamNumber,
					C.Theme,
					C.Subtheme,
					C.Complexity,
					C.Key,
					C.Weight,
					C.IsTimedTask,
				];
			} else {
				return [
					C.Question, 
					C.Images, 
					C.Audios, 
					C.Hint, 
					C.Video, 
					C.Key, 
					C.IsTimedTask,
				];
			}
	}
};