import s from './index.module.css'
import style from '../../index.module.css'
import commonStyle from '../Common/common.module.css'
import { useEffect, useState } from 'react'
import { ButtonDel } from '@UI/Button'
import classNames from 'classnames'
import { nextId } from '../../../../../../helpers/nextId'
import { InputNew, InputTextarea } from '../../../../../UI/InputNew'
import { useInputNew } from '../../../../../UI/InputNew/useInputNew'
import { ButtonAdd, ButtonRemove } from '../Common/Buttons'

export const Omissions = ({ data, edit, setErrorsList, showErrors }) => {
  const [state, setState] = useState(
    data?.answer_options
      ? data?.correct_answer
        ? data.answer_options?.map((item, index) => ({ ...item, ...data?.correct_answer?.find(el => el.index === item.index) ?? { text: '', index: item.index }, devID: index }))
        : data['answer_options']?.map((el, index) => ({ ...el, devID: index }))
      : []
  )

  useEffect(() => {
    const arrCorrect = []

    state?.forEach((el, index) => {
      if (el.text) arrCorrect.push({ text: el.text, index: index + 1 })
    })

    edit(prev => ({
      ...prev,
      'answer_options': [...state?.map((el, index) => ({ question: el.question, index: index + 1, answer: el.text ? 1 : 0 }))],
      'correct_answer': arrCorrect
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  const onEdit = (text, id) => setState([...state.map(variant => (variant.devID === id) ? { ...variant, text } : variant)])
  const onEditText = (question, id) => setState([...state.map(variant => (variant.devID === id) ? { ...variant, question } : variant)])

  const add = () => setState([...state, { text: '', question: '', devID: nextId(state, 'devID') }])
  const remove = id => {
    setState([...state?.filter(variant => variant.devID !== id)])
    setErrorsList(prev => ({ ...prev, items: { ...prev.items, [`${id}_omission`]: { value: false }, [`${id}_omission_text`]: { value: false } } }))
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { if (!data?.answer_options || data?.answer_options?.length < 1) add() }, [])



  return <div className={s.wrapper}>
    <span className={style.heading}>Задание:</span>
    <div className={s.list}>
      {state?.map((variant, i) =>
        <Item
          key={variant.devID}
          el={variant}
          index={i}
          isLast={state?.length === i + 1}
          remove={() => remove(variant.devID)}
          edit={v => onEdit(v, variant.devID)}
          editText={v => onEditText(v, variant.devID)}
          setErrorsList={setErrorsList} showErrors={showErrors} />)}
      <ButtonAdd onClick={add} />
    </div>
  </div>
}

const Item = ({ el, isLast, index, edit, remove, editText, setErrorsList, showErrors }) => {
  const [bindName] = useInputNew({
    name: `${el.devID}_omission`,
    value: el?.question,
    onChange: editText,
    onError: setErrorsList,
    placeholder: `Напишите текст задачи до пропуска (${index + 1} часть)`,
    validate: showErrors,
    title: `${index + 1} часть`,
    errorText: index === 0 ? 'Введите текст до пропуска' : 'Удалите или заполните поле',
  })
  const [bindText] = useInputNew({
    name: `${el.devID}_omission_text`,
    value: el?.text,
    onChange: edit,
    onError: (isLast && index !== 0) ? () => { } : setErrorsList,
    placeholder: `Напишите правильный ответ (${index + 1} часть)`,
    validate: (isLast && index !== 0) ? false : showErrors,
    errorText: index === 0 ? 'Хотя бы один ответ должен присутствовать' : 'Уберите пропуск или заполните поле',
  })

  return <div className={s.col}>
    <InputTextarea 
      classNameTextarea={commonStyle.inputBox}
      className={style.inputNew} 
      {...bindName} />
      
    <InputNew classNameForBox={commonStyle.inputBox} className={style.inputNew} {...bindText} />
    {index > 0 && <ButtonRemove onClick={remove} />}
  </div>
}
