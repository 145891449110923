import s from './Block.module.css';
import arrowSelectBottom from '../../../../../images/svg/arrow_select_bottom.svg';
import arrowSelectTop from '../../../../../images/svg/arrow_select_top.svg';
import pen from '../../../../../images/svg/pen.svg';
import plus from '../../../../../images/svg/plus.svg';
import classNames from 'classnames';

const Block = ({
    children,
    clickTop,
    clickBottom,
    clickEdit,
    clickDelete,
    className,
    classNameText = null
}) => {
    return (<div className={s.block + ' ' + className}>
        { (clickTop || clickBottom || clickEdit || clickDelete) && <div className={s.buttons}>
            { clickTop && <div className={s.button} onClick={clickTop}>
                <img src={arrowSelectTop} className={s.arrow_top} alt="вверх" />
            </div> }
            { clickBottom && <div className={s.button} onClick={clickBottom}>
                <img src={arrowSelectBottom} className={s.arrow_bottom} alt="вниз" />
            </div> }
            { clickEdit && <div className={s.button} onClick={clickEdit}>
                <img src={pen} className={s.pen} alt="редактировать" />
            </div> }
            { clickDelete && <div className={s.button} onClick={clickDelete}>
                <img src={plus} className={s.plus} alt="удалить" />
            </div> }
        </div> }
        <div className={classNames(s.text, {[classNameText]: classNameText})}>
            { children }
        </div>
    </div>)
}

export default Block;