import s from './index.module.css'
import style from '../../index.module.css'
import { ButtonDel } from '../../../../../UI/Button'
import classNames from 'classnames'
import { nextId } from '../../../../../../helpers/nextId'
import { useEffect, useState } from 'react'
import { useInputNew } from '@UI/InputNew/useInputNew'
import { InputNew } from '@UI/InputNew'
import { InputFormate } from '../../../../../UI/InputNew'
import { ButtonAdd, ButtonRemove } from '../Common/Buttons'
import commonStyle from '../Common/common.module.css'

export const OptionsAnswer = ({ data, edit, setErrorsList, showErrors }) => {
  const [state, setState] = useState(data['answer_options'] ? data['answer_options']?.map((el, index) => ({ ...el, devID: index })) : [])

  useEffect(() => {
    edit(prev => ({ ...prev, 'answer_options': [...state?.map((el, index) => ({ text: el.text, index: index + 1 }))] }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  const onEdit = (text, id) => setState([...state.map(variant => (variant.devID === id) ? { ...variant, text } : variant)])
  const add = () => setState([...state, { text: '', devID: nextId(state, 'devID') }])
  const remove = id => {
    setState([...state?.filter(variant => variant.devID !== id)])
    setErrorsList(prev => ({ ...prev, items: { ...prev.items, [`${id}-answer-option`]: { value: false } } }))
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { if (!data?.answer_options || data?.answer_options?.length < 1) add() }, [])

  return <div className={s.wrapper}>
    <span className={style.heading}>Варианты ответов:</span>
    <div className={s.list}>
      {state?.map((variant, i) =>
        <Item key={variant.devID} type={data.type} el={variant} index={i} remove={() => remove(variant.devID)} edit={v => onEdit(v, variant.devID)} setErrorsList={setErrorsList} showErrors={showErrors} />)}
      <ButtonAdd onClick={add} />
    </div>
  </div>
}

const Item = ({ type, el, index, edit, remove, setErrorsList, showErrors }) => {
  const responseType = () => {
    if (type === 'test')
      return typeof el?.text === 'object' ? el?.text : el?.text ? {
        "type": "doc",
        "content": [{ "type": "paragraph", "content": [{ "type": "text", "text": el?.text }] }]
      } : { "type": "doc", "content": [{ "type": "paragraph", }] }
    return el.text ?? ''
  }

  const [value, setValue] = useState(responseType())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { edit(value) }, [value])

  const [bindName] = useInputNew({
    name: `${el.devID}-answer-option`,
    value: value,
    onChange: setValue,
    onError: setErrorsList,
    placeholder: 'Вариант ответа',
    validate: showErrors,
  })
  return <div className={s.row}>
    <span className={s.booble}>{index + 1}</span>
    {type === 'test'
      ? <InputFormate 
        tiptapClassContent={commonStyle.tiptapClassContent}
        tiptapClassContentProseMirror={commonStyle.tiptapClassContentProseMirror}
        className={classNames(style.inputNew, commonStyle.inputFormate)} 
        {...bindName} />
      : <InputNew className={style.inputNew} {...bindName} />
    }
    {index > 0 && <ButtonRemove onClick={remove} />}
  </div>
}
