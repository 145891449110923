import cn from "classnames";
import {useHistory, useParams} from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {useSelector} from "react-redux";
import { LoadingOutlined } from '@ant-design/icons';
import {getMyGroups} from "@/selectors";
import s from "./index.module.css";
import React from "react";
import { AddFromPlatformMedia, BackIcon, CompressIcon, IcoDownload, PlusIconFatty } from "../../../utils/icons";
import { useScreenWidth } from '@hooks/useScreenWidth'
import classNames from "classnames";

const Button = ({
  name,
  optionText = "",
  className = "",
  styles = "",
  action = Function.prototype,
  children = "",
  disable = false,
  loading = false,
  newDesign = false,
  newDesignYellow = false,
  newDesignMaxHenght = false,
  ...props
}) => {
  
  const isDisabled = disable || loading

  return (
    <button
      className={cn(
        s.button,
        className,
        { [s[styles]]: styles },
        { [s.disable]: isDisabled },
        { [s.battonRubber]: newDesign },
        { ['backgroundNewYellow']: newDesignYellow },
        { [s.backgroundNewMaxHenght]: newDesignMaxHenght }
      )}
      onClick={(e) => !isDisabled && action(e)}
      {...props}
      disabled={isDisabled}
    >
      {loading && <LoadingOutlined className={s.loading}/>}
      {name} {children}
      {optionText && <span className={s.text}>{optionText}</span>}
    </button>
  );
};

export default Button;

export const ButtonPrev = ({  link, linkForsed = null, className = "", noHistory = false, newDesign = false, ...props }) => {
  const history = useHistory()
  const {course } = useParams();
  const pathname = useLocation().pathname
  let groups = useSelector(getMyGroups)[0]
  const materialPlans = groups?.materialPlans?.filter(
      (item) => `${item?.course_id}` === course
  );

  const handleClickToBlack = () => {
    let patchBlack = ''
    pathname.split('/').slice(1, -1).forEach(item => patchBlack += '/' + item)
    history.push(patchBlack)
  }
  const handleClickToBlackLink = () => {
    // history.push('/courses/my')
    history.goBack()
  }
  const handleClickToBackLinkDir = () => {
    history.push( `/courses/my/${groups.id}` )
  }

  if(linkForsed){
    return <button className={cn(s.buttonNewDesign, { [className]: className })} onClick={()=>{history.push(linkForsed)}} {...props}>
      <BackIcon />
      <span>Назад</span>
    </button>
  }

  if (groups?.coursePlans.length === 1){
    if(newDesign){
      return <button className={cn(s.buttonNewDesign, { [className]: className })} onClick={handleClickToBlackLink} {...props}>
        <BackIcon />
        <span>Назад</span>
      </button>
    }
    else{
      return <button className={cn(s.button, s.buttonPrev, { [className]: className })} onClick={handleClickToBlackLink} {...props}>Вернуться</button>
    }
  } 
  if (materialPlans?.length === 1){
    if(newDesign){
      return <button className={cn(s.buttonNewDesign, { [className]: className })} onClick={handleClickToBackLinkDir} {...props}>
        <BackIcon />
        <span>Назад</span>
      </button>
    }
    else{
      return <button className={cn(s.button, s.buttonPrev, { [className]: className })} onClick={handleClickToBackLinkDir} {...props}>Вернуться</button>
    }
  } 
  if (noHistory){
    if(newDesign){
      return <button className={cn(s.buttonNewDesign, { [className]: className })} onClick={handleClickToBlack} {...props}>
         <BackIcon />
        <span>Назад</span>
      </button>
    }
    else{
      return <button className={cn(s.button, s.buttonPrev, { [className]: className })} onClick={handleClickToBlack} {...props}>Вернуться</button>
    }
  } 

  if (!link){
    if(newDesign){
      return <button className={cn(s.buttonNewDesign, { [className]: className })} onClick={() => history.goBack()} {...props}>
        <BackIcon />
        <span>Назад</span>
      </button>
    }
    else{
      return <button className={cn(s.button, s.buttonPrev, { [className]: className })} onClick={() => history.goBack()} {...props}>Вернуться</button>
    }
  } 

  if(newDesign){
    return <button className={cn(s.buttonNewDesign, { [className]: className })} onClick={() => history.length < 2 ? history.push(link) : history.goBack()}
    {...props}>
       <svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M0.21967 6.53033C-0.0732233 6.23744 -0.0732233 5.76256 0.21967 5.46967L5.21967 0.46967C5.51256 0.176777 5.98744 0.176777 6.28033 0.46967C6.57322 0.762563 6.57322 1.23744 6.28033 1.53033L1.81066 6L6.28033 10.4697C6.57322 10.7626 6.57322 11.2374 6.28033 11.5303C5.98744 11.8232 5.51256 11.8232 5.21967 11.5303L0.21967 6.53033Z" fill="#2B2D3E"/>
        </svg>
      <span>Назад</span>
    </button>
  }
  else{
    return <button className={cn(s.button, s.buttonPrev, { [className]: className })} onClick={() => history.length < 2 ? history.push(link) : history.goBack()}
    {...props}>
      Вернуться
    </button>
  }
 
}

export const ButtonAdd = ({ className = "", ...props }) => {
  return <button className={`${s.buttonC} ${className}`} style={{width: '36px', height: '36px', display:'flex', justifyContent:'center', alignItems:'center', borderRadius: '100%', backgroundColor:'white'}} {...props}>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="16" fill="white" />
      <path d="M17.0041 23.0711C17.0041 23.3374 16.8983 23.5928 16.71 23.7811C16.5217 23.9694 16.2663 24.0752 16 24.0752C15.7337 24.0752 15.4783 23.9694 15.29 23.7811C15.1017 23.5928 14.9959 23.3374 14.9959 23.0711L15.003 16.997L8.92893 17.0041C8.66263 17.0041 8.40724 16.8983 8.21893 16.71C8.03063 16.5217 7.92484 16.2663 7.92484 16C7.92484 15.7337 8.03063 15.4783 8.21893 15.29C8.40724 15.1017 8.66263 14.9959 8.92893 14.9959L15.003 15.003L14.9959 8.92894C14.9959 8.66264 15.1017 8.40725 15.29 8.21894C15.4783 8.03064 15.7337 7.92485 16 7.92485C16.2663 7.92485 16.5217 8.03064 16.71 8.21894C16.8983 8.40725 17.0041 8.66264 17.0041 8.92894L16.997 15.003L23.0711 14.9959C23.3374 14.9959 23.5928 15.1017 23.7811 15.29C23.9694 15.4783 24.0752 15.7337 24.0752 16C24.0752 16.2663 23.9694 16.5217 23.7811 16.71C23.5928 16.8983 23.3374 17.0041 23.0711 17.0041L16.997 16.997L17.0041 23.0711Z" fill="black" />
    </svg>
  </button>
}

export const ButtonUpload = ({ className = "", ...props }) => {
  return <button className={`${s.buttonC} ${className}`} style={{width: '36px', height: '36px', display:'flex', justifyContent:'center', alignItems:'center', borderRadius: '100%', backgroundColor:'white'}} {...props}>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" fill="white" r="16"/>
      {/* <circle cx="16" cy="16" fill="#F4F4F4" r="12"/> */}
      <path
          d="m8.56,17.9a0.5,0.5 0 0 1 0.5,0.5l0,2.5a1,1 0 0 0 1,1l12,0a1,1 0 0 0 1,-1l0,-2.5a0.5,0.5 0 0 1 1,0l0,2.5a2,2 0 0 1 -2,2l-12,0a2,2 0 0 1 -2,-2l0,-2.5a0.5,0.5 0 0 1 0.5,-0.5z"
          fill="black" />
      <path
          d="m15.71,9.15a0.5,0.5 0 0 1 0.7,0l3,3a0.5,0.5 0 0 1 -0.7,0.7l-2.15,-2.14l0,8.79a0.5,0.5 0 0 1 -1,0l0,-8.79l-2.15,2.14a0.5,0.5 0 1 1 -0.7,-0.7l3,-3z"
          fill="black" />
    </svg>
  </button>
}

export const ButtonDel = ({className = "", ...props}) => {
  return <button className={classNames(s.btnRemove, s.buttonC, {[className]: className})} {...props}>
    <PlusIconFatty />
  </button>
}

export const ButtonEdit = ({ className = "", ...props }) => {
  return <button className={`${s.buttonC} ${className}`} {...props}>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="16" fill="white" />
      <circle cx="16" cy="16" r="12" fill="#F4F4F4" />
      <path d="M23.29 8.88L23.12 8.71C22.5575 8.1482 21.795 7.83264 21 7.83264C20.205 7.83264 19.4425 8.1482 18.88 8.71L9.29 18.29C9.1621 18.42 9.07219 18.5826 9.03 18.76L8.03 22.76C7.98239 22.929 7.98014 23.1076 8.02347 23.2778C8.0668 23.448 8.15419 23.6038 8.27684 23.7295C8.39949 23.8552 8.55309 23.9464 8.72216 23.9939C8.89124 24.0413 9.06984 24.0435 9.24 24L13.24 23C13.4174 22.9578 13.58 22.8679 13.71 22.74L23.29 13.15C23.8518 12.5875 24.1674 11.825 24.1674 11.03C24.1674 10.235 23.8518 9.4725 23.29 8.91V8.88ZM12.49 21.1L10.37 21.63L10.9 19.51L17.48 12.93L19.07 14.52L12.49 21.1ZM21.88 11.71L20.48 13.1L18.9 11.52L20.29 10.12C20.383 10.0263 20.4936 9.95188 20.6154 9.90111C20.7373 9.85034 20.868 9.8242 21 9.8242C21.132 9.8242 21.2627 9.85034 21.3846 9.90111C21.5064 9.95188 21.617 10.0263 21.71 10.12L21.88 10.29C21.9737 10.383 22.0481 10.4936 22.0989 10.6154C22.1497 10.7373 22.1758 10.868 22.1758 11C22.1758 11.132 22.1497 11.2627 22.0989 11.3846C22.0481 11.5064 21.9737 11.617 21.88 11.71Z" fill="black" />
    </svg>
  </button>
}

export const ButtonEditNew = ({ className = "",...props }) => {
  return <button className={`${s.buttonC} ${className}`} {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M4.46746 12.1983L5.02916 12.5163L4.46746 12.1983C4.35553 12.396 4.30168 12.6129 4.24778 12.8299C4.24365 12.8466 4.23953 12.8632 4.23538 12.8798L3.73851 14.8672C3.73602 14.8772 3.7335 14.8873 3.73094 14.8975C3.69261 15.0504 3.64805 15.2281 3.63316 15.3803C3.6165 15.5506 3.61697 15.8689 3.87419 16.1261C4.13141 16.3834 4.44973 16.3838 4.62005 16.3672C4.7722 16.3523 4.94997 16.3077 5.10287 16.2694C5.11306 16.2668 5.12313 16.2643 5.13308 16.2618L5.13309 16.2618L5.1614 16.2547L5.16141 16.2547L7.12055 15.7649C7.13715 15.7608 7.15377 15.7567 7.17038 15.7525C7.38744 15.6986 7.60434 15.6448 7.80203 15.5329C7.99971 15.4209 8.15749 15.2627 8.31538 15.1043C8.32747 15.0921 8.33955 15.08 8.35166 15.0679L14.2099 9.20962L14.2315 9.18808C14.4673 8.95232 14.6785 8.74115 14.8342 8.54925C15.0018 8.34269 15.1527 8.10304 15.2087 7.79929L14.5695 7.68139L15.2087 7.79928C15.2452 7.60143 15.2452 7.39857 15.2087 7.20072L14.5695 7.31861L15.2087 7.20071C15.1527 6.89696 15.0018 6.65731 14.8342 6.45075C14.6785 6.25885 14.4673 6.04769 14.2315 5.81194L14.2099 5.79038L14.1884 5.76883C13.9526 5.53304 13.7415 5.32184 13.5496 5.16612C13.343 4.99851 13.1034 4.84761 12.7996 4.79158L12.6824 5.42718L12.7996 4.79158C12.6018 4.75509 12.3989 4.75509 12.201 4.79158C11.8973 4.84761 11.6576 4.99851 11.4511 5.16612C11.2592 5.32185 11.048 5.53305 10.8122 5.76884L10.7907 5.79038L11.2503 6.25L10.7907 5.79038L4.93242 11.6487C4.92032 11.6608 4.90819 11.6729 4.89607 11.6849C4.73767 11.8428 4.57939 12.0006 4.46746 12.1983Z" stroke="#C5C6D9" strokeWidth="1.3"/>
      <path d="M10.417 6.25L13.7503 9.58333" stroke="#C5C6D9" strokeWidth="1.3"/>
    </svg>
  </button>
}

export const ButtonInfo = ({ className = "", ...props }) => {
  return <button className={`${s.buttonC} ${className}`} {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none">
      <circle cx="16" cy="16" r="16" fill="#fff" />
      <circle cx="16" cy="16" r="12" fill="#F4F4F4" />
      <path fill="#000" d="M18.217 13.618c0 .616-.198 1.68-.594 3.19-.396 1.496-.594 2.479-.594 2.948 0 .455.066.682.198.682.088 0 .425-.14 1.012-.418l.264-.132.374.77c-.132.117-.308.264-.528.44-.22.176-.63.418-1.232.726-.601.293-1.159.44-1.672.44-.513 0-.917-.154-1.21-.462-.293-.323-.44-.748-.44-1.276 0-.543.183-1.496.55-2.86.367-1.379.55-2.273.55-2.684 0-.616-.19-1.195-.572-1.738l-.198-.264.022-.286c.777-.22 2.039-.33 3.784-.33.19.22.286.638.286 1.254Zm-2.508-3.652c-.293-.293-.44-.682-.44-1.166 0-.484.198-.91.594-1.276.41-.367.88-.55 1.408-.55.528 0 .939.147 1.232.44.293.293.44.682.44 1.166 0 .47-.213.887-.638 1.254-.41.367-.873.55-1.386.55-.513 0-.917-.14-1.21-.418Z" />
    </svg>
  </button>
}


export const ButtonTransfer = ({ className = "", ...props }) => {
  return <button className={`${s.buttonC} ${className}`} {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2.04883" y="2" width="19.7848" height="20" rx="9.89238" fill="#F4F4F4" />
      <rect x="2.04883" y="2" width="19.7848" height="20" rx="9.89238" stroke="white" strokeWidth="4" />
      <path fillRule="evenodd" clipRule="evenodd" d="M8.25781 9.12875C8.25781 8.35812 8.87413 7.7334 9.63439 7.7334H10.5783C11.3386 7.7334 11.9549 8.35812 11.9549 9.12875V10.0856C11.9549 10.8562 11.3386 11.4809 10.5783 11.4809H9.63439C8.87413 11.4809 8.25781 10.8562 8.25781 10.0856V9.12875ZM9.63439 8.29154C9.17823 8.29154 8.80844 8.66637 8.80844 9.12875V10.0856C8.80844 10.5479 9.17823 10.9228 9.63439 10.9228H10.5783C11.0345 10.9228 11.4043 10.5479 11.4043 10.0856V9.12875C11.4043 8.66637 11.0345 8.29154 10.5783 8.29154H9.63439Z" fill="#2D3134" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.4521 9.12875C12.4521 8.35812 13.0685 7.7334 13.8287 7.7334H14.7727C15.5329 7.7334 16.1492 8.35812 16.1492 9.12875V10.0856C16.1492 10.8562 15.5329 11.4809 14.7727 11.4809H13.8287C13.0685 11.4809 12.4521 10.8562 12.4521 10.0856V9.12875ZM13.8287 8.29154C13.3726 8.29154 13.0028 8.66637 13.0028 9.12875V10.0856C13.0028 10.5479 13.3726 10.9228 13.8287 10.9228H14.7727C15.2288 10.9228 15.5986 10.5479 15.5986 10.0856V9.12875C15.5986 8.66637 15.2288 8.29154 14.7727 8.29154H13.8287Z" fill="#2D3134" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.4521 13.3814C12.4521 12.6108 13.0685 11.9861 13.8287 11.9861H14.7727C15.5329 11.9861 16.1492 12.6108 16.1492 13.3814V14.3382C16.1492 15.1089 15.5329 15.7336 14.7727 15.7336H13.8287C13.0685 15.7336 12.4521 15.1089 12.4521 14.3382V13.3814ZM13.8287 12.5442C13.3726 12.5442 13.0028 12.9191 13.0028 13.3814V14.3382C13.0028 14.8006 13.3726 15.1755 13.8287 15.1755H14.7727C15.2288 15.1755 15.5986 14.8006 15.5986 14.3382V13.3814C15.5986 12.9191 15.2288 12.5442 14.7727 12.5442H13.8287Z" fill="#2D3134" />
      <path fillRule="evenodd" clipRule="evenodd" d="M8.25781 13.3814C8.25781 12.6108 8.87413 11.9861 9.63439 11.9861H10.5783C11.3386 11.9861 11.9549 12.6108 11.9549 13.3814V14.3382C11.9549 15.1089 11.3386 15.7336 10.5783 15.7336H9.63439C8.87413 15.7336 8.25781 15.1089 8.25781 14.3382V13.3814ZM9.63439 12.5442C9.17823 12.5442 8.80844 12.9191 8.80844 13.3814V14.3382C8.80844 14.8006 9.17823 15.1755 9.63439 15.1755H10.5783C11.0345 15.1755 11.4043 14.8006 11.4043 14.3382V13.3814C11.4043 12.9191 11.0345 12.5442 10.5783 12.5442H9.63439Z" fill="#2D3134" />
    </svg>
  </button>
}

export const DownloadExcelButton = ({
  className = "",
  styles = "",
  getExcelTable,
  excelTableState,
  disable,
}) => {
  const isMobile = useScreenWidth(767);
  return (
    <button
      style={{fontWeight:'600'}}
      className={cn(
        s.button,
        className,
        { [s[styles]]: styles },
        { [s.disable]: disable }
      )}
      onClick={() => getExcelTable()}
      disabled={excelTableState.downloading}
    >
        {excelTableState.downloading ? 
        <>
          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{margin: 'auto', background: 'rgb(110, 54, 140)', display: 'block', shapeRendering: 'auto'}} width="20px" height="20px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
            <circle cx="50" cy="50" fill="none" stroke="#ffffff" strokeWidth="10" r="35" strokeDasharray="164.93361431346415 56.97787143782138">
              <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
            </circle>
          </svg>
        </> : <><IcoDownload /> {!isMobile ? `Excel` : ''}</>
        }
    </button>
  );
};

export const CollapseBtn = ({ onClick, style }) => (
    <div className={s.collapseBtn} style={style} onClick={onClick}>
        <CompressIcon width={'20'} height={'20'}/>
    </div>
)

export const ButtonAddFromPlatformMedia = ({ ...props }) => {
  return <button style={{width: '36px', height: '36px', display:'flex', justifyContent:'center', alignItems:'center', borderRadius: '100%', backgroundColor:'white'}} {...props}>
    <AddFromPlatformMedia width={'20'} height={'20'} />
  </button>
}