import axios from "axios";
import jwt_decode from "jwt-decode";
import { AuthService } from "../services/AuthService";
import { getInArr } from "../helpers/getInArr";
import ErrorHandling from "../services/ErrorHandling";
import {publicUrl} from "@/api/index";
import store from "../store/redux.store"

let isRefreshing = false; // Флаг для блокировки повторных вызовов
let failedQueue = []; // Очередь запросов, которые нужно повторить после обновления токена

const getPathFromUrl = (url) => {
  try {
    const urlObj = new URL(url, process.env.REACT_APP_PATH_FILE); // Создаем объект URL
    const path = urlObj.pathname; // Получаем путь (например, "/survey/take-survey")
    return path.startsWith('/') ? path.slice(1) : path; // Убираем начальный слеш
  } catch (e) {
    return url; // Если URL некорректен, возвращаем как есть
  }
};

class apiCreator {
  constructor() {
    this.api = axios.create({
      baseURL: process.env.REACT_APP_URL_API,
      mode: "no-cors",
      headers: {
        "Content-Type": "application/json",

        withCredentials: true,
      },
    });

    // Перехватчик ответа от сервера проверка на ошибку авторизации 401
    // Если код ответа был 401 - отправляет запрос на обновление токена на основании cookie
    this.api.interceptors.response.use(
      (res) => {
        const url = res.config.url
        const path = getPathFromUrl(url);
        if (!publicUrl.includes(path)) {
          const token = localStorage.getItem("userToken");

          if (!token && !res.data.token) {
            return Promise.reject();
          }
        }

        return res.data || res.status === 204 ? res : Promise.reject();
      },

      function (error) {
        const originalRequest = error.config;


        // Если базовый URL был равен URL обновлению токена - возвращаем пустой объект ответа
        if (error?.config?.url === `${process.env.REACT_APP_URL_API}auth/refresh-token`){
          return Promise.reject(AuthService.logout());
        }

        if (!getInArr(error?.response?.status, [401])) {
          ErrorHandling.showAlert(error.response.data);
          return error;
        }

          if (error?.response?.status === 401) {
            if(!isRefreshing){
              isRefreshing = true;
  
              return new Promise((res) => {
                AuthService.refreshToken().then(() => {
                  const token = localStorage.getItem("userToken");
                  isRefreshing = false;
                  originalRequest.headers["Authorization"] = `Bearer ${token}`;
  
                  res(axios(originalRequest)); // Повторяем исходный запрос
  
                  // Повторяем все запросы из очереди
                  failedQueue.forEach((promise) => {
                    promise.resolve(
                      axios(promise.config).catch((error) => {
                        console.error("Ошибка при повторном запросе:", error);
                        return Promise.reject(error);
                      })
                    );
                  });
                  failedQueue = [];
                })
                .catch(() => {
                  isRefreshing = false;
                  AuthService.logout();
                });
  
              });
            }
            else {
              // Если токен уже обновляется, добавляем запрос в очередь
              if (failedQueue.length > 30) {
                failedQueue = [];
                ErrorHandling.showAlert("Произошла ошибка. Пожалуйста, войдите снова.");
                AuthService.logout();
              }
              return new Promise((resolve) => {
                failedQueue.push({ resolve, config: originalRequest });
              });
            }
          }

          if (error?.response?.status === 403) {
            return Promise.reject(AuthService.logout());
          }
      }
    );
    // Перехватчик запроса. Перед отправкой запроса проверяет время жизни JWT токена
    // Если вермя жизни осталось меньше 10 секунд - отправляет запрос на обновление токена

    this.api.interceptors.request.use(async (config) => {
      const token = await localStorage.getItem("userToken");
      if (token) {
        const payload = jwt_decode(token);
        const expTime = payload.exp * 1000;
        const curTime = new Date().getTime();

        if (expTime - curTime <= 30000 || curTime > expTime) {
          await axios
            .post(
              `${process.env.REACT_APP_URL_API}auth/refresh-token`,
              {
                refresh_token: localStorage.getItem('userRefreshToken'),
                role: store.getState()?.profile?.profile?.role
              },
              {
                withCredentials: true,
                Authorization: `Bearer ${token}`,
              }
            )
            .then((res) => {
              if (res.status === 200) {
                localStorage.setItem("userToken", res?.data?.token);
                config.headers["Authorization"] = `Bearer ${res?.data?.token}`;
              }
            })
            .catch((error) => {
              AuthService.logout();
              return Promise.reject(error);
            });
        }
      }

      return {
        ...config,
        headers: {
          ...config.headers,
          withCredentials: true,
          Authorization: `Bearer ${token}`,
        },
        baseURL: process.env.REACT_APP_URL_API,
      };
    });
  }
}

export default new apiCreator().api;
