import classNames from 'classnames'
import {useRef, useState, useEffect} from "react";

import Textarea from '@UI/Textarea'
import UploadFile from "@UI/UploadFile";
import {UploadMultipleFiles} from '@UI/UploadMultipleFiles/UploadMultipleFiles';
import {parseAcceptArray} from '@/utils/parseAcceptArray';
import { AnswerButton } from '../AnswerButton/AnswerButton'
import { useTaskHomeWork } from '../hooks/useTaskHomeWork'
import {IcoRemove} from "@pages/Admin/constsIcon";
import Tooltip from '@UI/Tooltip';
import {useAddAlert} from "@/hooks/useAddAlert";

import { useSelector } from "react-redux";
import { selectProfile } from '@store/profile.reducer';
import s from './DetailedResponse.module.css'
import Preloader from '@UI/Preloader'
import { images } from '@/api';
import { formateTime } from '@/helpers/formateTime';

export const DetailedResponse = ({ type, ...props }) => {
  switch (type) {
    case 'decided': return <Decided {...props} />
    case 'correction': return <Correction {...props} />
    default: return <Task {...props} />
  }
}

const Correction = () => {
  return <div className={s.wrapper}>
    <Textarea className={classNames(s.textarea, s.textareaCorrection)} placeholder='Ответьте развернуто' disabled={true} />
    <UploadFile allowed={false} />
  </div>
}

const Decided = ({userRole, ...props}) => {
  const userRoleFromStore = useSelector(selectProfile).role
  const answer = props?.params?.result?.answer
  const files = props?.params?.result?.answer_files ?? []

  const addAlert = useAddAlert();
   //
    const [changeImage, setChangeImage] = useState(false);
    const canvasRef = useRef(null);
    const [isDrawing, setIsDrawing] = useState(false);
    const [ctx, setCtx] = useState(null);
    const [imageUrl, setImageUrl] = useState('');
    const [brushColor, setBrushColor] = useState('#000000');
    const [brushWidth, setBrushWidth] = useState(5);
    const [history, setHistory] = useState([]);
    const [scale, setScale] = useState(1);
    const [imageWidth, setImageWidth] = useState(0);
    const [imageHeight, setImageHeight] = useState(0);
    const [canvasText, setCanvasText] = useState('');
    const [showCanvasText, setShowCanvasText] = useState(false);
    const [isTextRegim, setIsTextRegim] = useState(false);
    const [xY, setXY] = useState({offsetX: 0, offsetY: 0});
    const [canvasFont, setCanvasFont] = useState(26);
    const [isSaving, setIsSaving ] = useState(false);
    const [loading, setLoading] = useState(false);
    const saveImageToHistory = () => {
      const dataURL = canvasRef.current.toDataURL('image/png');
      setHistory(prevHistory => [...prevHistory, dataURL]);
  };

  const drawImageOnCanvas = (image) => {
      const canvas = canvasRef.current;
      const context = ctx;

      const imgWidth = image.width;
      const imgHeight = image.height;
      setImageWidth(imgWidth);
      setImageHeight(imgHeight);

      canvas.width = imgWidth;
      canvas.height = imgHeight;

      context.clearRect(0, 0, canvas.width, canvas.height);
      context.drawImage(image, 0, 0, imgWidth, imgHeight);
      saveImageToHistory();
  };

  const drawImageOnCanvas2 = (image) => {
    const canvas = canvasRef.current;
    const context = ctx;

    context.clearRect(0, 0, canvas.width, canvas.height);
    context.drawImage(image, 0, 0, imageWidth*scale, scale*imageHeight);
  };
  const removeDuplicateSlashes = (url) => {
      return url.replace("///", "/");
  }

  const canvasFunction = () => {
    if(!imageUrl){
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
      setCtx(context);

      const image = new Image();
      image.src = '';
      image.onload = () => {
        drawImageOnCanvas(image);
      }
      return;
    } 
    addAlert({text:'Идет подготовка изображения к редактированию, пожалуйста, подождите'})
    setLoading(true)
    
    const getImgUrl = removeDuplicateSlashes(imageUrl).replace(process.env.REACT_APP_PATH_FILE, "/");
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    setCtx(context);

    images.getAccessConvas({imageUrl: getImgUrl})
      .then(({data})=>{
        const image = new Image();
        image.src = data.contents;
        image.onload = () => {
          drawImageOnCanvas(image);
        }
        addAlert({text:'Изображение готово к редактированию'});
      })
      .catch(()=>{
        addAlert({title: 'Ошибка', text:'Попробуйте ещё раз или обратитесь к администратору', type:'error'})
      })
      .finally(()=>{
        setLoading(false)
      })
  };

  useEffect(()=>{
    setScale(1);
    canvasFunction();
  }, [imageUrl]);

  const startDrawing = (e) => {
      if(isTextRegim)
        return;
      setIsDrawing(true);
      ctx.beginPath();
      const { offsetX, offsetY } = getMousePos(e);
      ctx.moveTo(offsetX - 16,  offsetY + 20);
      ctx.strokeStyle = brushColor;
      ctx.lineWidth = brushWidth;
  };

  const draw = (e) => {
      if (!isDrawing) return;
      const { offsetX, offsetY } = getMousePos(e);
      ctx.lineTo(offsetX - 16, offsetY + 20);
      ctx.stroke();
  };

  const stopDrawing = () => {
      if (isDrawing) {
          setIsDrawing(false);
          ctx.closePath();
          saveImageToHistory(); // Сохраняем текущее состояние при завершении рисования
      }
  };

  const saveImage = async () => {
      setIsSaving(true);
      addAlert({title: 'Идет отправка изображения'})
      const dataURL = canvasRef.current.toDataURL('image/png');
      const blob = await (await fetch(dataURL)).blob();
      props.teacherAnswer
          .addCommentFile({
              'comment_files': [blob],
              'work_id': props.homeWork_id,
              'task_id': props.id,
          })
          .then(res => {
              if (res.data.data?.length > 0) {
                props.updateList(res.data.data);
                addAlert({text: 'Изображение успешно добавленно во вложения'})
              }
          })
          .finally(()=>{
            setIsSaving(false)
          });
  };

  const undoLastAction = () => {
    if (history.length > 1) {
        const newHistory = history.slice(0, history.length - 1); 
        setHistory(newHistory);

        if (newHistory.length > 0) {
            const lastState = newHistory[newHistory.length - 1]; // Получаем последнее состояние
            const img = new Image();
            img.src = lastState; // Берем новое последнее состояние изображения
            img.onload = () => {
                ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height); 
                drawImageOnCanvas2(img); // Используйте текущее значение масштаба
            };
        } else {
            ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height); 
        }
    }
  };

  const increaseScale = () => {
      setScale(prevScale => {
          const newScale = Math.min(prevScale + 0.1, 5);
          redrawCanvas(newScale);
          return newScale;
      });
  };

  const decreaseScale = () => {
    setScale(prevScale => {
        const newScale = Math.max(prevScale - 0.1, 0.1);
        redrawCanvas(newScale);
        return newScale;
    });
  };

  let isRedrawing = false;
  const redrawCanvas = (newScale) => {
    if (isRedrawing || history.length === 0) return;
    isRedrawing = true;
    const lastState = history[history.length - 1];
    const lastImage = new Image();
    lastImage.src = lastState;

    lastImage.onload = () => {
        canvasRef.current.width = imageWidth * newScale;
        canvasRef.current.height = imageHeight * newScale;
        ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
        ctx.drawImage(lastImage, 0, 0, imageWidth * newScale, imageHeight * newScale);
        isRedrawing = false;
    };
  };

  const getMousePos = (e) => {
      const rect = canvasRef.current.getBoundingClientRect();
      return {
          offsetX: e.clientX - rect.left,
          offsetY: e.clientY - rect.top,
      };
  };

  const addText = (e) => {
    if (!isTextRegim)
      return;
    if(!showCanvasText) {
      setShowCanvasText(true);
    }
    setXY(getMousePos(e));
  }

  const saveCanvasText = () => {
    if (!canvasText){
      setShowCanvasText(false);
      return;
    }
    const allStr = canvasText.split('\n');
    ctx.fillStyle = brushColor;
    ctx.font = canvasFont + "px Montserrat";
    allStr.forEach((el, i) => {
      ctx.fillText(el, xY.offsetX, xY.offsetY + 1.3 * i * canvasFont);
    })
    
    saveImageToHistory();
    setCanvasText('');
    setShowCanvasText(false);
  }

  const changeCanvasFont = (value) => {
    if (!Number.isInteger(Number(value)))
      return;
    if (value < 10 || value > 50)
      return;
    setCanvasFont(value);
  }

  const canvasStyle = isTextRegim? { style: {
    cursor: 'text'
  }}: {};
  //код для конваса заканчивается здесь
  return (
      <div className={s.answerWrap}>
        <div className={s.description}>
          <span className={classNames(s.capsuleLeft, {[s.red]: false})}>
            {userRole === 'children' ? 'Ваш ответ' : 'Ответ учащегося'}
          </span>

          {answer ? (
              <Textarea className={classNames(s.textarea, s.textareaCorrection)} placeholder='Ответьте развернуто' disabled={true} value={answer}/>
          ) : (
              <span className='error'>Нет ответа</span>
          )}

          {
            files.map(file => <VievFile answer={file.path} {...{userRoleFromStore, changeImage, setImageUrl, loading}} />)
          }

      { userRoleFromStore == 'seminarian' && <button onClick={() => { setChangeImage((prevValue) => !prevValue) }} className={classNames(s.buttonText, 'backgroundNewGrey', 'backgroundNewYellow')}>
          {changeImage ? 'Свернуть' : 'Изменить изображение и отправить ученику'}
        </button>
      }
      <div style={{ width: '100%' }} className={classNames({ [s.canvasWrapperHide]: !changeImage })}>
      <div className={s.canvasManage}>
        
        {!imageUrl && <div className={s.tooltipWrapper}>
          <span className={s.textСlueBig}>Выберите изображение</span>
          <Tooltip 
            text="Вы можете выбрать любое изображение ученика для редактирования, кликнув по нему" 
            width={220} />
            </div>
        }
          <button 
            className='backgroundNewGrey'
            onClick={undoLastAction}>Отменить последнее действие</button>
          <div className={s.inputWrapper}>
            <span className={s.textСlue}>Выберите цвет кисточки и текста</span>
            <input
              type="color"
              value={brushColor}
              onChange={(e) => setBrushColor(e.target.value)}
            />
          </div>
          <div className={s.inputWrapper}>
            <span className={s.textСlue}>Выберите ширину кисточки</span>
            <input
              type="range"
              min="1"
              max="15"
              value={brushWidth}
              onChange={(e) => setBrushWidth(e.target.value)}
            />
          </div>
          <div className={s.inputsWrapper}>
            <button
              onClick={increaseScale} 
              className={classNames('backgroundNewGrey', { [s.buttonDisable]: canvasRef?.current?.width > 1000 }, { [s.buttonDisable]: !imageUrl })}>Увеличить изображение</button>
              <button 
                onClick={decreaseScale}
                className={classNames('backgroundNewGrey', { [s.buttonDisable]: canvasRef?.current?.width < 450 }, { [s.buttonDisable]: !imageUrl })}>Уменьшить изображение</button>
            <button 
              onClick={()=>setIsTextRegim(regim => !regim)}
              className="backgroundNewGrey">
                { isTextRegim? 'Выключить текстовый режим': 'Включить текстовый режим' }
              </button>
            <div className={s.inputWrapper}>
              <span className={s.textСlue}>Размер шрифта</span>
              <input
                type="range"
                min="10"
                max="50"
                className={s.canvasInputFont}
                value={canvasFont}
                onChange={(e)=>changeCanvasFont(e.target.value)}
              />
            </div>
          </div> 
          <button 
            className={classNames('backgroundNewGrey', 'backgroundNewYellow', { [s.buttonDisable]: !imageUrl || isSaving })}
            onClick={saveImage}>Отправить изображение учащемуся</button>
        </div>
        
        <div className={classNames(s.canvasAndIcoRemove, { [s.canvasWrapperHide]: !imageUrl }, 'customScroll')}>
          <div className={classNames(s.preloader, {[s.canvasWrapperHide]: !loading })}>
            <Preloader fullScreen='center' />
          </div>
          <IcoRemove onClick={() => {
            setScale(1);
            setImageUrl('');
            setHistory([]);
          }} />
          <canvas
            ref={canvasRef}
            className={classNames(s.canvas)}
            onMouseDown={startDrawing}
            onMouseMove={draw}
            onMouseUp={stopDrawing}
            onMouseOut={stopDrawing}
            onTouchStart={startDrawing} 
            onTouchMove={draw} 
            onTouchEnd={stopDrawing} 
            onTouchCancel={stopDrawing}
            onClick={addText}
            { ...canvasStyle }
          />
          {
            isTextRegim && <div style={{
                top: xY.offsetY,
                left: xY.offsetX,
                display: showCanvasText?'flex':'none',
                flexDirection: 'column',
                alignItems:'flex-start',
                position: 'absolute'
            }}>
                <textarea
                    
                    value={canvasText}
                    onChange={(e)=>setCanvasText(e.target.value)}
                    onBlur={saveCanvasText}
                    style={{
                      minHeight:'90px',
                      color: brushColor,
                      fontSize: canvasFont + 'px'
                  }}
                  className={s.inputText}
                />
                <button style={{marginTop:'5px'}} className='backgroundNewGrey backgroundNewYellow'>Ок</button>
            </div>
          }
        </div>
      </div>

        </div>
      {props?.params?.result?.time_spent &&
        <div className={s.estimation}>
          <span className={s.booble}>Потрачено время</span>
          <span className={s.booble}> {formateTime(props?.params?.result?.time_spent)}</span>
        </div>
      }
      </div>
  )
}

const Task = ({ params, ...props }) => {
  const textareaRef = useRef();
  const [files, setFiles] = useState([])
  const [isEditing, setIsEditing] = useState(false);
  const { onAnswer, answer, status, hint, video, videoType } = useTaskHomeWork(params, props, true, files, false, setIsEditing)

  const accept = parseAcceptArray(props?.mime)
  const defaultFiles = params?.result?.answer_files ?? []

  const onInputFiles = (files) => {
    if (!answer.get && files.length > 0) {
      answer.set('Ответ файлом')
    }
    setFiles(files)
  }

  const enableEditing = () => {
    params.setStatRowValues?.((prev) => {
        return prev.map((item) => (item.id === props.id ? {...item, value: 0} : item))
    })
    setIsEditing(true);
  };

  return <div className={s.answerWrap}>
    <Textarea
      ref={textareaRef}
      value={answer.get}
      onInput={e => answer.set(e.target.value)}
      className={s.textarea}
      placeholder='Ответьте развернуто'
      disabled={status > 4 && !isEditing}
    />

    {!props?.cant_upload_files &&
    <div style={{marginBottom: 10}}>
      <UploadMultipleFiles onInput={onInputFiles} disabled={status > 4 && !isEditing} accept={accept} maxFiles={10} defaultFiles={defaultFiles} />
    </div>
    }

    <div className={s.actionContainer}>
      <AnswerButton hintForChild={params.hintForChild} hintAfterAllAttempts={props.hint_after_all_attempts} {...{ status, onAnswer, hint, video, videoType, isEditing }} isDetailedResponse={true} />

      {!isEditing && status > 4 && (
        <button style={{marginLeft:'auto', marginTop:'auto'}} className='btn' onClick={enableEditing}>
          Изменить ответ
        </button>
      )}
    </div>
  </div>
}



const VievFile = ({userRoleFromStore, answer, changeImage, setImageUrl, loading}) => {
  const isImg = (str) => (str.includes('.jpeg') || str.includes('.png') || str.includes('.jpg') || str.includes('.webp') || str.includes('.svg'));
  //const isImg = (str) => (str.includes('.jpeg') || str.includes('.png') || str.includes('.jpg') || str.includes('.webp') || str.includes('.svg') || str.includes('.heic') || str.includes('.HEIC'));
  const imgSrc = process.env.REACT_APP_PATH_FILE + '/' + answer;
  if(answer) {
    if(isImg(answer) && userRoleFromStore !== 'children') {
      return (
        <img 
          onClick={() => {
            if(changeImage){
              if(loading){
                return;
              }
              setImageUrl(imgSrc)
            }
            else{
              window.open(imgSrc, '_blank');
            }
          }}
          className={s.editable} 
          src={imgSrc} />
      )
    }
    else{
      return <a href={imgSrc} className={s.capsuleRight} target='_blank' rel="noreferrer">Открыть файл</a>;
    }
  }
  else{
    return 'Учащийся не прикрепил файл';
  }
}
