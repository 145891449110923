import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { routes } from "@/routes/list";

const PageTitle = (props) => {
    const pathnames = useLocation()
        .pathname.split("/")
        .filter((el) => el);

    const path = `/${pathnames.slice(0, pathnames.length).join("/")}`;
    const name = routes.find((el) => el.path === path)?.label;
    const title = (props?.title ? props.title : name);
  
    return (
        <div className="newDesignTitleText">{title}</div>
    )
}

export default PageTitle;