import { Link } from 'react-router-dom'
import s from './index.module.css'
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectProfile } from '@store/profile.reducer'
import RoleList from '../RoleList'
import { formateName } from '@helpers/formateName'
import { getAvatar } from '@helpers/getAvatar'
import { AuthService } from '@/services/AuthService'
import { useScreenWidth } from '@hooks/useScreenWidth'
import { Drawer, Select } from 'antd'
import { NavigationApp } from '@/components/common/Sidebar/SidebarNewDesign.jsx';
import classNames from 'classnames';

import QModals from '@/components/pages/Main/Requests/Qmodals/index.jsx'
import {ButtonEditNew} from "@UI/Button";
import { CloseIcon, LogOutIcon, SupportIcon, Feedback} from "@/utils/icons";
import BalanceBlock from "@UI/BalanceBlock";

import Notification from './notifications/index.jsx'
import Further from "./FurtherNewDesign";
import { getMyLevel } from "@/helpers/getMyLevel";

import { GetIco } from "../../../../utils/icons"
import Translation from "../Translation"
import setSettingsFromServer from "@/helpers/setSettingsFromServer"
import { openModal } from '@store/modalsSlise';

const Header = () => {
  useEffect(()=>{
    import('../../../../template/styles/global_new_design.css');
  }, [])
  const isMobile = useScreenWidth(992)

  const profile = useSelector(selectProfile)
  const handleLogout = () => AuthService.logout()
  if (isMobile) return <Mobile {...{ profile, handleLogout }} />
  return <Desktop {...{ profile, handleLogout }} />
}
export default Header

const Mobile = ({ profile, handleLogout }) => {
  const [settings, setSettings] = useState(null);
  useEffect(() => {
    setSettingsFromServer(setSettings)
  }, []);
  
  const isDisableStudentSupportRequests = settings?.isDisableStudentSupportRequests == '1';
  const isDisableEmployeeSupportRequests = settings?.isDisableEmployeeSupportRequests == '1';
  

  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false)
  const [type, setType] = useState(false)

  const showDrawer = () => setOpen(true)
  const onClose = () => setOpen(false)
  const handleClick = () => {
    setShow(true)
    setType('ProblemSite')
    onClose()
  }
  const isMobile = useScreenWidth(540)

  const [logo, setLogo] = useState('')
  useEffect(() => {
    if (settings?.mainLogo) {
      setLogo(process.env.REACT_APP_PATH_FILE + (settings?.mainLogo))
    }
  }, [settings])

  return <>
    <Drawer
      {...{
        placement: "right",
        onClose: onClose,
        className: 'drawer-new-design',
        open: open,
        closable: false,
        width:  isMobile ? '100vw' : 276,
        style: { height: '100vh' },
        footer: <>

          {
            ((profile?.role === "children" && !isDisableStudentSupportRequests) || (profile?.role !== "children" && !isDisableEmployeeSupportRequests)) && <div className={classNames(s.support)} onClick={handleClick}>
              <SupportIcon/>
              Поддержка
            </div>
          }
          
          <div  onClick={handleLogout} className={s.support}>
            <LogOutIcon />
            Выход
          </div>
        </>,
        children: <div className={s.navList}>
          <div className={s.drawerHeader}>
            <div className={s.avatar}>
              <img src={getAvatar(profile)} alt="Name"/>
            </div>
            <div className={s.fio}>
              {formateName(profile?.name, "F I")}
              {showUserCustomId(profile)}
            </div>
            <Link to="/personal" className={s.linkEdit} onClick={onClose}>
              <ButtonEditNew className={s.btnEdit}/>
            </Link>
          </div>
          <NavigationApp onClose={onClose}/>
          {profile?.role === "children" && settings?.isFreeCourses === '0' && (
              <BalanceBlock profile={profile}/>
          )}
          <div className={s.close} onClick={onClose}>
            <CloseIcon />
          </div>
        </div>
      }}
    />

    <header className={`app-header ${s.wrapper}`}>
      <div className={s.headerContainer} >
        <div className={s.mainLogo}>
          <img src={logo} alt='логотип' className={s.headerLogo}/>
        </div>

        <div className={s.mobIconsHeader}>
        <Translation/>
          {/* <button className={s.buttonLanguage} title='Сменить язык'>
          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="26" viewBox="0 0 50 26" fill="none">
          <rect x="0.5" y="0.5" width="49" height="25" rx="4.5" fill="white"/>
          <rect x="0.5" y="0.5" width="49" height="25" rx="4.5" stroke="#F4F4F4"/>
          <g clip-path="url(#clip0_124_4451)">
          <path d="M21.45 11.2028H5.55V8.03442C5.55 7.90969 5.65115 7.80854 5.77587 7.80854H21.2241C21.3489 7.80854 21.45 7.90969 21.45 8.03442L21.45 11.2028Z" fill="white" stroke="#E8E8E8" stroke-width="0.1"/>
          <path d="M21.2241 18.2413H5.77587C5.62353 18.2413 5.5 18.1178 5.5 17.9654V14.7471H21.5V17.9654C21.5 18.1178 21.3764 18.2413 21.2241 18.2413Z" fill="#FF4B55"/>
          <path d="M21.5 11.2528H5.5V14.7469H21.5V11.2528Z" fill="#41479B"/>
          </g>
          <path d="M26.76 17V8.6H30.036C30.772 8.6 31.4 8.716 31.92 8.948C32.44 9.18 32.84 9.516 33.12 9.956C33.4 10.396 33.54 10.92 33.54 11.528C33.54 12.136 33.4 12.66 33.12 13.1C32.84 13.532 32.44 13.864 31.92 14.096C31.4 14.328 30.772 14.444 30.036 14.444H27.42L27.96 13.892V17H26.76ZM32.376 17L30.24 13.952H31.524L33.684 17H32.376ZM27.96 14.012L27.42 13.424H30C30.768 13.424 31.348 13.26 31.74 12.932C32.14 12.596 32.34 12.128 32.34 11.528C32.34 10.928 32.14 10.464 31.74 10.136C31.348 9.808 30.768 9.644 30 9.644H27.42L27.96 9.044V14.012ZM38.9588 17.096C37.8548 17.096 36.9868 16.78 36.3548 16.148C35.7228 15.516 35.4068 14.592 35.4068 13.376V8.6H36.6068V13.328C36.6068 14.264 36.8108 14.948 37.2188 15.38C37.6348 15.812 38.2188 16.028 38.9708 16.028C39.7308 16.028 40.3148 15.812 40.7228 15.38C41.1388 14.948 41.3468 14.264 41.3468 13.328V8.6H42.5108V13.376C42.5108 14.592 42.1948 15.516 41.5628 16.148C40.9388 16.78 40.0708 17.096 38.9588 17.096Z" fill="#2B2D3E"/>
          <defs>
          <clipPath id="clip0_124_4451">
          <rect width="16" height="16" fill="white" transform="translate(5.5 5)"/>
          </clipPath>
          </defs>
          </svg>
          </button> */}
          
        {(profile?.roles?.length > 1 && profile.roles[0].item_name !== 'children') &&
            <RoleList userRole={profile.role} roles={profile.roles}/>}        
        <Notification />

        </div>
        <button className={`burger burger-new ${s.burger}`} onClick={showDrawer}></button>
      </div>
      
      <div className={s.headerContainer}>
      {(profile?.role === "seminarian" || profile?.role === "children")
          && <Further  />
        }
      </div>
    </header>
    <QModals type={type} active={show} setActive={setShow} />
  </>
}

const Desktop = ({profile, handleLogout}) => {
  const dispatch = useDispatch();

  const openAddVideoLesson = () => {
    dispatch(openModal({
      type:'addVideoLesson',
      data: {
        type: 'add'
      }
    }));
  }

  const [settings, setSettings] = useState(null);
  useEffect(() => {
    setSettingsFromServer(setSettings)
  }, []);

  const isDisableStudentSupportRequests = settings?.isDisableStudentSupportRequests == '1';
  const isDisableEmployeeSupportRequests = settings?.isDisableEmployeeSupportRequests == '1';
  
  const [show, setShow] = useState(false)
  const [type, setType] = useState(false)
  const handleClick = () => {
    setShow(true)
    setType('ProblemSite')
  }
  const level = getMyLevel(profile?.pulses);

  const [logo, setLogo] = useState('')
  useEffect(() => {
    if (settings?.mainLogo) {
      setLogo(process.env.REACT_APP_PATH_FILE + (settings?.mainLogo))
    }
  }, [settings])
  
  return <>
    <header className={`app-header ${s.wrapper}`}>
      
      <div className={s.headerWrapperLeft}>
        <div className={s.logoDesktop}>
          <img src={logo} alt='логотип'/>
        </div>
        
        
        {
           profile?.role === "children"  && <div className={s.rating}>
          <div className={s.ratingIcon}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path fillRule="evenodd" clipRule="evenodd" d="M12.9472 3.55557C13.2676 3.3908 13.6484 3.32107 14.0064 3.36095C14.4116 3.40609 14.7199 3.64354 14.9495 3.9038C15.1789 4.16382 15.3859 4.50947 15.586 4.90525C15.9831 5.69043 16.4311 6.83423 17.004 8.29688L17.019 8.33518C17.4744 9.49788 17.878 10.62 18.1687 11.5969C18.4542 12.5562 18.6496 13.4354 18.6496 14.087C18.6496 17.7205 15.6633 20.65 11.9996 20.65C8.3359 20.65 5.34961 17.7205 5.34961 14.087C5.34961 13.4782 5.51988 12.6745 5.7716 11.7984C6.02772 10.907 6.38517 9.88445 6.79404 8.81602C7.40279 7.22524 9.64961 7.74379 9.64961 9.35666C9.64961 9.96845 10.1555 10.4806 10.7996 10.4806C11.4437 10.4806 11.9496 9.96845 11.9496 9.35666V9.31582C11.9496 7.71975 11.9496 6.47293 12.0331 5.58575C12.0751 5.13887 12.1418 4.73758 12.2605 4.40975C12.379 4.0826 12.5804 3.74422 12.9472 3.55557ZM13.555 4.7051C13.5439 4.71886 13.5166 4.75912 13.4828 4.85235C13.4202 5.0254 13.3659 5.29772 13.3274 5.70753C13.2503 6.52617 13.2496 7.71136 13.2496 9.35666C13.2496 10.7043 12.1437 11.7806 10.7996 11.7806C9.4555 11.7806 8.34961 10.7043 8.34961 9.35666C8.34961 9.27681 8.32566 9.23883 8.308 9.21867C8.28549 9.19296 8.24877 9.17017 8.20199 9.16107C8.15522 9.15198 8.11584 9.15987 8.08991 9.17322C8.07089 9.18302 8.03663 9.20627 8.00817 9.28064C7.60628 10.3309 7.26299 11.3153 7.02105 12.1574C6.77471 13.0148 6.64961 13.6711 6.64961 14.087C6.64961 16.9847 9.0359 19.35 11.9996 19.35C14.9633 19.35 17.3496 16.9847 17.3496 14.087C17.3496 13.6377 17.2041 12.9135 16.9227 11.9677C16.6464 11.0394 16.2571 9.95465 15.8085 8.80929C15.2175 7.30044 14.7916 6.21497 14.4259 5.49194C14.243 5.1302 14.0949 4.90016 13.9746 4.76379C13.9063 4.68641 13.8653 4.65961 13.8506 4.65179C13.7536 4.64348 13.6421 4.66381 13.555 4.7051Z" fill="#FCC246"/>
              <path fillRule="evenodd" clipRule="evenodd" d="M5.99961 13.35C6.35859 13.35 6.64961 13.641 6.64961 14C6.64961 16.9547 9.04489 19.35 11.9996 19.35C14.9543 19.35 17.3496 16.9547 17.3496 14C17.3496 13.641 17.6406 13.35 17.9996 13.35C18.3586 13.35 18.6496 13.641 18.6496 14C18.6496 17.6727 15.6723 20.65 11.9996 20.65C8.32692 20.65 5.34961 17.6727 5.34961 14C5.34961 13.641 5.64062 13.35 5.99961 13.35Z" fill="#2B2D3E"/>
            </svg>
            </div>
            <div className={s.ratingData}>
              {level.pulses}
            </div>
          </div>
        }
        {
          profile?.role === "seminarian" && <div className={s.rating}>
          <div className={s.ratingIcon}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path fillRule="evenodd" clipRule="evenodd" d="M12.9472 3.55557C13.2676 3.3908 13.6484 3.32107 14.0064 3.36095C14.4116 3.40609 14.7199 3.64354 14.9495 3.9038C15.1789 4.16382 15.3859 4.50947 15.586 4.90525C15.9831 5.69043 16.4311 6.83423 17.004 8.29688L17.019 8.33518C17.4744 9.49788 17.878 10.62 18.1687 11.5969C18.4542 12.5562 18.6496 13.4354 18.6496 14.087C18.6496 17.7205 15.6633 20.65 11.9996 20.65C8.3359 20.65 5.34961 17.7205 5.34961 14.087C5.34961 13.4782 5.51988 12.6745 5.7716 11.7984C6.02772 10.907 6.38517 9.88445 6.79404 8.81602C7.40279 7.22524 9.64961 7.74379 9.64961 9.35666C9.64961 9.96845 10.1555 10.4806 10.7996 10.4806C11.4437 10.4806 11.9496 9.96845 11.9496 9.35666V9.31582C11.9496 7.71975 11.9496 6.47293 12.0331 5.58575C12.0751 5.13887 12.1418 4.73758 12.2605 4.40975C12.379 4.0826 12.5804 3.74422 12.9472 3.55557ZM13.555 4.7051C13.5439 4.71886 13.5166 4.75912 13.4828 4.85235C13.4202 5.0254 13.3659 5.29772 13.3274 5.70753C13.2503 6.52617 13.2496 7.71136 13.2496 9.35666C13.2496 10.7043 12.1437 11.7806 10.7996 11.7806C9.4555 11.7806 8.34961 10.7043 8.34961 9.35666C8.34961 9.27681 8.32566 9.23883 8.308 9.21867C8.28549 9.19296 8.24877 9.17017 8.20199 9.16107C8.15522 9.15198 8.11584 9.15987 8.08991 9.17322C8.07089 9.18302 8.03663 9.20627 8.00817 9.28064C7.60628 10.3309 7.26299 11.3153 7.02105 12.1574C6.77471 13.0148 6.64961 13.6711 6.64961 14.087C6.64961 16.9847 9.0359 19.35 11.9996 19.35C14.9633 19.35 17.3496 16.9847 17.3496 14.087C17.3496 13.6377 17.2041 12.9135 16.9227 11.9677C16.6464 11.0394 16.2571 9.95465 15.8085 8.80929C15.2175 7.30044 14.7916 6.21497 14.4259 5.49194C14.243 5.1302 14.0949 4.90016 13.9746 4.76379C13.9063 4.68641 13.8653 4.65961 13.8506 4.65179C13.7536 4.64348 13.6421 4.66381 13.555 4.7051Z" fill="#FCC246"/>
              <path fillRule="evenodd" clipRule="evenodd" d="M5.99961 13.35C6.35859 13.35 6.64961 13.641 6.64961 14C6.64961 16.9547 9.04489 19.35 11.9996 19.35C14.9543 19.35 17.3496 16.9547 17.3496 14C17.3496 13.641 17.6406 13.35 17.9996 13.35C18.3586 13.35 18.6496 13.641 18.6496 14C18.6496 17.6727 15.6723 20.65 11.9996 20.65C8.32692 20.65 5.34961 17.6727 5.34961 14C5.34961 13.641 5.64062 13.35 5.99961 13.35Z" fill="#2B2D3E"/>
            </svg>
            </div>
            <div className={s.ratingData}>
              {level.pulses}
            </div>
          </div>
        }
        
        {(profile?.role === "seminarian" || profile?.role === "children")
          && <Further  />
        }
       
        
       
      </div>
      
      {profile.role === 'seminarian' && <>
        <div className={classNames(s.buttonsWrapper, 'hideMobile')}>
          <button className={classNames(s.btnVideo)} onClick={() => openAddVideoLesson()}>
            <span className={s.loadVideoText}>Загрузить видео с семинара</span>
            <span className={s.icoWrap}>
              <GetIco className={s.ico} icon='video_camera' />
            </span>


          </button>
        </div>
      </>
      }
      <Translation/>
     
        
      {(profile?.roles?.length > 1 && profile.roles[0].item_name !== 'children') &&
          <RoleList userRole={profile.role} roles={profile.roles}/>}
      {
        ((profile?.role === "children" && !isDisableStudentSupportRequests) || (profile?.role !== "children" && !isDisableEmployeeSupportRequests)) && <button className={s.button} title='Обратная связь' onClick={handleClick}>
          <Feedback />
        </button>
      }
      

      
      <Notification />

      <button className={`${s.button}`} title='Выйти' onClick={handleLogout}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M7 7.13193V6.61204C7 4.46614 7 3.3932 7.6896 2.79511C8.37919 2.19703 9.44136 2.34877 11.5657 2.65224L15.8485 3.26408C18.3047 3.61495 19.5327 3.79039 20.2664 4.63628C21 5.48217 21 6.72271 21 9.20377V14.7962C21 17.2773 21 18.5178 20.2664 19.3637C19.5327 20.2096 18.3047 20.385 15.8485 20.7359L11.5657 21.3478C9.44136 21.6512 8.37919 21.803 7.6896 21.2049C7 20.6068 7 19.5339 7 17.388V17.066" stroke="#2B2D3E" strokeWidth="1.5"/>
        <path d="M16 12L16.5857 11.5315L16.9605 12L16.5857 12.4685L16 12ZM4 12.75C3.58579 12.75 3.25 12.4142 3.25 12C3.25 11.5858 3.58579 11.25 4 11.25V12.75ZM12.5857 6.53148L16.5857 11.5315L15.4143 12.4685L11.4143 7.46852L12.5857 6.53148ZM16.5857 12.4685L12.5857 17.4685L11.4143 16.5315L15.4143 11.5315L16.5857 12.4685ZM16 12.75H4V11.25H16V12.75Z" fill="#2B2D3E"/>
      </svg>
      </button>
    </header>
    <QModals type={type} active={show} setActive={setShow}/>
  </>
}

function showUserCustomId(profile) {
  let customId = profile?.custom_id

  return customId && (
    <span className={s.customId}>
      {customId}
    </span>
  );
}