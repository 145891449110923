import { courseAPI, directionAPI, thematicsAPI } from '../api';
import { nextId } from '../helpers/nextId';
import { isJson } from '@/helpers/isJson';
import { jsonConvert } from '@/helpers/jsonConvert';
import { tiptapValueEmpty } from '@/helpers/new/tiptapValueEmpty';

import { nanoid } from "nanoid";

const SET_THEMATICS_TYPE = 'impulse/courseEditor/SET_THEMATICS_TYPE';

const GET_THEMATICS = 'impulse/courseEditor/GET_THEMATICS';
const SET_THEMATIC = 'impulse/courseEditor/SET_THEMATIC';

const GET_DIRECTIONS = 'impulse/courseEditor/GET_DIRECTIONS';
const SET_DIRECTION = 'impulse/courseEditor/SET_DIRECTION';

const GET_COURSES = 'impulse/courseEditor/GET_COURSES';
const SET_COURSE = 'impulse/courseEditor/SET_COURSE';
const EDIT_COURSE = 'impulse/courseEditor/EDIT_COURSE';
const DEL_COURSE = 'impulse/courseEditor/DEL_COURSE';

const SET_EDIT_DATA = 'impulse/courseEditor/SET_EDIT_DATA';
const SET_LESSON = 'impulse/courseEditor/SET_LESSON';



const ADD_EDIT_DATA_LESSON_TASKS =
	'impulse/courseEditor/ADD_EDIT_DATA_LESSON_TASKS';
const REMOVE_LESSON_TASKS = 'impulse/courseEditor/REMOVE_LESSON_TASKS';
const ADD_NEW_LESSON_TASKS = 'impulse/courseEditor/ADD_NEW_LESSON_TASKS';
const MOVE_LESSON_TASKS = 'impulse/courseEditor/MOVE_LESSON_TASKS';

let initialState = {
	thematicType: 'school',
	thematics: [],
	selectThematic: null,
	directions: [],
	selectDirection: null,
	courses: [],
	selectCourse: null,
	selectLesson: null,
	editData: {},
};

const courseEditorReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_THEMATICS_TYPE:
			return { ...state, thematicType: action.thType };

		case GET_THEMATICS:
			return { ...state, thematics: action.thematics };
		case SET_THEMATIC:
			return { ...state, selectThematic: action.id };

		case GET_DIRECTIONS:
			return { ...state, directions: action.directions };
		case SET_DIRECTION:
			window.localStorage.setItem('directionForEditTask', action.id);
			return { ...state, selectDirection: action.id };

		case GET_COURSES:
			return { ...state, courses: action.courses };
		case EDIT_COURSE:
			return {
				...state,
				courses: state.courses.map((course) =>
					course.id === action.data.id ? { ...course, ...action.data } : course
				),
			};
		case DEL_COURSE:
			return {
				...state,
				courses: state.courses.filter(({ id }) => id !== action.id),
			};
		case SET_COURSE:
			return { ...state, selectCourse: action.id };

		case SET_EDIT_DATA:
			return { ...state, editData: action.data };
		case SET_LESSON:
			return { ...state, selectLesson: action.id };
		case ADD_EDIT_DATA_LESSON_TASKS: {
			const list = nameList(state);
			let sorting = -1;
			return {
				...state,
				editData: {
					...state.editData,
					[list]: [
						...action.list
							?.filter((item) =>
								state.editData[list].find((i) => i?.id === item.id)
									? false
									: true
							)
							?.map((item) => {
								sorting++;
								return { ...item, sorting };
							}),
						...state.editData[list].map((item) => {
							sorting++;
							return { ...item, sorting };
						}),
					],
				},
			};
		}

		case ADD_NEW_LESSON_TASKS: {
			const list = nameList(state);
			return {
				...state,
				editData: {
					...state.editData,
					[list]: [
						{
							devID: nextId(state.editData[list], 'devID'),
							devIdUnique:nanoid(),
							type: action.typeNT,
							direction_id: window.localStorage.getItem('directionForEditTask'),
						},
						...state.editData[list],
					],
				},
			};
		}

		case REMOVE_LESSON_TASKS: {
			const list = nameList(state);

			const newState = {
				...state,
				editData: {
					...state.editData,
					[list]: [
						...state.editData[list]?.filter((item) => {
							if (action.data.type === 'new')
								return item.devID !== action.data.id ? true : false;
							else return item.id !== action.data.id ? true : false;
						}),
					],
				},
			};

			return newState;
		}

		case MOVE_LESSON_TASKS: {
			const list = nameList(state);
			return {
				...state,
				editData: {
					...state.editData,
					[list]: action.list,
				},
			};
		}
		default:
			return state;
	}
};

export const setThematicsType = (thType) => ({
	type: SET_THEMATICS_TYPE,
	thType,
});
export const setThematicsCE = (id) => ({ type: SET_THEMATIC, id });
export const setDirectionCE = (id) => ({ type: SET_DIRECTION, id });
export const setCourseCE = (id) => ({ type: SET_COURSE, id });
export const setLessonCE = (id) => ({ type: SET_LESSON, id });

export const setEditDataCE = (data) => {
	const changedDate = {
		...data,
		description: isJson(data.description) ? jsonConvert(data.description) : !tiptapValueEmpty(data.description) ? data.description : ''
	}
	return { type: SET_EDIT_DATA, data: changedDate };
};

export const removeTaskCE = (data) => ({ type: REMOVE_LESSON_TASKS, data });
export const addNewTaskCE = (typeNT) => ({
	type: ADD_NEW_LESSON_TASKS,
	typeNT,
});
export const moveTaskCE = (list) => ({ type: MOVE_LESSON_TASKS, list });

export const addEditDataLessonTaskCE = (list) => ({
	type: ADD_EDIT_DATA_LESSON_TASKS,
	list,
});

const setThematics = (thematics) => ({ type: GET_THEMATICS, thematics });
export const getThematicsCE = () => (dispatch) =>
	thematicsAPI.getAll().then((res) => dispatch(setThematics(res.data.data)));

const setDirections = (directions) => ({ type: GET_DIRECTIONS, directions });
export const getDirectionsCE = () => (dispatch) =>
	directionAPI.getAll().then((res) => dispatch(setDirections(res.data.data)));

export const setCourses = (courses) => ({ type: GET_COURSES, courses });
export const getCoursesCE = (directionID) => (dispatch) =>
	courseAPI
		.getAll({ filter: [`[direction_id][eq]=${directionID}`] })
		.then((res) => dispatch(setCourses(res.data.data)));

const editCourse = (data) => ({ type: EDIT_COURSE, data });
export const editCourseCE =
	({ id, ...data }) =>
	(dispatch) =>
		courseAPI
			.upd({ id: id, data: data })
			.then((res) => dispatch(editCourse(res.data)));

const removeCourse = (id) => ({ type: DEL_COURSE, id });
export const removeCourseCE = (id) => (dispatch) =>
	courseAPI.remove(id).then(() => dispatch(removeCourse(id)));

const nameList = (state) => {
	if (state.editData.lessonTasks !== undefined) return 'lessonTasks';
	else if (state.editData.controlTasks !== undefined) return 'controlTasks';
	else if (state.editData.probeTasks !== undefined) return 'probeTasks';
};

export default courseEditorReducer;
