
import s from './index.module.css';
import tiptap from '../../../../../../common.module.css';
import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { InputWhite } from '@UI/New/InputWhite';
import Button from '@UI/Button';
import CheckboxViolet from '@UI/New/ChecboxViolet/CheckboxViolet';
import { useAddAlert } from '@/hooks/useAddAlert';
import { surveyApi } from '@/api'
import { appendToFormData } from '@/helpers/new/appendToFormData'
import Audio from '../../../Common/Audio';
import Img from '../../../Common/Img';
import Tiptap from "@UI/Tiptap";
import { tiptapValueEmpty } from '@/helpers/new/tiptapValueEmpty';
import Preloader from '@UI/Preloader';
import { InputSelect } from "@UI/InputNew/Input";
import JsonPars from "@UI/JsonPars/JsonPars";

const Edit = ({task, chengeTasks, clickEdit, deleteFromModifiedQuestions, pages}) => {
    const [saving, setSaving] = useState(false);

    const addAlert = useAddAlert();
    const { id } = useParams();
    const add = useCallback((type) => chengeTasks({taskId: task.id, task, field: type, action: 'add'}), [chengeTasks, task.id]);

    const save = () => {
        if (tiptapValueEmpty(task.questionText)) {
            addAlert({ title: 'Ошибка', text: 'Вопрос не заполнен', type: 'error' });
            return;
        }

        const formData = new FormData();
        appendToFormData(formData, {
            ...task,
            questionText: JSON.stringify(task.questionText),
            delete_files: JSON.stringify(task.delete_files),
            surveyId: id
        });
        setSaving(true)
        surveyApi.updateTask(formData)
                .then(({data}) => {
                    if(data.status == "success") {
                        addAlert({title: 'Успешно', text: 'Вопрос успешно сохранен'})
                        console.log(33)
                        clickEdit(task)
                        deleteFromModifiedQuestions(task.id)
                    }
                })
                .catch(()=>{
                    console.log(55)
                    addAlert({title: 'Ошибка', text: 'При сохранении произошла ошибка', type: 'error'})
                })
                .finally(()=>{
                    setSaving(false)
                })
    };
    
    
    return <div className={s.wrapper}>
        <div className={s.title}>Ползунок</div>
        <div className={s.inputWrapper}>
            <div className={s.nameLabel}>
                Введите вопрос 
            </div>
            
            <Tiptap
                value={task.questionText}
                setValue={
                    (value) => chengeTasks({taskId: task.id, task, field: 'questionText', value})
                }
                tiptapClassWrapper={s.inputQuestionText}
                tiptapClassContent={tiptap.tiptapClassContent}
                tiptapClassContentProseMirror={tiptap.tiptapClassContentProseMirror}
                placeholder='Напишите текст вопроса'
            />
        </div>
        
        <Img {...{ add, chengeTasks, task}} />
        
        <Audio {...{ add, chengeTasks, task}} />
        
        <div className={s.inputWrapperMin}>
            <div className={s.nameLabel}>
                Минимальное значение
            </div>
            <InputWhite 
                value={task.min}
                onChange={(value) => {chengeTasks({taskId: task.id, task, field: 'min', value})}}
                className={s.inputMin}
                min="0"
                max="1000"
                type="number"
            />
        </div>

        <div className={s.inputWrapperMax}>
            <div className={s.nameLabel}>
                Максимальное значение
            </div>
            <InputWhite 
                value={task.max}
                onChange={(value) => {chengeTasks({taskId: task.id, task, field: 'max', value})}}
                className={s.inputMax}
                min="0"
                max="1000"
                type="number"
            />
        </div>

        <div className={s.inputWrapperStep}>
            <div className={s.nameLabel}>
             Укажите шаг хода
            </div>
            <InputWhite 
                value={task.step}
                onChange={(value) => {chengeTasks({taskId: task.id, task, field: 'step', value})}}
                className={s.inputStep}
                min="0"
                max="1000"
                type="number"
            />
        </div>

        <div className={s.checkboxWrapper}>
            <CheckboxViolet 
                textClassName={s.checkboxText}
                blockClassName={s.checkboxBlock}
                value={!!task.countResponses}
                onClick={() => {chengeTasks({taskId: task.id, task, field: 'countResponses'})}}
                text='Считать частоту ответов' 
            />
           
        </div>
        
        <span className={s.text}>Выберите страницу завершения опроса</span>
        <InputSelect
            className={s.inputSelect}
            classNameBox={s.inputSelectBox}
            classSelectItem={s.selectItem}
            newDesign 
            placeholder='Выберите страницу'
            options={ pages.map((el) => ({label: <JsonPars json={el.title} />, value: el.id})) } 
            value={task.finish_id} 
            onChange={(e)=> chengeTasks({taskId: task.id, task, field: 'finish_id', value: e.target.value})}
        />
        

        <div className={s.buttonAndPreloader}>
            <Button 
                onClick={save}
                newDesign
                disable={saving}
                className={s.save}
                >Сохранить</Button>
            {
                saving && <Preloader />
            }
        </div>
        
    </div>
}

export default Edit;