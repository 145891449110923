import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    surveys: [],
    surveysLoadingStatus: 'idle'
};

const surveysSlice = createSlice({
    name: 'surveys',
    initialState,
    reducers: {
        surveysFetching: (state) => { 
            state.surveysLoadingStatus = 'loading'; 
        },
        surveysFetched: (state, action) => { 
            state.surveysLoadingStatus = 'idle';
            state.surveys = action.payload; 
        },
    }
});

const { actions, reducer } = surveysSlice;

export default reducer;

export const {
    surveysFetching,
    surveysFetched
} = actions;
