import s from './index.module.css'
import style from '../../index.module.css'
import { useEffect, useState } from 'react'
import { subThemeAPI } from '../../../../../../api'
import { useInputNew } from '../../../../../UI/InputNew/useInputNew'
import { InputSelect } from '../../../../../UI/InputNew'
import commonStyle from '../Common/common.module.css'



export const Subtheme = ({ data, edit, showErrors, setErrorsList, flagEasily }) => {

  const [list, setList] = useState()
  const getList = () => subThemeAPI.getAll({ filter: [`[task_topic_id][eq]=${data?.topic_id}`] }).then(res => {
    if (res) setList(res?.data?.data?.map(item => ({ label: item.name, value: item.id })))
  })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { if (data?.topic_id) getList() }, [data?.topic_id])

  const [bindSelect] = useInputNew({
    name: 'subtopic_id',
    value: data?.subtopic_id,
    onChange: e => edit(prev => ({ ...prev, 'subtopic_id': e })),
    placeholder: 'Подтема',
    ...(flagEasily===0 ? {
      validate: showErrors,
      onError: setErrorsList,
      errorText: 'Не выбрана подтема',
    } : {}),
   
    errorOptions: 'Не выбрана тема',
    options: list,
  })

  return <div className={s.wrapper}>
    <span className={style.heading}>Выберите подтему задачи:</span>
    <InputSelect classNameInputBoxSelect={commonStyle.inputBoxSelect} className={style.inputNew} {...bindSelect} />
  </div>
}
