import s from './index.module.css'
import style from '../../index.module.css'
import classNames from 'classnames';
import { DragList } from './DragList';
import update from 'immutability-helper'
import { useEffect, useState } from 'react';
import { ButtonAdd } from '../Common/Buttons'

export const MatchList = ({ data, edit }) => {
  const generateId = (leftList = [], rightList = []) => {
    const generate = () => Math.floor(Math.random() * 1000)
    const listIds = []

    leftList.forEach(item => listIds.push(item.id))
    rightList.forEach(item => listIds.push(item.id))

    const nextId = () => {
      let id = generate()

      while (listIds.indexOf(id) !== -1) {
        id = generate()
      }

      return id
    }

    return nextId()
  }

  const sortVariants = (part, list) => {
    if (data.correct_answer?.length > 0) {
      const arr = []
      data.correct_answer.forEach(item => arr.push(data.answer_options[part].find(i => i.id === item[part])))
      return arr
    }
    return [{ text: '', img: '', id: generateId(list) }]
  }

  const [variantsL, setVariantsL] = useState(sortVariants('left'))
  const [variantsR, setVariantsR] = useState(sortVariants('right', variantsL))

  useEffect(() => {
    edit(prev => ({
      ...prev, 'answer_options': { left: variantsL, right: prev['answer_options'].right }
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variantsL])

  useEffect(() => {
    edit(prev => ({
      ...prev,
      'answer_options': { left: prev['answer_options'].left, right: variantsR }
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variantsR])

  const add = () => {
    const ad = (prevState) => update(prevState, { $push: [{ text: '', img: '', id: generateId(variantsL, variantsR) }] })
    setVariantsL(ad)
    setVariantsR(ad)
  }

  const remove = (index) => {
    setVariantsL(prev => prev.filter(item => item.id !== prev[index]?.id))
    setVariantsR(prev => prev.filter(item => item.id !== prev[index]?.id))
  }

  const editVal = ({ part, key, val, id }) => {
    const edit = prev => prev.map(item => (item.id === id) ? { ...item, [key]: val } : item);
    (part === 'left') ? setVariantsL(edit) : setVariantsR(edit)
  }



  return <div className={s.wrapper}>
    <span className={style.heading}>Заполните схему:</span>
    <div>
      <div className={s.list}>
        <div>
          <DragList state={variantsL} setState={setVariantsL} editVal={data => editVal({ part: 'left', ...data })} />
        </div>
        <div>
          <DragList state={variantsR} setState={setVariantsR} remove={remove} editVal={data => editVal({ part: 'right', ...data })} />
        </div>
        <ButtonAdd onClick={add} />
      </div>
    </div>
  </div>
}
