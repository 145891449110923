import s from './index.module.css'
import style from '../../index.module.css'
import commonStyle from '../Common/common.module.css'
import { getInArr } from '../../../../../../helpers/getInArr'
import { InputFormate } from '../../../../../UI/InputNew'
import { useInputNew } from '../../../../../UI/InputNew/useInputNew'
import classNames from 'classnames'


export const Question = ({ data, edit, setErrorsList, showErrors }) => {
  const title = getInArr(data.type, ['order', 'multiple-choice']) ? 'Вопрос и варианты ответа' : 'Вопрос'

  const [bindFormate] = useInputNew({
    name: 'formate',
    value: data?.question,
    onChange: e => edit(prev => ({ ...prev, question: e })),
    onError: setErrorsList,
    placeholder: 'Напишите текст задачи',
    validate: showErrors,
    errorText: 'Напиши вопрос задания!'
  })

  return <div className={s.wrapper}>
    <span className={style.heading}>{title}:</span>
    <InputFormate 
      tiptapClassContent={commonStyle.tiptapClassContent}
      tiptapClassContentProseMirror={commonStyle.tiptapClassContentProseMirror}
      className={classNames(style.inputNew, commonStyle.inputFormate)} 
      {...bindFormate} />
  </div>
}
