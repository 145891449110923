
import { NodeViewWrapper } from '@tiptap/react'
import React, { useRef } from 'react'
import 'katex/dist/katex.min.css'
import katex from 'katex';
import s from './Component.module.css'
import { IconMath } from './IconMath'
import { useOutside } from '../../../hooks'
import cn from 'classnames'




const Tiptap = props => {

  const [ref, isShow, setIsShow] = useOutside(true)
  const increase = e => {
    props.updateAttributes({
      value: e.target.value,
    })
  }

  const refInput = useRef()

  const paste = text => {
    const value = props.node.attrs.value
    const input = refInput.current
    const start = input.selectionStart
    const end = input.selectionEnd
    const finText = value.substring(0, start) + text + value.substring(end)

    props.updateAttributes({
      value: finText,
    })
    input.focus()
    input.selectionEnd = (start === end) ? (end + text.length) : end
  }


  const formatString = (text) => {
    let newText = text.replace(/\n/g, '\\newline ').replace(/ /g, '\\,');;
    const mathSymbolsRegex = /([+=*:<>∩∪≤≥∈∥-])/g; // Обрабатываем символы +, =, *, -, <, >, ∩, ∪,
    // Оборачиваем каждый символ в \text{}
    newText = newText.replace(mathSymbolsRegex, '\\text{$1}');
    // Обрабатываем \cdot, добавляя неразрывные пробелы вокруг

    // Обрабатываем \cap и \cup
    newText = newText.replace(/\\cap/g, '~\\cap~');
    newText = newText.replace(/\\cup/g, '~\\cup~');
    newText = newText.replace(/\\leqslant/g, '~\\leqslant~');
    newText = newText.replace(/\\geqslant/g, '~\\geqslant~');
    newText = newText.replace(/\\parallel/g, '~\\parallel~');
    newText = newText.replace(/\\notin/g, '~\\notin~');
    newText = newText.replace(/\\in/g, '~\\in~');

    newText = newText.replace(/\\cdot/g, '~\\cdot~');
    // Отдельно обрабатываем символ процента, заменяя его на \%
    newText = newText.replace(/%/g, '\\%');

    return newText;
  };

  const renderFormula = (formula) => {
    const formattedFormula = formatString(formula);
    return katex.renderToString(formattedFormula, {
      throwOnError: false,
      displayMode: false, // Для отображения в отдельном блоке
    });
  };

  return <NodeViewWrapper className="react-component">
    {isShow && <span ref={ref} className={s.mathInputWrapper}>
      <textarea ref={refInput} className={s.mathInput} value={props.node.attrs.value} onChange={increase} autoFocus={true} />
      <Keyboard onChange={paste} />
    </span>}
    {!isShow && <span className={cn('tiptap-math', s.mathLatex)}
      hint={props.node.attrs.value?.length > 3 ? '' : 'введите функцию'}
      onClick={() => setIsShow(true)}
      dangerouslySetInnerHTML={{ __html: renderFormula(props.node.attrs.value) }}
    />}
  </NodeViewWrapper>

}

const Keyboard = ({ onChange }) => {
  const buttons = ['sqrt', 'int', 'frac', 'infty', '^', 'sin', 'alpha', 'gamma', '_', 'cos', 'beta', 'pi']

  const onClick = type => {
    const add = text => onChange(text)
    switch (type) {
      case '^':
        add('^')
        break
      case '_':
        add('_')
        break
      case 'sqrt':
        add('\\sqrt[n]{из}')
        break
      case 'cos':
        add('\\cos')
        break
      case 'sin':
        add('\\sin')
        break
      case 'infty':
        add('\\infty')
        break
      case 'alpha':
        add('\\alpha')
        break
      case 'beta':
        add('\\beta')
        break
      case 'gamma':
        add('\\gamma')
        break
      case 'pi':
        add('\\pi')
        break
      case 'frac':
        add('\\frac{делимое}{делитель}')
        break

      case 'int':
        add('\\int_{от}^{до}')
        break
      default:
        break
    }
  }

  const link = () => window.open('https://ru.wikibooks.org/wiki/Математические_формулы_в_LaTeX#Ряды_и_интегралы')

  return <div className={s.mathKeyboard}>
    {buttons?.map(item => <ButtonKey key={item} type={item} onClick={onClick} />)}
    <button className={s.mathLink} onClick={link}>ещё</button>
  </div>
}

const ButtonKey = ({ type, onClick }) => {
  return <button onClick={() => onClick(type)}><IconMath type={type} /></button>
}



export default Tiptap
