import s from './index.module.css'
import style from '../../index.module.css'
import commonStyle from '../Common/common.module.css'
import { useInputNew } from '../../../../../UI/InputNew/useInputNew'
import { InputFormate } from '../../../../../UI/InputNew'
import classNames from 'classnames'



export const Hint = ({ data, edit }) => {
  const [bindFormate] = useInputNew({
    name: 'prompt',
    value: data?.prompt,
    onChange: e => edit(prev => ({ ...prev, prompt: e })),
    placeholder: 'Напишите подсказку',
  })
  return <div className={s.wrapper}>
    <span className={style.heading}>Подсказка для учеников:</span>
    <InputFormate
      tiptapClassContent={commonStyle.tiptapClassContent}
      tiptapClassContentProseMirror={commonStyle.tiptapClassContentProseMirror}
      className={classNames(style.inputNew, commonStyle.inputFormate)} {...bindFormate} />
  </div>
}