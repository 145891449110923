
import s from './index.module.css';
import tiptap from '../../../../../../common.module.css';
import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { InputWhite } from '@UI/New/InputWhite';
import Button from '@UI/Button';
import CheckboxViolet from '@UI/New/ChecboxViolet/CheckboxViolet';
import { PlusIcon } from '@/utils/icons';
import classNames from 'classnames';
import { useAddAlert } from '@/hooks/useAddAlert';
import { surveyApi } from '@/api'
import { appendToFormData } from '@/helpers/new/appendToFormData'
import Audio from '../../../Common/Audio';
import Img from '../../../Common/Img';
import Add from '../../../Common/Add';
import Tiptap from "@UI/Tiptap";
import { tiptapValueEmpty } from '@/helpers/new/tiptapValueEmpty';
import Preloader from '@UI/Preloader';
import { InputSelect } from "@UI/InputNew/Input";
import JsonPars from "@UI/JsonPars/JsonPars";

const Edit = ({task, chengeTasks, clickEdit, deleteFromModifiedQuestions, pages}) => {
    const [saving, setSaving] = useState(false);
    const addAlert = useAddAlert();
    const { id } = useParams();

    const add = useCallback((type, setLoading) => chengeTasks({taskId: task.id, task, field: type, action: 'add', setLoading}), [chengeTasks, task.id]);
    const [loading, setLoading] = useState({
        state: false,
        ids: []
    });
    const isNumber = (str) => {
        str = String(str);
        const hasConsecutiveSymbols = /[,.;]{2,}/.test(str);
        if (hasConsecutiveSymbols) {
            return false;
        }
        const cleanedStr = str.replace(/,/g, '.').replace(/\s+/g, '');//обработка
        return !isNaN(cleanedStr) && cleanedStr.trim() !== '';
    }
    const save = () => {
        if (tiptapValueEmpty(task.questionText)) {
            addAlert({ title: 'Ошибка', text: 'Вопрос не заполнен', type: 'error' });
            return;
        }
    
        if (!task.answerOptions.length) {
            addAlert({ title: 'Ошибка', text: 'Добавьте варианты ответов', type: 'error' });
            return;
        }

        const hasEmptyAnswersNotNumber = task.answerOptions.some(element => tiptapValueEmpty(element.option));
        if (hasEmptyAnswersNotNumber) {
            addAlert({ title: 'Ошибка', text: 'Варианты ответов не могут быть пустыми', type: 'error' });
            return;
        }
    
    
        if (task.number && task.answerOptions.some(element => !isNumber(element.unit))) {
            addAlert({ title: 'Ошибка', text: 'Вес должен быть числовым значением', type: 'error' });
            return;
        }

        const formData = new FormData();
        const {answerOptions, ...taskSave} = task;

        appendToFormData(formData, {
            ...taskSave,
            answerOptions: answerOptions.map((el) => ({...el, option: JSON.stringify(el.option)})),
            questionText: JSON.stringify(task.questionText),
            delete_files: JSON.stringify(task.delete_files),
            surveyId: id
        });
        setSaving(true);
        surveyApi.updateTask(formData)
                .then(({data}) => {
                    if(data.status == "success") {
                        addAlert({title: 'Успешно', text: 'Вопрос успешно сохранен'})
                        clickEdit(task)
                        deleteFromModifiedQuestions(task.id)
                    }
                })
                .catch(()=>{
                    addAlert({title: 'Ошибка', text: 'При сохранении произошла ошибка', type: 'error'})
                })
                .finally(()=>{
                    setSaving(false)
                })
    };
    
    return <div className={s.wrapper}>
        <div className={s.title}>Тестовое задание</div>
        <div className={s.inputWrapper}>
            <div className={s.nameLabel}>
                Введите вопрос 
            </div>
            <Tiptap
                value={task.questionText}
                setValue={
                    (value) => chengeTasks({taskId: task.id, task, field: 'questionText', value})
                }
                tiptapClassWrapper={s.inputQuestionText}
                tiptapClassContent={tiptap.tiptapClassContent}
                tiptapClassContentProseMirror={tiptap.tiptapClassContentProseMirror}
                placeholder='Напишите текст вопроса'
            />
        </div>
        
        <Img {...{ add, chengeTasks, task}} />
         
        <Audio {...{ add, chengeTasks, task}} />

        <div className={s.answerOptionsWrapper}>
            <Add classNameIcon={s.answerOptionsAddIcon} text='Варианты ответов' add={add} type='answerOptions' /> 
            {
                task.answerOptions.map((option, index) => <div key={option.id} className={s.optionWrapper}> 
                    <div className={s.numberOption}>{index+1}</div>
                    
                    {
                        <Tiptap
                            value={option.option}
                            setValue={
                                (value) => chengeTasks({taskId: task.id, task, field: 'answerOptions', value, idInArr: option.id})
                            }
                            tiptapClassWrapper={s.inputOption}
                            tiptapClassContent={tiptap.tiptapClassContent}
                            tiptapClassContentProseMirror={tiptap.tiptapClassContentProseMirror}
                            placeholder={`Вариант №${index+1}`}
                        />
                    }
                    {
                        !!task.number && <InputWhite 
                            value={option.unit}
                            onChange={(value) => {chengeTasks({taskId: task.id, task, field: 'answerOptions', fieldInArr: 'unit', value, idInArr: option.id})}}
                            placeholder='Вес'
                            className={s.inputUnit}
                        />
                    }
                    <div 
                        onClick={()=> {
                            !loading.ids.includes(option.id) &&
                            chengeTasks({
                                taskId: task.id, 
                                task, 
                                field: 'answerOptions', 
                                action: 'remove', 
                                idInArr: option.id,
                                setLoading: (state) => {
                                    setLoading((prevLoading) => ({
                                        state,
                                        ids: state ? [...prevLoading.ids, option.id] : prevLoading.ids.filter((el) => el !== option.id)
                                    }));
                                } 
                            });
                        }} 
                        className={classNames(s.remove, s.removeOption, {[s.disable]: loading.ids.includes(option.id)})}>
                            <PlusIcon />
                    </div>
                </div>
                )
            }
        </div>

        <div className={s.checkboxWrapper}>
            <CheckboxViolet 
                textClassName={s.checkboxText}
                blockClassName={s.checkboxBlock}
                value={!!task.number}
                onClick={() => {chengeTasks({taskId: task.id, task, field: 'number'})}}
                text='Задание с числовым ответом, считать по нему среднее в аналитике' 
            />
            <CheckboxViolet 
                textClassName={s.checkboxText}
                blockClassName={s.checkboxBlock}
                value={!!task.countResponses}
                onClick={() => {chengeTasks({taskId: task.id, task, field: 'countResponses'})}}
                text='Считать частоту ответов' 
            />
            <CheckboxViolet 
                textClassName={s.checkboxText}
                blockClassName={s.checkboxBlock}
                value={!!task.multiple}
                onClick={() => {chengeTasks({taskId: task.id, task, field: 'multiple'})}}
                text='Множественный выбор' 
            />
            
        </div>

        <span className={s.text}>Выберите страницу завершения опроса</span>
        <InputSelect
            className={s.inputSelect}
            classNameBox={s.inputSelectBox}
            classSelectItem={s.selectItem}
            newDesign 
            placeholder='Выберите страницу'
            options={ pages.map((el) => ({label: <JsonPars json={el.title} />, value: el.id})) } 
            value={task.finish_id} 
            onChange={(e)=> chengeTasks({taskId: task.id, task, field: 'finish_id', value: e.target.value})}
        />
       
        <div className={s.buttonAndPreloader}>
            <Button 
                onClick={save}
                newDesign
                disable={saving}
                className={s.save}
                >Сохранить</Button>
            {
                saving && <Preloader />
            }
        </div>
    </div>
}

export default Edit;