import { useState, useMemo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { recordingLessonAPI } from '../../../api'
import { useAddAlert } from '../../../hooks/useAddAlert'
import Button from '../../UI/Button'
import Preloader from '../../UI/Preloader'
import s from './index.module.css'
import { Form, Select, DatePicker, Typography, Input } from 'antd';
import classNames from 'classnames'
import { PlusIcon } from '@/utils/icons'
import { selectProfile } from '@store/profile.reducer'
import { useGetDirection } from '@hooks/useGetDirection'
import { getInArr } from '@helpers/getInArr'
import { useGetGroup } from '../../../hooks/useGetGroup'
import { nanoid } from 'nanoid';
import dayjs from 'dayjs'

const { TextArea } = Input;
const { Item } = Form;
const { Text } = Typography;

const AddVideoLesson = ({ data, closeModal }) => {
  const [form] = Form.useForm();

  const role = useSelector(selectProfile).role

  const initState = { 
    direction_id: null, 
    groups_id: [], 
    lesson_date: '', 
    videos: [],
  }
  
  const direction = useGetDirection(getInArr(role, ['seminarian', 'senior-seminarian']))

  const [state, setState] = useState(data?.type === 'add' ? initState : data.data)

  // Преобразуем videos в объект для initialValues
  const initialValues = useMemo(() => {
    const videoValues = {};
    state.videos.forEach((el) => {
      videoValues[`video_name_${el.id}`] = el.name;
      videoValues[`video_link_${el.id}`] = el.link_to_video;
    });

    return {
      direction_id: state.direction_id,
      groups_id: state.groups_id,
      lesson_date: state.lesson_date ? dayjs(state.lesson_date) : null,
      ...videoValues,
    };
  }, [state]);

  // Устанавливаем начальные значения формы
  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);
  
  const filter = useMemo(() => state.direction ? [`[direction_id][eq]=${state.direction}`] : [], [state.direction])
  const groups = useGetGroup(getInArr(role, ['seminarian', 'senior-seminarian']), { filter }, ({ direction_id }) => state.direction_id ? direction_id === state.direction_id : true)

  const handleDate = (date, dateStr) => {
    setState(prev => ({ 
      ...prev, 
      lesson_date: dateStr.length > 6 
        ? dayjs(dateStr, 'DD.MM.YYYY HH:mm').format('YYYY-MM-DD HH:mm') 
        : '' 
    }));
    form.setFieldsValue({ lesson_date: date });
  };

  const handleUrl = (value, id) => {
    const newVideos = state.videos.map(el => 
      el.id === id ? { ...el, link_to_video: value } : el
    );
    setState(prev => ({ ...prev, videos: newVideos }));
  };

  const handleName = (value, id) => {
    const newVideos = state.videos.map(el => 
      el.id === id ? { ...el, name: value } : el
    );
    setState(prev => ({ ...prev, videos: newVideos }));
  };

  const [loading, setLoading] = useState(false)
  const addAlert = useAddAlert()

  const add = () => {
    setState(prev => {
      return { ...prev, videos: [...(prev?.videos ?? []), {name: '', link_to_video: '', id: nanoid(), new: true}] };
    });
  }

  const removeVideo = (id) => {
    setState(prev => {
      return { ...prev, videos: prev.videos.filter((el) => el.id !== id) };
    });
  }

  const saveAdded = () => {
    const savedData = {...state};
    if(!savedData.direction_id){
      delete savedData.direction_id;
    }

    setLoading(true)
      recordingLessonAPI.add({ data: savedData }).then(res => {
        setLoading(false)
        if (res.data.status === "success") {
          addAlert({ title: 'Запись успешно добавлена!' })
          closeModal()
        }
        else addAlert({ title: 'Упс! Ошибка', text: 'Попробуйте ещё раз или обратитесь к администратору', type: 'error' })
      })
  }
  
  const saveUpdate = () => {
    setLoading(true)
    recordingLessonAPI.upd({ id: state.id, data: state })
    .then(res => {
      if (res.data.status === "success") {
        addAlert({ title: 'Запись успешно обновлена!' })
        closeModal()
      }
      else addAlert({ title: 'Упс! Ошибка', text: 'Попробуйте ещё раз или обратитесь к администратору', type: 'error' })
    })
    .finally(()=>{
      setLoading(false)
    })
  }


  const validateForm = async () => {
    try {
      // 1. Проверка основных полей через Ant Design
      await form.validateFields();
      
      // 2. Кастомная проверка видео
      if (state.videos.length === 0) {
        throw new Error('Добавьте хотя бы одну ссылку!');
      }
      
      const hasInvalidVideos = state.videos.some(v => 
        !v.name?.trim() || !v.link_to_video?.trim()
      );
      
      if (hasInvalidVideos) {
        throw new Error('Заполните все поля для видео!');
      }
      
      return true;
    } catch (error) {
      addAlert({ title: 'Ошибка', text: error.message, type: 'error' });
      return false;
    }
  };

  const handleSubmit = async () => {
    if (!(await validateForm())) return;

    if(data?.type === 'add') {
      saveAdded();
    }
    else{
      saveUpdate();
    }
  };

  return <>
    <h2 className='modal__title'>{data?.type === 'add' ? 'Добавить' : 'Редактировать'} запись</h2>
    <Form form={form} onFinish={handleSubmit}>
      <div className={s.list}>
        <span>Если запись будет принадлежать разным курсам, то не выбирайте курс, а выберите нужные группы</span>
        <Item
          name="direction_id"
          // rules={[{ required: true, message: 'Пожалуйста, выберите курс!' }]}
          className={s.item}
        >
        <Select
            onChange={(e) => {
              setState(prev => ({ ...prev, direction_id: e === undefined ? null : e, groups_id: [] }))
              form.setFields([{ name: 'groups_id', value: [], errors: [] }]);
            }}
            // defaultValue={state.direction_id}
            options={direction.select}
            className={classNames('antdSelectNewDesign', s.select)}
            showSearch
            allowClear
            style={{width:'100%'}}
            placeholder="Выберите курс"
            filterOption={(input, option) => 
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            notFoundContent="Нет доступным Вам курсов"
            filterSort={(optionA, optionB) => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
        />
      </Item>
      
      
        <div style={{width: '100%'}} className="select-scroll-auto-departments">
          <Item
            name="groups_id"
            rules={[{ 
              required: true, 
              message: 'Пожалуйста, выберите хотя бы одну группу!',
              type: 'array',
              validator: (_, value) => 
                value && value.length > 0 
                  ? Promise.resolve() 
                  : Promise.reject(new Error('Выберите минимум 1 группу'))
            }]}

            className={s.item}
          >
            <Select
                tokenSeparators={[',']}
                mode="multiple"

                onChange={(e) => setState(prev => ({ ...prev, groups_id: e === undefined ? null : e }))}
                // defaultValue={state.groups_id}
                options={groups.select}
                className={classNames('multipleSearhNewDesign', s.multiSelect)}
                showSearch
                allowClear
                style={{width: '100%'}}
                placeholder="Выберите группы"
                filterOption={(input, option) => 
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                notFoundContent="Нет доступным Вам групп"
                filterSort={(optionA, optionB) => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
            />
          </Item>
        </div>

        <div className={s.dateWrapper}>
          <Item
            name="lesson_date"
            rules={[
              { 
                required: true, 
                message: 'Пожалуйста, выберите дату и время!',
                validator: (_, value) => 
                  value ? Promise.resolve() : Promise.reject()
              }
            ]}

            className={s.item}
          >
            <DatePicker 
              className={s.antd}
              showTime
              style={{width:'100%'}}
              onChange={handleDate}
              transitionName=""
              format="DD.MM.YYYY HH:mm"
              okKey="Выбрать"
              showToday={false}
              onKeyDown={(e) => e.preventDefault()}
            />
          </Item>
          {/* {state.lesson_date && <span className={s.date}>Выбрано: {moment(state.lesson_date, 'YYYY-MM-DD HH:mm').format('DD.MM.YYYY HH:mm')}</span>} */}
        </div>
        <div className={s.fileWrapper}>
          <div className={s.text}>Загруженные ссылки</div>
          {state?.videos?.length === 0 && (
            <Text type="danger">Добавьте хотя бы одну ссылку!</Text>
          )}
          {
            state.videos?.map((el) => {
              return(
                <div key={el.id} className={s.inputsWrapper}>
                  <Item
                  name={`video_name_${el.id}`}
                  rules = {
                    [
                      { required: true, 
                        message: 'Введите описание',
                        validator: (_, value) => {
                          // Проверяем, что поле не пустое после удаления пробелов
                          if (!value || !value.trim()) {
                            return Promise.reject(new Error('Введите описание'));
                          }
                          return Promise.resolve();
                        }
                      }
                    ]
                  }
                  
                  style={{ flex: 1 }}
                  className={s.item}
                >
                  <TextArea
                    className={classNames(s.antd, s.antdTextarea)}
                    placeholder="Описание ссылки"
                    // defaultValue={el.name}
                    onChange={(e) => handleName(e.target.value, el.id)}
                  />
                </Item>
                <Item
                  name={`video_link_${el.id}`}
                  rules={[{ 
                    required: true, 
                    message: 'Вставьте ссылку',
                    type: 'url',
                    validator: (_, value) => 
                      /^(ftp|http|https):\/\/[^ "]+$/.test(value) 
                        ? Promise.resolve() 
                        : Promise.reject(new Error('Некорректная ссылка'))
                  }]}
                  style={{ flex: 1 }}
                  className={s.item}
                >
                  <Input
                    className={s.antd}
                    placeholder="Вставьте ссылку на файл"
                    defaultValue={el.link_to_video}
                    onChange={(e) => handleUrl(e.target.value, el.id)}
                  />
                </Item>
                  <button onClick={()=> removeVideo(el.id) } className={classNames(s.icon, s.removeIcon)}><PlusIcon /></button>
                </div>
              )
            })
          }
          
          <button onClick={add} className={classNames(s.icon)} type="button" ><PlusIcon /></button>
        </div>
      </div>

      {loading ? <Preloader /> : <Button newDesign newDesignYellow htmlType="submit">Сохранить</Button>}
    </Form>
  </>
}


export default AddVideoLesson
