import s from './index.module.css';
import { PlusIcon } from '@/utils/icons';
import Add from '../Add';
import classNames from 'classnames';

const audio = ({add, task, chengeTasks}) => {
    if(task.state=='view' || task.state=='penetrate'){
       return (
            <div className={classNames(s.filesWrapper, s.filesWrapperView)}>
                {
                    task.audios.map((audio)=>{
                        if(!audio.file && !audio.filePath) return <></>;
                        return (
                            <audio key={audio.id} className={s.audio} controls>
                                <source src={audio?.filePath ? process.env.REACT_APP_PATH_FILE + audio.filePath : URL.createObjectURL(audio.file)} type="audio/wav" />
                                Ваш браузер не поддерживает teg аудио 
                            </audio>
                        )
                    })
                }
            </div>
       )
    }
    return (
        <div className={s.filesWrapper}>
            <Add text='Аудио' add={add} type='audios' /> 
            {
                task.audios.map((audio) => <div key={audio.id} className={s.fileWrapper}> 
                    <label className={s.file}>
                        <input 
                            accept="audio/*"
                            style={{display:'none'}}
                            onChange={(e) => {
                                const file = e.target.files[0];
                                chengeTasks({taskId: task.id, task, field: 'audios', value: file, idInArr: audio.id});
                            }}
                            type = 'file' /> 
                        <span className={s.nameLabel}>{ audio?.file?.name ? audio?.file?.name : audio?.filePath ? audio?.filePath : 'Выберите аудио'}</span>
                    </label>
                    <div onClick={() => chengeTasks({taskId: task.id, task, field: 'audios', action: 'remove', idInArr: audio.id})} className={s.remove}><PlusIcon /></div>
                </div>
                )
            }
        </div>
    )
}

export default audio;