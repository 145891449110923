import Test from './Test'
import DetailedAnswer from './DetailedAnswer'
import Range from './Range'
import Filter from './Filter'

const Task  = ({task, tasks, chengeTasks,
                openModal, clickBottom, clickTop, 
                clickDelete, deletion, changeOrder, 
                clickEdit, deleteFromModifiedQuestions, 
                modifiedQuestions, showUnsavedItems, pages}) => {
    switch (task.type) {
         case 'test':
           return <Test { ...{ task, chengeTasks, clickEdit, deleteFromModifiedQuestions, pages} } />;
         case 'detailedAnswer':
           return <DetailedAnswer { ...{ task, chengeTasks, clickEdit, deleteFromModifiedQuestions, pages} } />;
         case 'range':
           return <Range { ...{ task, chengeTasks, clickEdit, deleteFromModifiedQuestions, pages} } />;
         case 'filter':
           return <Filter { ...{ task, tasks, chengeTasks, 
                                 openModal, clickBottom, clickTop, 
                                 clickDelete, deletion, changeOrder, 
                                 clickEdit, deleteFromModifiedQuestions,
                                 modifiedQuestions, showUnsavedItems, pages} } />;
          default:
            return <></>;
    }
}
export default Task;