import ShakaPlayer from 'shaka-player-react';
import 'shaka-player-react/dist/controls.css';

const VideoPlayer = ({ src }) => {
  return (
      <ShakaPlayer src={src} />
  );
};

export default VideoPlayer;
