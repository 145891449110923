import { usePdf } from "@mikecousins/react-pdf";
import classNames from "classnames";
import { useEffect, useMemo, useRef, useState } from "react";
import { GetIco } from "@/utils/icons";
import Button, { ButtonPrev } from "@UI/Button";
import Timer from "@UI/Timer";
import Task, { TaskBalls } from "../Task";
import s from "./Probes.module.css";
import openFull from "../../../images/free-icon-fullscreen-158458.png";
import closeFull from "../../../images/free-icon-exit-full-screen-2459633.png";

import imgStart from "../../../images/start.png";
import { useLocalStorage } from "@hooks";
import { InputFile } from "@UI/InputNew";
import { useInputNew } from "@UI/InputNew/useInputNew";
import { probeApi } from "@api";
import { jsonConvert } from "@/helpers/jsonConvert";
import { useHistory } from "react-router-dom";
import { formateTime } from "@/helpers/formateTime";
import { useLoad } from "@hooks/useLoad";
import Preloader from "../../UI/Preloader";
import { getInArr } from "@/helpers/getInArr";
import { useAddAlert } from "@hooks/useAddAlert";
import { useDispatch } from "react-redux";
import { actionGetOwnBacklog } from "@store/children/children.reducer";
import { LinkOutlined } from "@ant-design/icons";

export const Probes = (props) => {
  if (props.state.status > 4) return <ShowMode {...props} />;
  return <EditMode {...props} />;
};

const ShowMode = ({ state, file, ...props }) => {
  const [showComment, setShowComment] = useState(false);

  return (
    <>
      <div className={s.headerContainer}>
        <ButtonPrev className={s.buttonPrev} />
        <h2 className={s.time}>
          Затрачено времени: {formateTime(14400 - state.left_time)}
        </h2>
      </div>
      <MyPdfViewer file={process.env.REACT_APP_PATH_FILE + file} />

      {!showComment && (
        <div className={s.showBottom}>
          <button className="btn" onClick={() => setShowComment(!showComment)}>
            Перейти к результатам
          </button>
        </div>
      )}

      {showComment && <Comment state={state} />}
    </>
  );
};

const EditMode = ({ file, state, ...props }) => {
  const time = state.left_time
    ? state.left_time > 0
      ? state.left_time
      : 0
    : 14400;
  const [isStart, setStart] = useState(state.status === 1 ? "start" : "go");

  const hist = useHistory();

  const start = () => state.status === 0 && probeApi.start({ id: props.id });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (isStart === "start") start();
  }, [isStart]);

  const [saveLoading, setSaveLoading] = useState(false);
  const addAlert = useAddAlert();
  const dispatch = useDispatch();

  const save = (data) => {
    setSaveLoading(true);

    const formData = new FormData();
    formData.append("answers", jsonConvert(data.answers));
    data.probe_files.forEach((file) => formData.append("probe_files[]", file));
    if (data.probe_files?.length === 0)
      formData.append("probe_files[]", "null");

    probeApi.pass({ id: props.id, data: formData }).then((res) => {
      setSaveLoading(false);
      if (res.data.status === "success") {
        addAlert({ title: `Ваш пробник успешно отправлен` });
        localStorage.removeItem(`${state.work_id}-userProbeTasks`);
        dispatch(actionGetOwnBacklog());
        hist.goBack();
      } else
        addAlert({
          title: `УПС! Возникла ошибка...`,
          text: "Повторите попытку или обратитесь к педагогу",
        });
    });
  };

  const [timeOut, setTimeOut] = useState(false);

  return (
    <>
      <div className={s.headerContainer}>
        {/* <MyPdfViewer file={process.env.REACT_APP_PATH_FILE + file} /> */}
        <ButtonPrev className={s.buttonPrev} />
        <Timer
          {...{ setTimeOut, time }}
          setStatus={setStart}
          status={isStart}
          type="probe"
        />
      </div>

      {isStart === "start" ? (
        <Body {...{ file, state, save, saveLoading, timeOut }} />
      ) : (
        <BodyStart setStart={setStart} />
      )}
    </>
  );
};

const BodyStart = ({ setStart }) => {
  return (
    <div className={s.banner}>
      <div>
        <p>Удачи тебе!</p>
        <p>Всё получится!</p>
        <button className="btn btn--yellow" onClick={() => setStart("start")}>
          Начать решать пробник
        </button>
      </div>
      <div>
        <img src={imgStart} alt="" />
      </div>
    </div>
  );
};

const Body = ({ state, file, show = false, timeOut, ...props }) => {
  const sort = (a, b) => (a.sorting > b.sorting ? 1 : -1);

  // получаем задания
  const allTasks = state?.work?.material?.probeTasks;
  const tasks = allTasks
    ?.sort(sort)
    ?.filter((item) => !getInArr(item.task.type, ["probe-c", "file-answer"]));
  const cPath = allTasks
    ?.sort(sort)
    ?.filter((item) => getInArr(item.task.type, ["probe-c", "file-answer"]));

  // длина строки
  const STRING_LENGTH = 18;

  // создание массива строки
  const createValues = (initValue) => {
    const newArr = [];
    for (let i = 0; i < STRING_LENGTH; i++)
      initValue[i] !== undefined ? newArr.push(initValue[i]) : newArr.push("");
    return newArr;
  };

  // создание строки
  const createRow = (id, initValue) => ({
    id,
    values: createValues(initValue),
  });

  // создание всех строк
  const createTable = (data) => {
    const newArr = [];
    data?.map((item) => newArr.push(createRow(item?.task_id, "")));
    return newArr;
  };
  const [kim, setKim] = useLocalStorage(
    `${state.work_id}-userProbeTasks`,
    createTable(tasks)
  );

  const allCount = kim?.length;
  const leftCount = Math.round(allCount / 2);

  const leftKim = kim?.slice(0, leftCount);
  const rightKim = kim?.slice(leftCount);

  const handleChange = (id, idxCol, value) => {
    setKim((prev) =>
      prev.map((row) => {
        if (row.id === id) {
          const newValues = [...row.values];
          newValues[idxCol] = value;
          return { ...row, values: newValues };
        }
        return row;
      })
    );
  };

  const [files, setFiles] = useState([]);
  const [bindFiles] = useInputNew({
    name: "files",
    value: files,
    onChange: setFiles,
    placeholder: "Загрузить решение С-части",
    multiple: true,
  });

  const save = () => {
    const dataKim = kim.map(({ values, id }) => {
      let value = "";
      values.forEach((item) => {
        value += item;
      });
      return { task_id: id, answer: value };
    });

    const dataCPath = cPath.map(({ task_id }) => ({ task_id, answer: "" }));

    props.save({ answers: [...dataKim, ...dataCPath], probe_files: files });
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (timeOut) save();
  }, [timeOut]);

  return (
    <>
      <MyPdfViewer file={process.env.REACT_APP_PATH_FILE + file} />
      <h2 className={s.kimTaskHeader}>Заполните КИМ по первой части</h2>

      <div className={classNames(s.divTable, "customScroll")}>
        <ColKim {...{ path: leftKim, handleChange }} />
        <ColKim {...{ path: rightKim, handleChange, startIdx: leftCount }} />
      </div>
      <div className={s.textCenter}>
        <InputFile className={s.uploadFile} {...bindFiles} />
      </div>

      <div className={s.footerSend}>
        {props.saveLoading ? (
          <Preloader />
        ) : (
          <Button name={"Сдать работу"} action={save} />
        )}
      </div>
    </>
  );
};

const ColKim = ({ path, handleChange, startIdx = 0 }) => {
  return (
    <div>
      {path?.map((row, idxRow) => (
        <div
          className={classNames(s.row, { [s.rowFirst]: idxRow === 0 })}
          key={idxRow}
        >
          <span className={s.editingInput}>{idxRow + 1 + startIdx}</span>
          {row.values.map((col, idxCol) => (
            <Cell
              key={idxCol}
              value={col}
              onChange={(value) => handleChange(row.id, idxCol, value)}
            />
          ))}
          {process.env.REACT_APP_DEV_MODE === "1" && (
            <span className="error">{row.id}</span>
          )}
        </div>
      ))}
    </div>
  );
};

const Cell = (props) => {
  const value = props.value ?? "";

  const kd = (e) => {
    const kd = e.target;
    props.onChange(kd.value.toUpperCase());
    let nextKd = kd.nextElementSibling;
    if (kd.value) nextKd?.focus();
  };

  const kdBack = (e) => {
    const kd = e.target;
    if (e.target.value === "" && e.keyCode === 8) {
      props.onChange("");
      let prevKd = kd.previousElementSibling;
      prevKd?.focus();
      if (prevKd) prevKd.value = "";
    }
  };

  return (
    <input
      className={s.editingInput}
      onKeyDown={kdBack}
      onChange={kd}
      value={value}
      type={"text"}
      maxLength={1}
    />
  );
};

const Comment = (props) => {
  const work_id = props.state.work_id;

  const { state, loading } = useLoad({
    api: probeApi.get,
    params: {
      id: work_id,
      expand: ["work.material.probeTasks", "work.material.probeTasks.task"],
    },
    initState: {},
    key: "data",
  });

  // получаем задания
  const allTasks = state?.work?.material?.probeTasks?.map((i) => i.task);
  const tasks = allTasks?.filter((item) => !getInArr(item.type, ["probe-c"]));
  const cPath = allTasks?.filter((item) => getInArr(item.type, ["probe-c"]));

  const fileCPath = jsonConvert(state?.probe_files, "json");

  const initAnswer = () => {
    const arr = {};
    state?.work?.material?.probeTasks?.forEach(
      (task) => (arr[task.task_id] = { score: "" })
    );
    state?.results?.forEach(({ task_id, score }) => {
      arr[task_id] = { score: score ?? "" };
    });
    return arr;
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const answer = useMemo(() => initAnswer(), [state]);

  // получение максимума баллов за тестовую часть
  const checkMaxBallTestPath = () => {
    let maxBalls = 0;
    if (answer !== null)
      tasks?.forEach((item) => {
        const weight = jsonConvert(item.exam_weight)?.slice(-1);
        if (weight?.length) maxBalls += weight[0]?.score;
      });
    return maxBalls;
  };
  // получение баллов за тестовую часть
  const checkBallTestPath = () => {
    let sumBalls = 0;
    if (answer !== null)
      tasks?.forEach((item) => {
        const weight = answer[item.id]?.score;
        if (weight) sumBalls += weight;
      });
    return sumBalls;
  };

  // получение максимума баллов за С часть
  const checkMaxBallCPath = () => {
    let maxBalls = 0;
    cPath?.forEach((item) => {
      const weight = jsonConvert(item.exam_weight)?.slice(-1);
      if (weight?.length) maxBalls += weight[0]?.score;
    });
    return maxBalls;
  };

  // получение баллов за С часть
  const checkBallCPath = () => {
    let sumBalls = 0;
    if (answer !== null)
      cPath?.forEach((item) => {
        const weight = answer[item.id]?.score;
        if (weight) sumBalls += weight;
      });
    return sumBalls;
  };

  // получение процента решенных задач
  const checkCountSolved = () => {
    const totalCount = state?.work?.material?.probeTasks?.length;
    let solvedCount = 0;
    if (answer !== null)
      for (let key in answer) {
        if (answer[key]?.score > 0) solvedCount++;
      }
    return Math.round((solvedCount / totalCount) * 100);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const maxBallTestPath = useMemo(
    () => (tasks?.length ? checkMaxBallTestPath() : 0),
    [tasks]
  );
  const ballTestPath = checkBallTestPath();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const maxBallCPath = useMemo(
    () => (cPath?.length ? checkMaxBallCPath() : 0),
    [cPath]
  );
  const ballCPath = checkBallCPath();

  const countSolved =
    props.state.decided_right !== undefined
      ? props.state.decided_right
      : checkCountSolved();

  return (
    <>
      {loading ? (
        <Preloader fullScreen="center" />
      ) : state.id !== undefined ? (
        <div className={s.commentList}>
          <div className={s.balls}>
            <TaskBalls
              title="Тестовая часть"
              val={ballTestPath}
              maxVal={maxBallTestPath}
            />
          </div>
          {tasks?.map((el, idx) => (
            <Task
              key={el.id}
              {...{
                props: { ...el, category: "decided" },
                title: idx + 1,
                result: state.results?.find((i) => +i.task_id === el.id),
                status: state.status,
              }}
            />
          ))}

          <div className={s.balls}>
            <TaskBalls title="C часть" val={ballCPath} maxVal={maxBallCPath} />
          </div>

          {fileCPath?.length > 0 && (
            <div className={s.card}>
              <h2 className={s.cardTitle}>Прикреплённые файлы к С части</h2>

              <div className={s.cardList}>
                {fileCPath?.map((item, idx) => (
                  <a
                    key={idx}
                    href={process.env.REACT_APP_PATH_FILE + item}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button>Открыть файл</Button>
                  </a>
                ))}
              </div>
            </div>
          )}

          {cPath?.map((el, idx) => (
            <Task
              key={el.id}
              {...{
                props: { ...el, category: "decided" },
                title: idx + 1,
                result: state.results?.find((i) => +i.task_id === el.id),
                status: state.status,
              }}
            />
          ))}

          <div className={s.balls}>
            <TaskBalls
              title="Итого"
              val={ballTestPath + ballCPath}
              maxVal={countSolved}
              maxValTitle="Решено заданий"
              maxType="%"
            />
          </div>

          {props?.state?.comment && (
            <div className={s.card}>
              <h2 className={s.cardTitle}>Комментарий к пробнику</h2>

              <p>{props?.state?.comment}</p>
            </div>
          )}
        </div>
      ) : (
        <h2>Нет данных</h2>
      )}
    </>
  );
};

export const MyPdfViewer = ({ presentation = false, file }) => {
  const [page, setPage] = useState(1);
  const [fullScreen, setFullScreen] = useState(false);
  const canvasRef = useRef(null);
  let canvas = document.getElementById("canvas");
  const { pdfDocument } = usePdf({
    file: file,
    page,
    canvasRef,
    scale: 1.2,
  });
  let elem = document.getElementById("pdf");

  document.addEventListener("fullscreenchange", () => {
    if (document.fullscreenElement) {
    } else {
      setFullScreen(false);
    }
  });

  function openFullscreen() {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }

    setFullScreen(true);
  }
  if (fullScreen) {
    canvas.style.width = "100%";
    canvas.style.height = "90vh";
  }
  /* Close fullscreen */
  function closeFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document?.webkitExitFullscreen) {
      /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE11 */
      document.msExitFullscreen();
    }
    setFullScreen(false);
  }

  return (
    <div id="pdf">
      <div
        className={classNames(
          fullScreen ? s.imageContainerFullScreen : s.imageContainer,
          "customScroll"
        )}
      >
        <a href={file} target="_blank" rel="noreferrer">
          {!pdfDocument && <Preloader fullScreen="center" />}
          {pdfDocument && <canvas id="canvas" ref={canvasRef} />}
        </a>
      </div>
      {Boolean(pdfDocument && pdfDocument.numPages) && (
        <nav className={s.buttonPdf}>
          <button
            className="btn"
            disabled={page === 1}
            onClick={() => setPage(page - 1)}
          >
            <GetIco icon="angle_arrow_left" />
          </button>
          <span className={"booble"}>
            {page}/{pdfDocument.numPages}
          </span>
          <button
            className="btn"
            disabled={page === pdfDocument.numPages}
            onClick={() => setPage(page + 1)}
          >
            <GetIco icon="angle_arrow_right" />
          </button>
          {presentation && fullScreen && (
            <button className="btn" onClick={closeFullscreen}>
              <img className={s.icoFull} src={closeFull} />
            </button>
          )}
          {presentation && !fullScreen && (
            <button className="btn" onClick={openFullscreen}>
              <img className={s.icoFull} src={openFull} />{" "}
            </button>
          )}
        </nav>
      )}
    </div>
  );
};
export const MyPdfViewerNewDesign = ({ presentation = false, file }) => {
  const [page, setPage] = useState(1);
  const [fullScreen, setFullScreen] = useState(false);
  const canvasRef = useRef(null);
  let canvas = document.getElementById("canvas");
  const { pdfDocument } = usePdf({
    file: file,
    page,
    canvasRef,
    scale: 1.2,
  });
  let elem = document.getElementById("pdf");

  document.addEventListener("fullscreenchange", () => {
    if (document.fullscreenElement) {
    } else {
      setFullScreen(false);
    }
  });

  function openFullscreen() {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }

    setFullScreen(true);
  }
  if (fullScreen) {
    canvas.style.width = "100%";
    canvas.style.height = "90vh";
  }
  /* Close fullscreen */
  function closeFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document?.webkitExitFullscreen) {
      /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE11 */
      document.msExitFullscreen();
    }
    setFullScreen(false);
  }

  const handleClick = (e) => {
    const canvas = canvasRef.current;
    const canvasRect = canvas.getBoundingClientRect();
    const canvasWidth = canvasRect.width;
    const clickPosition = e.clientX - canvasRect.left;

    if (clickPosition > canvasWidth / 2) {
      if (page !== pdfDocument.numPages) {
        setPage(page + 1);
      }
    } else {
      if (page > 1) {
        setPage(page - 1);
      }
    }
  };

  return (
    <div id="pdf">
      <div
        className={classNames(
          fullScreen ? s.imageContainerFullScreen : s.imageContainer,
          "customScroll",
          s.imageContainerNewDesign
        )}
      >
        <a
          className={s.openLinkButton}
          href={file}
          target="_blank"
          rel="noreferrer"
        >
          <LinkOutlined />
        </a>
        <div onClick={handleClick} style={{ cursor: "pointer" }}>
          {!pdfDocument && (
            <span style={{ position: "absolute" }}>Loading...</span>
          )}
          <canvas id="canvas" ref={canvasRef} />
        </div>
      </div>
      {Boolean(pdfDocument && pdfDocument.numPages) && (
        <nav className={s.buttonPdf}>
          <button
            className="btn"
            disabled={page === 1}
            onClick={() => setPage(page - 1)}
          >
            <GetIco icon="angle_arrow_left" />
          </button>
          <span className={"booble"}>
            {page}/{pdfDocument.numPages}
          </span>
          <button
            className="btn"
            disabled={page === pdfDocument.numPages}
            onClick={() => setPage(page + 1)}
          >
            <GetIco icon="angle_arrow_right" />
          </button>
          {presentation && fullScreen && (
            <button className="btn" onClick={closeFullscreen}>
              <img className={s.icoFull} src={closeFull} />
            </button>
          )}
          {presentation && !fullScreen && (
            <button className="btn" onClick={openFullscreen}>
              <img className={s.icoFull} src={openFull} />{" "}
            </button>
          )}
        </nav>
      )}
    </div>
  );
};
