import s from './index.module.css';
import cn from 'classnames';

const InputWhite = ({
    //name,
    value,
    onChange,
    placeholder,
    className,
    type='text',
    min='0',
    max='1000',
}) => {
    const handleChange = (e) => {
        const newValue = e.target.value;

        if (type === 'number') {
            if (newValue.length===0) {
                onChange('');
            }
            else{
                const numValue = Number(newValue);
                if (!isNaN(numValue)) {
                    if (numValue >= min && numValue <= max) {
                        onChange(numValue);
                    }
                }
            }
        } 
        else {
            onChange(newValue); // Для других типов просто передаем строку
        }
    };

    return (<>
        <input
            className={cn(s.input, className)}
            value={value}
            placeholder={placeholder}
            onChange={handleChange}
            />
    </>);
}



const TextareaWhite = ({
    value,
    onChange,
    placeholder,
    className,
    disabled=false
}) => {
    return (<>
        <textarea
            disabled={disabled}
            className={cn(s.input, s.textarea, className)}
            value={value}
            placeholder={placeholder}
            onChange={(e) => {onChange(e.target.value)}}
        />
    </>);
}

export { TextareaWhite, InputWhite };