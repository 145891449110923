import store from '@store/redux.store';
import { settingApi } from "@/api";
import {SETTINGS_TYPES} from "@/constant";
import { actionSetItemSetting, actionSetStatus, changeSettings } from '@store/settings-reducer';

export const checkSettingsStore = () => {
    return new Promise((resolve, reject) => {
        if (store.getState().settings.status) {
            resolve();
        } else {
            fethSettingsStore()
                .then(() => resolve())
                .catch((error) => reject(error));
        }
    })
}

const otherSettingsKeys = [
    'titleLoginPage',
    'subTitleLoginPage',
    'imageLoginPage',
    'catalogLogo'
];

export const fethSettingsStore = () => {
    return new Promise((resolve, reject) => {
        store.dispatch(actionSetStatus(1));
        settingApi.getAll()
            .then(response => {
                const settings = response.data;
                if (!settings) {
                    store.dispatch(actionSetStatus(0));
                    reject(response);
                    return;
                }
                const items = settings.filter(item => item.type === SETTINGS_TYPES.GENERAL);
                const generalSettings = {};
                items.forEach(item => Object.assign(generalSettings, { [item.key]: item.value }));
                localStorage.setItem(SETTINGS_TYPES.GENERAL, JSON.stringify(generalSettings));
                store.dispatch(changeSettings(generalSettings));
                const itemsCustom = settings.filter(item => item.type === SETTINGS_TYPES.CUSTOM);
                const customSettings = {};
                itemsCustom.forEach(item => Object.assign(customSettings, { [item.key]: item.value }));
                store.dispatch(actionSetItemSetting('custom', customSettings));
                const authPageSettingsItems = settings.filter(item => otherSettingsKeys.includes(item.key));
                const authPageSettings = {};
                authPageSettingsItems.forEach(item => Object.assign(authPageSettings, { [item.key]: item.value }));
                store.dispatch(changeSettings(authPageSettings));
                const authImage = settings.find(item => item.key === 'authImage');
                if (authImage)
                    store.dispatch(actionSetItemSetting('imageLoginPage', process.env.REACT_APP_PATH_FILE + '/' + authImage.value));
                store.dispatch(actionSetStatus(2));
                resolve();
            })
            .catch((error) => {
                store.dispatch(actionSetStatus(0));
                reject(error);
            })
    });
}