import s from './index.module.css'
import style from '../../index.module.css'
import commonStyle from '../Common/common.module.css'
import { InputSelect } from '../../../../../UI/InputNew'
import { useInputNew } from '../../../../../UI/InputNew/useInputNew'


export const CorrectAnswer = ({ data, edit, setErrorsList, showErrors }) => {
  const arSelect = data?.answer_options?.map((_, i) => ({ value: i + 1, label: 'Вариант №' + (i + 1) }))

  const [bindSelect] = useInputNew({
    name: 'correct_answer',
    value: Number.isInteger(data?.correct_answer) ? [data?.correct_answer] : data?.correct_answer,
    onChange: e => edit(prev => ({ ...prev, 'correct_answer': e })),
    onError: setErrorsList,
    validate: showErrors,
    placeholder: 'Выберите правильный ответ',
    options: arSelect,
    errorOptions: 'Нет вариантов ответа',
    errorText: 'Не выбран правильный ответ',
    multiple: true,
    printValues: true
  })

  return <div className={s.wrapper}>
    <span className={style.heading}>Правильный ответ:</span>
    <InputSelect classNameInputBoxSelect={commonStyle.inputBoxSelect} className={style.inputNew} {...bindSelect} />
  </div>
}
