import { CheckBoxLabel } from '@UI/CheckBox'

export const DisplayHintAfterAllAttempts = ({ data, edit }) => {
    return <CheckBoxLabel
        value={data.hint_after_all_attempts}
        onClick={() =>
            edit((prev) => ({
                ...prev,
                hint_after_all_attempts: !data.hint_after_all_attempts,
            }))
        }
        label='Отображать подсказку после использования всех попыток, а не после первой попытки'
    />
}
