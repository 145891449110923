import classNames from 'classnames';
import { GetIco } from '../../../utils/icons'
import s from './index.module.css'
import { useEffect, useState } from 'react'
const Search = ({ className = '', boundValue = null, setSearch = () => {}, placeholder = 'Поиск', ...props }) => {
 const [value, setValue] = useState(boundValue ?? '');

 useEffect(()=>{
  if(boundValue !== null){
    setValue(boundValue);
  }
 }, [boundValue])

  const handleSubmit = e => {
    e.preventDefault()
    setSearch(value ?? '')
  }

  const handleReset = () => {
    setSearch('');
    setValue('');
  }

  return (
    <form 
            onSubmit={handleSubmit} 
            className={classNames(s.search, className)} 
            {...props}>
            <input 
                onChange={(e)=> setValue(e.target.value)} 
                placeholder={placeholder} 
                disabled={props.disabled} 
                value={value}
            />
            <button type='submit'><GetIco icon='magnifier_new_design' /></button>
            {value && <button type='button' onClick={handleReset} className={s.close}><GetIco icon='cross' /></button>}
    </form>
  )
}

export default Search