import { reportAPI } from '../../api';

const SET_OWN_BACKLOG = 'impulse/children/SET_NOT_COMPLETED_HM';

const initialState = {
	ownBacklog: [],
};

const childrenReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_OWN_BACKLOG:
			return {
				...state,
				ownBacklog: action.state,
			};

		default:
			return state;
	}
};

export default childrenReducer;

// действия
const actionSetOwnBacklog = (state) => ({ type: SET_OWN_BACKLOG, state });
export const actionGetOwnBacklog = () => (dispatch) =>
	reportAPI
		.ownBacklog({
			params: {
				expand: 'direction,lesson.topic,order.direction,work.course.direction,theme.course.direction',
			},
		})
		.then((res) => dispatch(actionSetOwnBacklog(res.data)));

// селекторы
export const selectorOwnBacklog = (state) => state.childrenR.ownBacklog;
export const selectorOwnBacklogCount = (state) =>
	state.childrenR.ownBacklog.count;
