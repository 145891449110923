import { useState } from 'react'
import { recordingLessonAPI } from '../../../api'
import { useAddAlert } from '../../../hooks/useAddAlert'
import Button from '../../UI/Button'
import Preloader from '../../UI/Preloader'
import s from './Remove.module.css'


const RemoveVideoLesson = ({ closeModal, data }) => {
  console.log(data)
  const [loadingSave, setLoadingSave] = useState(false)

  const addAlert = useAddAlert()

  const save = () => {
    setLoadingSave(true)
    recordingLessonAPI.remove({ id: data.id }).then(res => {
      setLoadingSave(true)
      if (res.data.status === "success") {
        addAlert({ title: 'Запись успешно удалена!' })
        closeModal()
      }
      else addAlert({ title: 'Упс! Ошибка', text: 'Попробуйте ещё раз или обратитесь к администратору', type: 'error' })
    })
  }


  return <>
    <h2 className="modal__title">Удалить запись занятия</h2>
    <div className={s.buttons}>
      {loadingSave ? <Preloader /> : <Button newDesign newDesignYellow action={save}>Подтвердить</Button>}
      <Button newDesign action={closeModal}>Отмена</Button>
    </div>
  </>
}


export default RemoveVideoLesson;