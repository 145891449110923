import s from './index.module.css';
import tiptap from '../../../../../../common.module.css';
import { useAddAlert } from '@/hooks/useAddAlert';
import Audio from '../../../Common/Audio';
import Img from '../../../Common/Img';
import { TextareaWhite } from '@UI/New/InputWhite';
import JsonPars from "@UI/JsonPars/JsonPars";

const View = ({task, chengeTasks}) => {
    const addAlert = useAddAlert();
   
    return <div className={s.wrapper}>
        <div className={s.title}>Вопрос №{task.order}</div>
        <div style={{ whiteSpace: 'pre-line'}} className={tiptap.tiptapClassContentView}><JsonPars json={task.questionText} /></div>
        {
            (task.imgs.length !== 0 ) && <Img {...{ task }} />
        }
        {
            (task.audios.length !== 0 ) && <Audio {...{ task }} />
        }
        <TextareaWhite value={task.answer} disabled placeholder= {!!task.number ? 'Ответ числом' : 'Дайте развернутый ответ'} />
      
    </div>
}

export default View;