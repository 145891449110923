
import {SETTINGS_TYPES} from "@/constant";
const SET_IS_SHOW_GENDER_COLLEAGE = 'SET_IS_SHOW_GENDER_COLLEAGE';
const SET_ITEM_SETTINGS = 'SET_ITEM_SETTINGS';
const SET_STATUS_SETTINGS = 'SET_STATUS_SETTINGS';
const SET_GENERAL_ITEM_SETTINGS = 'SET_GENERAL_ITEM_SETTINGS';

let storeData = localStorage.getItem(SETTINGS_TYPES.GENERAL);

const defaultInitialState2 = {
    //Colleague
    isShowGenderColleague: true,
    isShowDateOfBirthColleague: true,
    isShowCityColleague: true,
    isShowRegistrationAddressColleague: true,
    isShowEducationColleague: true,
    isShowExperienceColleague: true,
    isShowLinkToVKSColleague: true,
    isShowLinkToSocialMediaNetColleague: true,
    isShowAboutMeColleague: true,
    isShowAgreementsColleague: true,
    isShowWorkSheduleColleague: true,
    //Child
    isShowPhoneChild: true,
    isShowEmailChild: true,
    isShowGenderChild: true,
    isShowDateOfBirthChild: true,
    isShowClassChild: true,
    isShowPromoChild: true,
    isShowLinkToSocialMediaNetChild: true,
    isShowCityChild: true,
    isShowIdChild: true,
    isShowPointerChild: true,
    isShowAboutMeChild: true,
    isShowAboutChild: true,
    isShowTimeZoneChild: true,
    isShowParentNameChild: true,
    isShowParentEmailChild: true,
    isShowParentPhoneChild: true,
    isShowClassesChild: true,
    isShowCoursesChild: true,
    isShowAgreementsChild: true,
    isShowCustomFieldsChild: true,
    customFieldsChild: [],
    status: 0, // 0 - empy state, 1 - loading state, 2 - state up to date

    isRenameCoursesToTrajectories: false,
    elJurSelectPeriodForDisplay: null,
    disablePopupStatusPMinus: '0',
    disablePopupStatusPPlus: '0',
    disablePopupStatusP: '0',
    disablePopupStatusN: '0',
    average100bArithmeticMean: '0',
    mainPageAllCoursesInOneBlock: '0',
    mainPageHoursInSchedule: '0',
    mainPageHoursInScheduleBegin: '0',
    mainPageHoursInScheduleEnd: '0',
    elJourDisablingSkipCountColumn: '0',
    elJourDisablingHitCountColumn: '0',
    elJourDisablingAttendanceColumn: '0',

    custom: {}
};
const initialState1 = storeData ? {...defaultInitialState2, ...JSON.parse(storeData)} : defaultInitialState2;
const initialState3 = {
    titleLoginPage: 'Учитесь по-другому',
    subTitleLoginPage: 'Авторизуйтесь, чтобы начать заниматься',
    imageLoginPage: null,
    status: 0
}
export const initialState = {
    ...initialState1,
    ...initialState3
}

const settingsReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'CHANGE_SETTINGS':
			return {
                ...state,
                ...action.payload
            };
        case SET_ITEM_SETTINGS: {
            return {
                ...state,
                [action.key]: action.value
            };
        }
        case SET_GENERAL_ITEM_SETTINGS: {
            if (state[action.key] != action.value) {
                const store = localStorage.get(SETTINGS_TYPES.GENERAL);
                localStorage.setItem(SETTINGS_TYPES.GENERAL, JSON.stringify({
                    ...JSON.parse(store),
                    [action.key]: action.value
                }));
            }
            return {
                ...state,
                [action.key]: action.value
            };
        }
        case SET_STATUS_SETTINGS: {
            return {
                ...state,
                status: action.status
            };
        }
		default:
			return state;
	}
};

export default settingsReducer;

export const changeSettings = (data) => {
    return {
        type: 'CHANGE_SETTINGS', 
        payload: data
    }
};

export const actionSetStatus = (status) => ({
    type: SET_STATUS_SETTINGS,
    status,
});

export const actionSetItemSetting = (key, value) => ({
    type: SET_ITEM_SETTINGS,
    key,
    value
});