export const appendToFormData = (formData, data, parentKey = '') => {
    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            const value = data[key];
            const formKey = parentKey ? `${parentKey}[${key}]` : key;

            if (value instanceof File) {
                // Если это файл, добавляем его в FormData
                formData.append(formKey, value);
            } else if (Array.isArray(value)) {
                // Если это массив, обрабатываем каждый элемент
                value.forEach((item, index) => {
                    appendToFormData(formData, item, `${formKey}[${index}]`);
                });
            } else if (typeof value === 'object' && value !== null) {
                // Если это объект, рекурсивно добавляем его свойства
                appendToFormData(formData, value, formKey);
            } else {
                // В противном случае добавляем значение как текст
                formData.append(formKey, value);
            }
        }
    }
}