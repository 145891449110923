import { InfoIcon } from "@/utils/icons";
import { useState, useEffect, useRef } from "react";
import s from './index.module.css';
import classNames from "classnames";

const Tooltip = ({text, className, width, style, down=false, centerMobile=false}) => {
    const ref=useRef(null);
    const [height, setHeight] = useState();
    useEffect(()=>{
        setHeight(ref.current.clientHeight)
    }, [text])
    return <div style={{...style}} className={`${s.tooltip} ${className}`}>
        <InfoIcon />
        {!down && <div style={{top:`-${height + 10}px`, left:`-${width / 2 - 7}px`, width:`${width}px`}} ref={ref} className={classNames(s.tooltipText, {[s.centerMobile]: centerMobile})}>{text}</div>}
        {down && <div style={{left:`-${width / 2 - 7}px`, width:`${width}px`}} ref={ref} className={`${s.tooltipText} ${s.tooltipTextDown}`}>{text}</div>}

    </div>;
}
export default Tooltip;