import Modal, { ModalContainer } from '../common/Modal'
import {IcoRemove} from "@pages/Admin/constsIcon";
import { useSelector } from 'react-redux'; 
import AddVideoLesson from './AddVideoLesson';
import RemoveVideoLesson from './RemoveVideoLesson';
import { closeAllModal } from '@store/modalsSlise';
import { useDispatch } from 'react-redux';
import s from './index.module.css'

const Modals = () => {
    const modals = useSelector((state) => state.modals);

    const dispatch = useDispatch();

    const handleCloseAllModal = () => {
        dispatch(closeAllModal());
    };

    console.log(modals)

    return (
        <Modal className={s.modal} active={!!modals.types.length} setActive={() => {handleCloseAllModal()}}>
            <ModalContainer closeModal={handleCloseAllModal}>
                {
                    modals.types.includes('addVideoLesson') && <AddVideoLesson closeModal={handleCloseAllModal} data={modals.data['addVideoLesson']} />
                }
                {
                    modals.types.includes('removeVideoLesson') && <RemoveVideoLesson closeModal={handleCloseAllModal} data={modals.data['removeVideoLesson']} />
                }
            </ModalContainer>
        </Modal>
    )
}

export default Modals;