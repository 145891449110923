import { BrowserRouter, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { routes } from './list'
import NoAccess from '@pages/NoAccess'
import NoMobile from '@pages/NoMobile'
import Auth from '@pages/Auth'
import SignUpVk from '@pages/SignUpVk'
import RestorePassword from '@pages/RestorePassword'
import { AuthApp } from '@pages/Auth/app'
import { selectProfile, selectIsAuth } from '@store/profile.reducer'
import { useScreenWidth } from '@hooks/useScreenWidth'
import Layout from '@common/Layout'
import Register from '@pages/Register'
import Technical from '@common/Technical'
import { SettingsService } from '@/services/SettingsService'
import { SETTINGS_TYPES } from '@/constant'
import LayoutNewDesign from '@common/Layout/LayoutNewDesign'
import { useFcmMessaging } from '@/hooks/useFcmMessaging'
import WrapperNewsViewing from '@pages/NewsViewing'
import Survey from '@pages/MySurveys/Components/FollowingLink'
import { lazy, Suspense } from 'react'
import Preloader from '@UI/Preloader'
import RequiredSurveyModal from '@/components/modals/RequiredSurvey'
const Routing = () => {
  useFcmMessaging()

  const profile = useSelector(selectProfile)
  const isAuth = useSelector(selectIsAuth)
  const isMobile = useScreenWidth(992)
  const settings = SettingsService.get(SETTINGS_TYPES.GENERAL)

  const LayoutComponent =
    settings?.isShowNewDesign === '1' ? LayoutNewDesign : Layout

  return (
    <BrowserRouter>
      <Technical />
      {isAuth && (
        <LayoutComponent>
          <Suspense
            fallback={
              <main className="page-content">
                <Preloader fullScreen="center" />
              </main>
            }
          >
            {routes.map((route, i) => {
              let access =
                !(route.forRole && !route.forRole.includes(profile?.role)) ||
                profile?.role === 'admin' ||
                profile?.role === 'junior-admin'
              const Component = access
                ? route.noMobile && isMobile
                  ? NoMobile
                  : route.components
                : NoAccess

              return (
                <Route
                  exact={route.exact}
                  path={route.path}
                  key={i}
                  render={() => <Component {...route.props} />}
                />
              )
            })}
          </Suspense>
          <RequiredSurveyModal />
        </LayoutComponent>
      )}
      <Route exact={true} path="/auth" render={() => <Auth />} />
      <Route exact={true} path="/register" render={() => <Register />} />
      <Route exact={true} path="/signup-vk" render={() => <SignUpVk />} />
      <Route
        exact={true}
        path="/restore-password"
        render={() => <RestorePassword />}
      />
      <Route exact={true} path="/auth/app" render={() => <AuthApp />} />
      {!isAuth && (
        <>
          <Route
            exact={true}
            path="/news/:id"
            label="Новость"
            render={() => <WrapperNewsViewing />}
          />
          <Route
            exact={true}
            path="/my-survey-link/:id"
            render={() => <Survey />}
          />
        </>
      )}
    </BrowserRouter>
  )
}

export default Routing
