import classNames from 'classnames'
import classes from './index.module.css'
import { Link } from 'react-router-dom'
import { Tooltip } from 'antd'
import { ExclamationOutlined } from '@ant-design/icons'
import { CSSProperties } from 'react'

interface ISurvey {
  id: number
  title: string
  description: string
  regularity: string
  type: string
  order: number
  uuid: string
  report_id: number
}

const SurveyCard = ({
  isRequired,
  isPassed,
  isManual = false,
  survey,
  className,
  style,
  fromLesson,
  openLesson,
  forLesson,
}: {
  isRequired: boolean
  isPassed?: boolean
  isManual?: boolean
  survey: ISurvey
  className?: string
  style?: CSSProperties
  fromLesson?: boolean,
  openLesson?: number | null,
  forLesson?: number
}) => {
  const isActive = !isPassed && !!survey?.id

  const baseUrl = `/my-survey-link/${survey?.id}`;

  const queryParams = [];
  if (openLesson && forLesson) {
    queryParams.push(`openLesson=${openLesson}`);
  }

  if (forLesson) {
    queryParams.push(`forLesson=${forLesson}`);
  }

  const url = queryParams.length > 0 ? `${baseUrl}?${queryParams.join('&')}` : baseUrl;

  return (
    <div
      className={classNames(classes.surveyContainer, className)}
      style={style}
    >
      <div className={classes.surveyHeader}>
        <div className={classes.surveyHeaderTitle}>Опрос</div>
        {isRequired && (
          <Tooltip title="Обязательный опрос">
            <div className={classes.requiredMark}>
              <ExclamationOutlined
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              />
            </div>
          </Tooltip>
        )}
      </div>
      <div className={classes.surveyContent}>
        <div className={classes.surveyInfo}>
          <div className={classes.surveyTitle}>
            {survey?.title ?? 'опрос не найден'}
          </div>
          {survey?.description && (
            <div className={classes.surveyDescription}>
              {survey?.description}
            </div>
          )}
        </div>
        <div className={classes.actionRow}>
          <Link
            to={
              isManual
                ? `/my-survey/${survey?.report_id}/`
                : fromLesson
                ? isActive && url
                : isActive && `my-survey-link/${survey?.id}/`
            }
          >
            <button className={classes.primaryButton} disabled={!isActive}>
              {isPassed ? 'Уже пройден' : 'Пройти опрос'}
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default SurveyCard
