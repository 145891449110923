import Modal from '../../../common/Modal'
import s from './VideoAnswer.module.css'
import { useState } from 'react'
import { getIdUrlYoutube } from '../../../../helpers/getIdUrlYoutube'
import VideoPlayer from "@UI/VideoPlayer";
import { getCorrectFilePath } from '@/helpers/new/getCorrectFilePath';

export const VideoAnswer = ({ video, videoType }) => {
  console.log(video)
  console.log(videoType)
  const [active, setActive] = useState(false)

  return <>
    <button type='button' className='btn btn--yellow' onClick={() => setActive(true)}>Посмотреть видеорешение</button>
    <Modal active={active} setActive={setActive}>
      <div className={s.modal}>
        {
          (videoType == 'youtube' || !videoType) && <iframe className={s.iframe} src={`https://www.youtube.com/embed/${getIdUrlYoutube(video)}`}
            title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen></iframe>
        }
        {
          (videoType == 'platform') && <VideoPlayer src={getCorrectFilePath(video.path)} />
        }
        {
          (videoType == 'iframe') && <div dangerouslySetInnerHTML={{ __html: video }} />
        }
      </div>
    </Modal>
  </>
}
