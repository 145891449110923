import s from './index.module.css';
import { PlusIcon } from '@/utils/icons';
import Add from '../Add';
import classNames from 'classnames';

const Img = ({add, task, chengeTasks}) => {
    if(task.state=='view' || task.state=='penetrate'){
        return (
             <div className={classNames(s.filesWrapper, s.filesWrapperView)}>
                 {
                     task.imgs.map((img)=>{
                        if(!img.file && !img.filePath) return <></>;
                         const urlFile = img?.filePath ? process.env.REACT_APP_PATH_FILE + img?.filePath : URL.createObjectURL(img.file);
                         return (
                            <div key={img.id} style={{maxWidth: img.width ? `${img.width}%` : undefined}} className={s.img}>
                                <a target='_blank' href={urlFile}>
                                    <img src={urlFile} />
                                </a>
                            </div>
                         )
                     })
                 }
             </div>
        )
    }
    return (
        <div className={s.filesWrapper}>
            <Add text='Изображение' add={add} type='imgs' />
            {
                task.imgs.map((img) => <div key={img.id} className={s.fileWrapper}> 
                    <label className={s.file}>
                        <input 
                            accept="image/*"
                            style={{display:'none'}}
                            onChange={(e) => {
                                const file = e.target.files[0];
                                chengeTasks({taskId: task.id, task, field: 'imgs', value: file, idInArr: img.id});
                            }}
                            type = 'file' /> 
                        <span className={s.nameLabel}>{ img?.file?.name ? img?.file?.name : img?.filePath ? img?.filePath : 'Выберите изображение'}</span>
                    </label>
                    <div onClick={()=> chengeTasks({taskId: task.id, task, field: 'imgs', action: 'remove', idInArr: img.id })} className={s.remove}><PlusIcon /></div>
                    <div className={s.width}>
                        <span>Ширина(%)</span>
                        <input 
                            value={img.width}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (!isNaN(value) && value >= 0 && value <= 100) {
                                    chengeTasks({taskId: task.id, task, field: 'imgs', fieldInArr: 'width', value, idInArr: img.id});
                                }
                            }}
                        type="number"
                        />
                    </div>
                </div>
                )
            }
        </div>
    )
}

export default Img;