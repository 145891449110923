// export const tiptapValueEmpty = (value) => {
//     try {
//         return !value?.content || // Проверяем, существует ли content
//             value.content.length === 0 || // Если массив пустой
//             value.content.every(item => !item?.content || !item.content[0]?.text?.trim()) // Проверяем, что все элементы не заполнены
//     } catch (error) {
//         return false
//     }
// }

export const tiptapValueEmpty = (value) => {
    try {
        if (!value?.content || value.content.length === 0) {
            return true;
        }

        const hasTextContent = (node) => {
            if (node.text && node.text.trim()) {
                return true;
            }
            
            if (node.content && Array.isArray(node.content)) {
                return node.content.some(hasTextContent);
            }
            
            return false;
        };

        return !value.content.some(hasTextContent);
    } catch (error) {
        return false;
    }
};