import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    types: [],
    data: {}
};

const modalsSlice = createSlice({
    name: 'modals',
    initialState,
    reducers: {
        openModal: (state, action) => { 
            console.log(action)
            state.types = [...state.types, action.payload.type]; 
            state.data = {
                ...state.data,
                [action.payload.type]: action.payload.data
            }; 
        },
        closeModal: (state, action) => { 
            state.types = state.types.filter((type) => action.payload.type !== type); 
            state.data = () => {
                const newData = {...state.data};

                for (const key in newData) {
                    if (key === action.payload.type) {
                      delete newData[key];
                    }
                }

                return newData;
            }
           
        },
        closeAllModal: (state) => { 
            state.types = initialState.types;
            state.data = initialState.data;
        },
    }
});

const { actions, reducer } = modalsSlice;

export default reducer;

export const {
    openModal,
    closeModal,
    closeAllModal
} = actions;
