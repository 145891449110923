const websocketUrl = process.env.REACT_APP_WEBSOCKET_URL || 'ws://localhost:9090';

class WebSocketService {
    constructor() {
        this.socket = null;
        this.callbacks = {
            notification: [],
            chat: [],
        };
    }

    connect() {
        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
            console.warn('WebSocket is already connected');
            return;
        }

        try {
            this.socket = new WebSocket(websocketUrl);

            this.socket.onopen = () => {
                console.log('WebSocket connection established');
                const profile = localStorage.getItem('profile');
                if (profile) {
                    const userId = JSON.parse(profile).id;
                    this.send('register', { userId });
                }
            };

            this.socket.onmessage = (event) => {
                const message = JSON.parse(event.data);
                if (this.callbacks[message.type]) {
                    this.callbacks[message.type].forEach((callback) => callback(message));
                }
            };

            this.socket.onclose = () => {
                console.log('WebSocket connection closed');
                this.clearCallbacks();
            };

            this.socket.onerror = (error) => {
                console.error('WebSocket error:', error);
            };
        } catch (error) {
            console.error('Failed to connect to WebSocket:', error);
        }
    }

    subscribe(type, callback) {
        try {
            if (this.callbacks[type]) {
                this.callbacks[type].push(callback);
            } else {
                console.warn(`Unknown callback type: ${type}`);
            }
        } catch (error) {
            console.error('Error subscribing to WebSocket event:', error);
        }
    }

    send(type, data) {
        try {
            if (this.socket && this.socket.readyState === WebSocket.OPEN) {
                this.socket.send(JSON.stringify({ type, ...data }));
            } else {
                console.error('WebSocket is not open');
            }
        } catch (error) {
            console.error('Error sending WebSocket message:', error);
        }
    }

    disconnect() {
        try {
            if (this.socket) {
                this.socket.onclose = null;
                this.socket.close();
                this.socket = null;
                console.log('WebSocket disconnected');
            }
            this.clearCallbacks();
        } catch (error) {
            console.error('Error disconnecting WebSocket:', error);
        }
    }

    clearCallbacks() {
        Object.keys(this.callbacks).forEach(key => {
            this.callbacks[key] = [];
        });
    }
}

export default new WebSocketService();
