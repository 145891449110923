import s from './index.module.css';
import tiptap from '../../../../../../common.module.css';
import { useAddAlert } from '@/hooks/useAddAlert';
import Audio from '../../../Common/Audio';
import Img from '../../../Common/Img';
import classNames from 'classnames';
import JsonPars from "@UI/JsonPars/JsonPars";

const Penetrate = ({task, chengeTasks}) => {
    const addAlert = useAddAlert();
   
    return <div className={s.wrapper}>
        <div className={s.title}>Вопрос №{task.order}</div>
        <div style={{ whiteSpace: 'pre-line'}} className={tiptap.tiptapClassContentView}><JsonPars json={task.questionText} /></div>
        { 
            (task.imgs.length !== 0 ) && <Img {...{ task }} />
        }
        {
            (task.audios.length !== 0 ) && <Audio {...{ task }} />
        }

        <div className={s.answerOptionsWrapper}>
            {
                task.answerOptions.map((option, index) => <div onClick={()=> chengeTasks({task: task, optionId: option.id})} className={s.option} key={option.id}>
                        <div className={classNames(s.optionIcon, {[s.optionIconActive]: task?.answers?.includes(option.id)}, {[s.multiple]: task?.multiple})}></div>
                        <div style={{ whiteSpace: 'pre-line'}} className={tiptap.tiptapClassContentView}><JsonPars json={option.option} /></div>
                    </div>
                )
            }
        </div>
    </div>
}

export default Penetrate;