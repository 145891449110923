export const getParams = (data, last = true) => {
	if (data) {
		const filter = data?.filter ?? [];
		const expand = data?.expand ?? [];
		const pageSize = data?.pageSize ?? 'no';
		const paramsPagination = data?.paramsPagination;
		let res = '';
		if (typeof filter === 'object')
			filter?.forEach((item, i) => {
				if (i === 0 && last) res = '?filter' + item;
				else res += '&filter' + item;
			});
		if (typeof expand === 'object')
			expand?.forEach((item, i) => {
				if (i === 0 && res === '' && last) res = '?expand=' + item;
				else if (i === 0) res += '&expand=' + item;
				else res += ',' + item;
			});
		if (typeof pageSize === 'number' || typeof pageSize === 'boolean') {
			if (res === '' && last) res = '?pageSize=' + pageSize;
			else res += '&pageSize=' + pageSize;
		}
		if (typeof paramsPagination === 'object') {
			if (res === '') res = '?per-page=' + paramsPagination?.perPage;
			else res += '&per-page=' + paramsPagination?.perPage;

			if (res === '') res = '?page=' + paramsPagination?.page;
			else res += '&page=' + paramsPagination?.page;
		}

		Object.keys(data).forEach((key) => {
			if (!['filter', 'expand', 'pageSize', 'paramsPagination', 'data'].includes(key)) {
				if (data[key]) {
					res += (res === '' ? '?' : '&') + `${key}=${encodeURIComponent(data[key])}`;
				}
			}
		});

		return res;
	}
	return '';
};
