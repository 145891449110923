import { CheckBoxLabel } from '@UI/CheckBox'
import { Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons';
import s from './index.module.css'

export const IsTimedTask = ({ data, edit }) => {
    return <div className={s.timedTaskCheckboxContainer}>
        <CheckBoxLabel
            value={data.is_timed_task}
            onClick={() =>
                edit((prev) => ({
                    ...prev,
                    is_timed_task: !data.is_timed_task,
                }))
            }
            label='Это задание на время'
        />
        <Tooltip title="Если вы включите данный чекбокс, то учащийся перед выполнением этого задания увидит надпись: “Данное задание выполняется на время, когда будете готовы – нажмите на кнопку «Начать» и задание появится. После ответа – исчезнет”">
            <InfoCircleOutlined className={s.infoTooltip} />
        </Tooltip>
    </div>
}
