import s from './CheckboxViolet.module.css';
import check from '@/images/svg/check.svg';
import classNames from 'classnames';

const CheckboxViolet = ({ 
    text,
    value,
    setValue = () => {},
    takePassedValue = false,
    className,
    textClassName,
    blockClassName,
    onClick,
    onClickCheckbox = () => {},
    onClickText = () => {}
}) => {
    return (<div
        className={classNames(className, s.checkboxViolet, {[s.active]: value})}
        onClick={ onClick ? onClick : takePassedValue ? () => setValue(!value) : () => setValue(value => !value)}
    >
        <div className={classNames(s.block, {[blockClassName]: blockClassName})}
            onClick={() => onClickCheckbox(!value)}
        >
            <img src={check} alt="check" />
        </div>
        <div className={classNames(s.text, {[textClassName]: textClassName})}
            onClick={() => onClickText(!value)}
        >
            { text }
        </div>
    </div>)
}

export default CheckboxViolet;