import classNames from "classnames"
import { ButtonPrev } from "@UI/Button";
import PageTitle from "@UI/New/PageTitle";

const HeaderWithBackButton = ({ children, className }) => (
    <div className={classNames('newDesignHeader', className)}>
        {children}
    </div>
);

export default HeaderWithBackButton;


const HeaderWithBackButtonDefault = ({ className, title='', linkForsed = null }) => (
    <div className={classNames('newDesignHeader', className)}>
        <ButtonPrev linkForsed={linkForsed} newDesign={true} />
        <PageTitle title={title} />
    </div>
);

export { HeaderWithBackButtonDefault };