import classNames from 'classnames';
import s from './Match.module.css'
import { useEffect, useState } from 'react';
import { AnswerItem } from './AnswerItem';
import { AnswerButton } from '../AnswerButton/AnswerButton';
import { DragList } from './DragList';
import { jsonConvert } from '../../../../helpers/jsonConvert';
import { useTaskHomeWork } from '../hooks/useTaskHomeWork';
import JsonPars from '../../../UI/JsonPars/JsonPars';
import { formateTime } from '@/helpers/formateTime';


export const Match = ({ type,   ...props }) => {
  switch (type) {
    case 'decided': return <Decided {...props} />
    case 'correction': return <Correction {...props} />
    default: return <Task  {...props} />
  }
}
const Correction = ({ variantsL, variantsR }) => {
  const isImg = (variantsL?.find(el => el?.img?.length) || variantsR.find(el => el?.img?.length)) ? true : false
  return <div className={s.wrapper}>
    <div className={classNames(s.list, s.listCorrection)}>
      <div className={s.listItem}>
        {variantsL?.map((val, index) => <AnswerItem key={val.id} index={index} id={val.id} text={val.text} img={val.img} isImg={isImg} status={'no'} />)}
      </div>
      <div className={s.listItem}>
        {variantsR?.map((val, index) => <AnswerItem key={val.id} index={index} id={val.id} text={val.text} img={val.img} isImg={isImg} status={'no'} />)}
      </div>
    </div>
  </div>
}

const Decided = ({ userRole, ...props }) => {
  const answerOptions = props?.answer_options
  const answer = jsonConvert(props?.params?.result?.answer, 'json')
  const correctAnswer = jsonConvert(props?.params?.result?.correct_answer ?? [], 'json')
  const decidedRight = props?.params?.result?.decided_right


  const variantsL = answer ? answer?.map(item => answerOptions?.left?.find(i => i.id === item.left)) : []
  const variantsR = answer ? answer?.map(item => answerOptions?.right?.find(i => i.id === item.right)) : []



  // let trueStatement = true
  // answer?.forEach((el, i) => {
  //   const answerList = el.split('-')
  //   const leftEl = variantsL.find(el => el.index === +answerList[0])
  //   const rightEl = variantsR.find(el => el.index === +answerList[1])
  //   if (leftEl.index !== rightEl.index) trueStatement = false
  // })


  return <div className={s.answerWrap}>
    {/* {answer.map(i => <div>{i.left}</div>)}
    <br />
    {answer.map(i => <div>{i.right}</div>)} */}



    <span className={classNames(s.title, { [s.red]: decidedRight !== 1 })}>{userRole === 'children' ? 'Ваш ответ' : 'Ответ ребёнка'}</span>
    {variantsL?.map((el, i) => {
      const rightAnswer = variantsR[i]

      const leftId = el.id
      const rightId = rightAnswer.id
      const validity = correctAnswer?.find(item => (item.left === leftId && item.right === rightId)) ? true : false



      return <div className={s.answerRow} key={i}>
        <span className={classNames(s.booble)}>
          {process.env.REACT_APP_DEV_MODE === '1' && <span className='error'>{leftId}</span>}
          <JsonPars json={el.text} />
        </span>
        {/* <span className={classNames(s.booble, { [s.boobleImg]: leftEl.img })}>{leftEl.img
          ? <a href={leftEl.img}><img src={leftEl.img} alt="" /></a>
          : leftEl.text}
        </span> */}
        <span className={classNames(s.booble, { [s.accent]: validity || decidedRight === 1 }, { [s.red]: !validity })}>
          {process.env.REACT_APP_DEV_MODE === '1' && <span className='error'>{rightId}</span>}

          <JsonPars json={rightAnswer.text} />
        </span>
        {/* <span className={classNames(s.booble, { [s.accent]: leftEl.index === rightEl.index }, { [s.red]: leftEl.index !== rightEl.index })}>
          {rightEl.img
            ? <a href={leftEl.img}><img src={rightEl.img} alt="" /></a>
            : rightEl.text}
        </span> */}
      </div>
    })}
    {props?.params?.result?.time_spent &&
      <div className={s.estimation}>
        <span className={s.boobleSpent}>Потрачено время</span>
        <span className={s.boobleSpent}> {formateTime(props?.params?.result?.time_spent)}</span>
      </div>
    }
  </div>
}

const Task = ({  params, ...props }) => {
  const { onAnswer, answer, status, hint, video, videoType } = useTaskHomeWork(params,  { ...props, type: 'arr' })

  const [rightAnswers, setRightAnswers] = useState(answer?.get?.length
    ? answer.get.map(i => props.answer_options.right.find(item => item.id === i.right))
    : props.answer_options.right
  )

  useEffect(() => {
    const newArr = []
    props.answer_options.left?.forEach((item, i) => {
      newArr.push({ left: item.id, right: rightAnswers[i].id })
    })
    answer.set(newArr)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rightAnswers])

  // // задаёт большой размер для карточек если есть изображения
  // const isImg = (data.left?.find(el => el.img?.length) || data.right?.find(el => el.img?.length)) ? true : false



  // // смена по клику
  const [selectVar, setSelectVar] = useState([])
  const onSelectVar = index => setSelectVar([...selectVar, index])
  if (selectVar.length === 2) {
    const newState = [...rightAnswers];
    [newState[selectVar[0]], newState[selectVar[1]]] = [newState[selectVar[1]], newState[selectVar[0]]]
    setRightAnswers(newState)
    setSelectVar([])
  }
  const select = { onSelect: onSelectVar, values: selectVar }


  return <>
    <div className={s.list}>
      <div className={s.listItem}>
        {props.answer_options.left?.map((val, index) => <AnswerItem key={index} {...val} isImg={false} />)}
      </div>
      <div className={s.listItem}>
        <DragList {...{ status, select, state: rightAnswers, setState: setRightAnswers }} />
      </div>
    </div>

    <AnswerButton hintForChild={params.hintForChild} hintAfterAllAttempts={props.hint_after_all_attempts} {...{ status, onAnswer, hint, video, videoType }} />
  </>

}
