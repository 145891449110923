import { useSelector } from 'react-redux';
import {
	selectorMyGroups,
	selectorMyGroupsSelect,
} from '@store/common/common.reducer';
import { useLoad } from './useLoad';
import { directionGroupApi } from '@api';
import { useEffect } from 'react';
import { selectProfile } from '@store/profile.reducer';
import { getInArr } from '@helpers/getInArr';

export const useGetGroup = (
	isMyBooble = false,
	params = {},
	filter = null,
	load = true
) => {
	const userRole = useSelector(selectProfile).role;
	const isMy = isMyBooble
		? getInArr(userRole, ['seminarian', 'children'])
		: false;
	const myGroups = useSelector(selectorMyGroups) ?? [];
	const myGroupsSelect = useSelector(selectorMyGroupsSelect) ?? [];

	const { state, getState, loading } = useLoad({
		api: directionGroupApi.get,
		params: params,
		last: false,
		initState: [],
	});

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		!isMy && load && getState();
	}, [params?.filter]);

	const result = {
		list: isMy ? (filter ? myGroups?.filter(filter) : myGroups) : state,
		select: isMy
			? filter
				? myGroupsSelect?.filter(filter)
				: myGroupsSelect
			: state?.map(({ id: value, name: label }) => ({ value, label })),
		loading,
	};
	return result;
};
