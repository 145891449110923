import { useSelector } from 'react-redux';
import {
	selectorMyDirections,
	selectorMyDirectionsSelect,
} from '@store/common/common.reducer';
import { useLoad } from './useLoad';
import { useEffect } from 'react';
import { directionAPI } from '@api';
import { selectProfile } from '@store/profile.reducer';
import { getInArr } from '@helpers/getInArr';

export const useGetDirection = (isMyBooble = false, params = {}) => {
	const userRole = useSelector(selectProfile).role;
	const isMy = isMyBooble
		? getInArr(userRole, ['seminarian', 'senior-seminarian', 'children'])
		: false;
	const myDirections = useSelector(selectorMyDirections) ?? [];
	const myDirectionsSelect = useSelector(selectorMyDirectionsSelect) ?? [];

	const { state, getState, loading } = useLoad({
		api: directionAPI.getAll,
		params: { expand: ['directionTariffs'] },
		last: false,
		key: 'data',
		initState: [],
	});

	const tar = [];
	let arr = state?.map((el) => {
		el.directionTariffs.forEach(({ id: value, name: label }) => {
			tar.push({ value, label });
		});
	});

	const arrOftarrif = Array.from(new Set(tar.map((item) => item.label)));

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		!isMy && getState();
	}, [params?.filter]);

	const extractTariffs = () => {
		const directions = isMy ? myDirections : state

		return directions?.map(direction =>
			direction.directionTariffs
				?.filter(tariff => params.anySeminarian ? tariff.any_seminarian : true)
				.map(tariff => ({
					value: tariff.id,
					label: tariff.name,
					directionId: direction.id,
					type: tariff.type,
				}))
		).flat()
	}

	const result = {
		list: isMy ? myDirections : arrOftarrif,
		select: isMy
			? myDirectionsSelect
			: state
				?.filter(direction => {
					if (params?.anySeminarian) {
						return direction.directionTariffs.find(tariff => tariff.any_seminarian === true)
					}

					return true
				})
				.map(({ id: value, name: label }) => ({ value, label })),
		tariffs: extractTariffs(),
		loading,
	};

	return result;
};
