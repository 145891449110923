import { surveyApi } from '@/api'
import classes from './index.module.css'
import Modal from '@common/Modal'
import SurveyCard from '@pages/Admin/Surveys/Сomponents/SurveyCard'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectProfile } from '@store/profile.reducer'
import { useLocation } from 'react-router-dom'

interface ISurvey {
  id: number
  title: string
  name: string // title duplicate
  description: string
  regularity: string
  type: string
  order: number
  uuid: string
  report_id: number
}

const RequiredSurveyModal = ({}: {}) => {
  const location = useLocation()
  const userid = useSelector(selectProfile)?.id
  const [isInitialized, setIsInitialized] = useState<boolean>(false)
  const [surveyList, setSurveyList] = useState<ISurvey[]>([])
  const [isSurveyPath, setIsSurveyPath] = useState<boolean>(false)
  const [isActive, setIsActive] = useState(false)

  console.log('RequiredSurveyModal', 'ping')

  const checkSurveyPath = (): boolean => {
    const hiddenPages = ['/my-survey-link/', '/my-survey/']
    return hiddenPages.some((path) => location.pathname.startsWith(path))
  }

  const fetchRequiredSurveys = () => {
    const dataParams = {
      filter: [`[completed]=${false}`, `[type]=${2}`],
      user_id: userid,

      paramsPagination: {
        perPage: 5,
        page: 0,
      },
    }

    surveyApi.getMysurvey({ params: {}, ...dataParams }).then((res) => {
      const data = res?.data?.data ?? []
      setSurveyList(data)
      setIsActive(data?.length !== 0)
    })
  }

  useEffect(() => {
    if (checkSurveyPath()) {
      setIsSurveyPath(true)
      setIsActive(false)
    } else {
      if (isSurveyPath || !isInitialized) {
        fetchRequiredSurveys()
        setIsSurveyPath(false)
        setIsInitialized(true)
      }
    }
  }, [location.pathname])

  return (
    <Modal
      active={isActive}
      setActive={() => {}}
      // @ts-ignore
      className={classes.modalWrapper}
    >
      <div className={classes.modalContainer}>
        <div className={classes.modalHeader}>
          <div className={classes.modalTitleContainer}>
            <span className={classes.modalHeaderTitle}>
              Обязательные опросы
            </span>
          </div>
        </div>
        <div className={classes.contentWrapper}>
          <div className={classes.description}>
            Внимание! Пройдите опросы, чтобы продолжить пользоваться платформой.
          </div>
          {surveyList?.map((survey) => {
            return (
              <SurveyCard
                survey={{ ...survey, title: survey?.name }}
                isManual
                fromLesson
                isRequired={false}
              />
            )
          })}
        </div>
      </div>
    </Modal>
  )
}

export default RequiredSurveyModal
