

import s from "./index.module.css";
import s2 from "../../index.module.css";
import { HeaderWithBackButtonDefault } from "@UI/New/HeaderWithBackButton";
import PageWrapper from "@UI/New/PageWrapper";
import Task from "@pages/Admin/Surveys/Create/Questions/Components/Question/"
import Block from '@pages/Admin/Surveys/Create/Block';
import { useCallback, useEffect, useMemo, useState } from "react";
import Button from "@UI/Button";
import classNames from 'classnames';
import { useAddAlert } from "@/hooks/useAddAlert";
import { surveyApi, lessonСompleted } from "@/api";
import Preloader from "@UI/Preloader";
import { useSelector } from 'react-redux'
import { selectIsAuth } from '@store/profile.reducer'
import {selectProfile} from "@store/profile.reducer";
import defoultImg from "@/template/images/atTable.png"
import JsonPars from "@UI/JsonPars/JsonPars";
import { useHistory, useParams, useLocation  } from "react-router-dom";
import PageTitle from "@UI/New/PageTitle";

const checkCondition = (task, condition) => {
    if (task.type === 'test') {
        return task.answers.includes(condition.value) && task.answers.length;
    }
    if (task.type === 'range') {
        if(!task.answer || !condition.value) return false;
        const taskAnswer = Number(task.answer);
        const conditionValue = Number(condition.value);

        return (((condition.direction === 'bottom') && (taskAnswer < conditionValue) && conditionValue) || 
            ((condition.direction === 'top') && (taskAnswer > conditionValue) && conditionValue));
    }
    return false;
};

const handleTaskUpdate = (taskIter, type, optionId, value) => {
    if (type === 'test') {
        const answers = taskIter.answers.includes(optionId)
            ? taskIter.answers.filter((el) => el !== optionId) 
            : taskIter.multiple 
                ? [...taskIter.answers, optionId]
                : [optionId];

        return { ...taskIter, answers }; 
    } else {
        return { ...taskIter, answer: value };
    }
};

const Survey = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const openLesson = searchParams.get('openLesson');
    const forLesson = searchParams.get('forLesson');

    const {id: userId} = useSelector(selectProfile);
    
    const isAuth = useSelector(selectIsAuth)

    const [completedSurvey, setCompletedSurvey] = useState(false);
    const [lastQuestionData, setLastQuestionData] = useState({
        title: 'Вы прошли опрос',
        subTitle: 'Благодарим вас за пройденный опрос',
        image: ''
    });

    const addAlert = useAddAlert();

    const { id: surveyId } = useParams();

    const [state, setState] = useState([]);
    const [settings, setSettings] = useState({});

    const [loading, setLoading] = useState(false);
    const [creating, setCreating] = useState(false);
    const [error, setError] = useState(false);
    const [reportId, setReportId] = useState(null);

    useEffect(() => {
        if(completedSurvey){
            const lastQestions = visibleArea[visibleArea.length - 1];
            if(lastQestions.finish_id) {
                surveyApi.getFinishWindow(lastQestions.finish_id)
                    .then(({data})=>{
                        if(data.status === 'success') {
                            setLastQuestionData(data.data);
                        }
                    })
            }
        }
    }, [completedSurvey])

    const createReport = () => {
        setCreating(true);
        surveyApi.createReport(
            window.location.search,
            {
                survey_id: surveyId,
                ...(userId ? { user_id: userId } : {}),
                ...(forLesson ? {for_lesson: forLesson} : {}),
            }
        )
        .then(({data})=>{
            if(data?.status=="success"){
                setReportId(data.data.id)
            }
            else{
                addAlert({title: 'Ошибка', text: 'При получении опроса произошла ошибка', type: 'error'})
                setError(true)
            }
        })
        .catch(()=>{
            addAlert({title: 'Ошибка', text: 'При получении опроса произошла ошибка', type: 'error'})
            setError(true)
        })
        .finally(()=>{
            setCreating(false);
        })
    }

    useEffect(()=>{
        createReport();
        setLoading(true);

        surveyApi.passFollowingLink(surveyId)
        .then(({data}) => {
            if(data?.status=="success"){
                setState(data.data.questions);
                setSettings(data.data.settings)
            }
            else{
                addAlert({title: 'Ошибка', text: 'При получшении данных произошла ошибка', type: 'error'})
            }
        })
        .catch(()=>{
            addAlert({title: 'Ошибка', text: 'При получшении данных произошла ошибка', type: 'error'})
        })
        .finally(() => {
            setLoading(false);
        });
        
    }, [])

    const chengeTasks = ({ task, optionId=null, value = '' }) => {
        setState((prevState) => {
            return prevState.map((taskIter) => {
                if (taskIter.id === task.id || taskIter.id === task.parentId) {
                    if (taskIter.id === task.parentId) {
                        return {
                            ...taskIter,
                            questions: taskIter.questions.map((question) => {
                                if (question.id === task.id) {
                                    return handleTaskUpdate(question, task.type, optionId, value);
                                }
                                return question;
                            }),
                        };
                    } 
                    return handleTaskUpdate(taskIter, task.type, optionId, value);
                }
                return taskIter;
            });
        });
    };

   

    const outputFilter = (taskFilter) => {//вернет true, если фильтр находится в зоне видимости
        if(taskFilter.conditions.length == 0) return true;

        let show = false;

        taskFilter.conditions.forEach((condition) => {//пербираем условия фильтра
            state.forEach((task) => {//перебираем все задачи
                    if(task.type=='filter') {//если у задачи тип фильтр 
                        task.questions.forEach((question)=>{//тогда мы перебираем все задачи что есть в этом фильтре
                            if(checkCondition(question, condition) && question.id == condition.questionId && outputFilter(task)){ // если есть задача внутри фильтра, ответ которой подходид под условие и фильтр нааходится в зоне видимости
                                show = true;
                            }
                        })
                    }
                    else{
                        if(checkCondition(task, condition) && task.id == condition.questionId){//если задача - не фильтр ответ которой подходид под условие
                            show = true;
                        }
                    }
            })

        })

        return show;
    }

    const outputChecking = (conditions) => { //получаем условия фильтра
        let show = false;

        for (const condition of conditions) {//перебираем все условия из текущего фильтра
            for (const currentTask of state) {//перебираем все задачи
                if (currentTask.type === 'filter') { //если задача - фильтр 
                   
                    if(outputFilter(currentTask)){ // и этот фильтр находится в зоне видимости
                        for (const question of currentTask.questions) { //тогда перебираем вопросы внутри его
                            if(question.id === condition.questionId){ // если условие вильтра зависит от этой задачи 
                                if (checkCondition(question, condition)) { //и если условие задачи совпадает с ответом на задачу
                                    show = true; // то мы отображаем все задачи из этого фильтра
                                    break;
                                }
                            }
                        }
                    }
                } 
                else if (currentTask.id === condition.questionId) { //если условие зависит от задачи и задача не фильтр
                    if (checkCondition(currentTask, condition)) { //и если условие совпадает с ответом на задачу
                        show = true; // то мы отображаем все задачи из этого фильтра
                        break;
                    }
                }
            }
            if (show) break;
        }
        
        return show;
    }

    const visibleArea = useMemo(() => {
        
        return state.flatMap((task) => {
            if (task.type === 'filter') {
            
                let show = outputChecking(task.conditions);

                if(show){
                    return task.questions;
                }
                return [];
                
            }
            return task;
        })
        
    }, [state]);

    const send = () => {
        
        const sendData = [];

        const processTask = (task) => {
            if (task.answer || task.answer == 0) {
                sendData.push({
                    id: task.id,
                    answer: task.answer,
                });
            }
            if (task?.answers?.length) {
                sendData.push({
                    id: task.id,
                    answers: task.answers,
                });
            }
        };
            
        visibleArea.forEach((taskIter) => {
            processTask(taskIter);
        });

        if(visibleArea.length !== sendData.length){
            addAlert({title: 'Ошибка', text: 'Вы ответили не на все вопросы', type: 'error'})
        }
        else{
            surveyApi.takeSurvey({
                surveyId,
                answers: sendData,
                user_id: userId,
                report_survey_id: reportId,
            })
            .then(({data})=>{
                if(data?.status=="success"){
                    addAlert({title: 'Успешно!', text: 'Опрос успешно пройден'})
                    setCompletedSurvey(true)
                    if(openLesson == '1'){
                        lessonСompleted.completed(forLesson, { userId })
                            .then(() => {
                                
                            })
                    }
                }
                else{
                    addAlert({title: 'Ошибка', text: 'Попробуйте ещё раз или обратитесь к администратору', type: 'error'})
                }
            })
            .catch(()=>{
                addAlert({title: 'Ошибка', text: 'Попробуйте ещё раз или обратитесь к администратору', type: 'error'})
            })
            
        }
    };

    if(completedSurvey){
       return (
        <PageWrapper className={classNames({[s.notAuth]: !isAuth})}>
            <CustomHeader title='Опрос пройден'/>
            <div className={s.wrapperCompletion}>
                {
                    lastQuestionData.title && <div className={s.completionTitle}><JsonPars json={lastQuestionData.title} /></div>
                }
                {
                    lastQuestionData.subTitle && <div className={s.completionSubTitle}><JsonPars json={lastQuestionData.subTitle} /></div>
                }
                
                <div className={s.completionImg}>
                    <img src={lastQuestionData.image ? `${process.env.REACT_APP_PATH_FILE}/${lastQuestionData?.image}` : defoultImg} alt="" />
                </div>
            </div>
        </PageWrapper>
       )
    }
    
    if(!isAuth && !settings.isAnonymousAllowed){
       return(
        <main className={`page-content ${s.page}`}>
            {
                (loading || creating) ? <Preloader fullScreen='center' /> : error ? <></> : <div className={s.authorized}>Этот опрос доступен только для авторизованных пользователей</div>
            }
            
        </main>
       )
    }
    
    return (
        <PageWrapper className={classNames({[s.notAuth]: !isAuth})}>
            <CustomHeader title='Пройти опрос' link={settings?.backLink ?? null}/>
            {
                (loading || creating) ? <Preloader fullScreen='center' /> : error ? <></> :
                <div className={s.titleAndSubTitle}>
                    {settings?.name && <div className="newDesignTitleText">Опрос: {settings?.name}</div>}
                    {settings?.theme && <div className={s.subTitle}>{settings?.theme}</div>}
                    <div className={s.blocks}>
                    
                    {
                        visibleArea.map((task, index)=> {
                            return (
                                <Block key={task.id}>
                                    <Task chengeTasks={chengeTasks} task={{...task, order: index + 1, state:'penetrate'}}/> 
                                </Block>
                            )
                        })
                    }

                    </div>
                    {
                        <Button
                            onClick={send}
                            className={classNames(s.button, s2.button)}
                        >Ответить</Button>
                    }
                </div>

            }
            
        </PageWrapper>
    )
}

const CustomHeader = ({ title = "", link = null }) => {
    const history = useHistory()
    const hasForLesson = history.location.search.includes("forLesson");

    const handleClick = () => {
        if(link){
            window.location.href = link;
        } else {
            if (history.length > 1 && history.action !== "POP") {
                history.goBack();
            } else {
                history.push("/");
            }
        }
    }

    return (
        <div className={'newDesignHeader'}>
            <button className={classNames(s.buttonNewDesign)} onClick={handleClick}>
                <svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M0.21967 6.53033C-0.0732233 6.23744 -0.0732233 5.76256 0.21967 5.46967L5.21967 0.46967C5.51256 0.176777 5.98744 0.176777 6.28033 0.46967C6.57322 0.762563 6.57322 1.23744 6.28033 1.53033L1.81066 6L6.28033 10.4697C6.57322 10.7626 6.57322 11.2374 6.28033 11.5303C5.98744 11.8232 5.51256 11.8232 5.21967 11.5303L0.21967 6.53033Z" fill="#2B2D3E"/>
                    </svg>
                <span>{hasForLesson ? 'Назад к курсу' : 'Назад'}</span>
            </button>
            <PageTitle title={title} />
        </div>
    )
};

export default Survey;