import Edit from './Edit';
import View from './View';
import Penetrate from './Penetrate';

const Test = ({task, chengeTasks, clickEdit, deleteFromModifiedQuestions, pages}) => {
    switch (task.state) {
        case 'edit':
            return <Edit {...{task, chengeTasks, clickEdit, deleteFromModifiedQuestions, pages}} />;
        case 'view':
            return <View {...{task, chengeTasks}} />;
        case 'penetrate':
            return <Penetrate {...{task, chengeTasks}} />;
        default:
            return <></>;
    }
} 

export default Test;