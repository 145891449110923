import { CheckBoxLabel } from '@UI/CheckBox'

export const IsNumberAnswer = ({ data, edit }) => {
    return <CheckBoxLabel
        value={data.is_number_answer}
        onClick={() =>
            edit((prev) => ({
                ...prev,
                is_number_answer: !data.is_number_answer,
            }))
        }
        label='Ответ числом'
    />
}
