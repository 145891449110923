import s from './index.module.css';
import { PlusIcon } from '@/utils/icons';
import classNames from 'classnames';
import { useState } from 'react';
import Tooltip from '@UI/Tooltip';

const Add = ({text, add, type, textTooltip, className, classNameIcon}) => {
    const [loading, setLoading] = useState(false);
    return (
        <div className={classNames(s.add, className, {[s.disable]: loading})}>
            <div className={s.wrapperNameAndTooltip}>
                <span className={s.nameLabel}>{text}</span>
                {
                    textTooltip && <Tooltip 
                    centerMobile
                    text={textTooltip} 
                    width={220} />
                }
            </div>
            <div onClick={() => !loading && add(type, setLoading)} className={classNames(s.addIcon, classNameIcon)}><PlusIcon /></div>
        </div>
    )
}

export default Add;