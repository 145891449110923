import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import "swiper/css";
import {
	controlApi,
	courseAPI,
	homeWorkApi,
	lessonAbstractAPI,
	probeApi,
} from '@api'; 
import { useLocalStorage } from '@hooks';
import { useLoad } from '@hooks/useLoad';
import { useLoadMore } from '@hooks/useLoadMore';
import { selectorMyDirectionsSelect } from '@store/common/common.reducer';
import { selectProfile } from '@store/profile.reducer';
import HomeWorkPage from './HomeWorkPage';

import setSettingsFromServer from "@/helpers/setSettingsFromServer"
import { rubberLayout } from '@/helpers/rubberLayout';
import AllHomeWorkPage from './AllHomeWorkPage'

const HomeWorkPageContainer = () => {
	useEffect(rubberLayout, []);
	
	const [settings, setSettings] = useState(null);
	useEffect(() => {
		setSettingsFromServer(setSettings)
	}, []);
	const newDesign = settings?.isShowNewDesign === '1';
	const isAllWorksOnePage = settings?.isAllWorksOnePage == 1;

	const type = useTypeHW();
	const status = useStatusHW(type);
	const filters = useFiltersHW(status.get);
	const tasks = useTasksHW(type.get, filters.get);

	if(isAllWorksOnePage){
		return <AllHomeWorkPage />;
	}
	return (
		<HomeWorkPage
			{...{
				type,
				status,
				filters: filters.items,
				reset: filters.reset,
				tasks,
				newDesign
			}}
		/>
	);
};

export default HomeWorkPageContainer;

// типы работы
const useTypeHW = () => {
	const typeItems = {
		homeWork: 'Домашняя работа',
		classWork: 'Классная работа',
		probe: 'Пробник',
		control: 'Контрольная',
		abstract: 'Конспекты',
	};
	const [typeState, setTypeState] = useLocalStorage(
		'homeWorkTabType',
		Object.keys(typeItems)[0]
	);
	return { get: typeState, set: setTypeState, items: typeItems };
};

// статус домашней работы
const useStatusHW = (type) => {
	const statusItems = {
		0: 'Не выполнено',
		5: 'Выполнено',
		10: 'Проверенно',
		15: 'Архив',
	};
	if (type.get === 'abstract') delete statusItems['0'];
	if (type.get === 'abstract') delete statusItems['15'];
	const [typeState, setTypeState] = useLocalStorage(
		'homeWorkTabStatus',
		Object.keys(statusItems)[0]
	);
	return { get: typeState, set: setTypeState, items: statusItems };
};

// фильтры (направление и курсы)
const useFiltersHW = (status) => {
	// const defaultFilters = { course: null };
	const defaultDirection = null;

	const [filterDirection, setFilterDirection] = useState(JSON.parse(localStorage.getItem('childrenFiltersDzDirection')) ?? defaultDirection);
	// const initFilters = JSON.parse(localStorage.getItem('childrenFiltersDz')) ?? defaultFilters
	// const [filtersState, setFilterState] = useState(initFilters);
	// console.log(filtersState)
	console.log(filterDirection)
	const direction = {
		value: filterDirection ? Number(filterDirection) : filterDirection,
		onChange: (e) => {
			localStorage.setItem('childrenFiltersDzDirection', JSON.stringify(e))
			setFilterDirection(e)
		},
		placeholder: 'Курс',
		options: useSelector(selectorMyDirectionsSelect)
			.filter((item, index, self) => index === self.findIndex(t => t.value === item.value)),
	};

	// const {
	// 	state: courses,
	// 	getState: getCourses,
	// 	reset: resetCourse,
	// } = useLoad({
	// 	api: courseAPI.getAll,
	// 	params: { filter: [`[direction_id]=${filterDirection}`] },
	// 	key: 'data',
	// 	initState: [],
	// 	last: false,
	// });
	
	// const [isFirstRender, setIsFirstRender] = useState(true);
	// useEffect(() => {
	// 	if (filterDirection) getCourses();
	// 	if (isFirstRender) {
    //         setIsFirstRender(false); 
    //         return; 
    //     }
	// 	if (filtersState.course) {
	// 		localStorage.setItem('childrenFiltersDz',  JSON.stringify(defaultFilters));
	// 		setFilterState(defaultFilters);
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [filterDirection]);

	// const course = {
	// 	value: filtersState.course,
	// 	onChange: (e) => setFilterState((prev) => {
	// 		localStorage.setItem('childrenFiltersDz',  JSON.stringify({ ...prev, course: e }));
	// 		return { ...prev, course: e };
	// 	}),
	// 	placeholder: filterDirection ? 'Курс' : 'Нет направления',
	// 	options: courses.map(({ name: label, id: value }) => ({
	// 		label,
	// 		value,
	// 	})),
	// };

	const list = [];
	if (status === '0')
		list.push(['[status][in][]=0'], ['[status][in][]=1'], ['[status][in][]=2']);
	else list.push(`[status][eq]=${status}`);
	if (filterDirection) list.push(`[direction_id][eq]=${filterDirection}`);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const filterList = React.useMemo(() => list, [status, filterDirection]);

	const reset = () => {
		// setFilterState(defaultFilters);
		// localStorage.setItem('childrenFiltersDz',  JSON.stringify(defaultFilters));
		
		setFilterDirection(defaultDirection);
		localStorage.setItem('childrenFiltersDzDirection', JSON.stringify(defaultDirection))

		// resetCourse();
	};

	return { get: filterList, reset, items: { direction } };
};

// получение самих работ
const useTasksHW = (type, filter) => {
	const { id } = useSelector(selectProfile);

	const apiParams = {
		homeWork: {
			api: homeWorkApi.get,
			params: {
				id,
				filter:[...filter, [`[is_classwork][eq]=0`]],
				expand: [
					'lesson.lessonTasks',
					'lesson.topic',
					'direction',
					'homeWorkResults',
					'lesson.lessonTasks.task',
					'homeWorkUniqueTasks.task',
				],
			},
		},
		classWork: {
			api: homeWorkApi.get,
			params: {
				id,
				filter:[...filter, [`[is_classwork][eq]=1`]],
				expand: [
					'lesson.lessonTasks',
					'lesson.topic',
					'direction',
					'homeWorkResults',
					'lesson.lessonTasks.task',
					'homeWorkUniqueTasks.task',
				],
			},
		},
		probe: {
			api: probeApi.getMy,
			params: {
				filter,
				expand: [
					'work',
					'work.material.course',
					'work.material.course.direction',
					'work.material.probeTasks',
					'work.material.probeTasks.task',
					'homeWorkResults',
				],
			},
		},
		control: {
			api: controlApi.get,
			params: {
				filter: [...filter, `[children_id][eq]=${id}`],
				expand: [
					'work.material.course.direction',
					'work.material.controlTasks.task',
					'controlWorkResults',
					'theme.course', 
					'theme.course.direction',
					'theme.controlTasks.task.homeTaskFiles',
				],
			},
		},
		abstract: {
			api: lessonAbstractAPI.get,
			params: {
				filter,
				expand: ['verifiedBy', 'files', 'plan.lesson.topic.course.direction'],
			},
		},
	};

	const {
		state: res,
		getState,
		ref,
		loadingAll,
		loading,
		totalCount
	} = useLoadMore({ ...apiParams[type], last: false });

	let state;

	switch (type) {
		case 'probe':
			state = res?.map((item) => ({
				id: item?.id,
				type: 'probe',
				children: item?.children,
				score: +item?.score,
				completed_date: item?.completed_date,
				expired_date: item?.expired_date,
				direction: item?.work?.material?.course?.direction ?? {},
				lesson: {
					...item?.work?.material,
					lessonTasks: item?.work?.material?.probeTasks,
				},
				group_id: item?.work?.group_id,
				course_id: item?.work?.course_id,
				work_id: item?.work_id,
				decided_right: item?.decided_right,
				homeWorkResults: item.results,

				created_at: item?.created_at,
				deadline_date: item?.deadline_date,
				verified_date: item?.verified_date,
			}));
			break;
		case 'control':
			state = res?.map((item) => ({
				id: item?.id,
				type: 'control',
				children: item?.children,
				score: +item?.score,
				completed_date: item?.completed_date,
				expired_date: item?.expired_date,
				direction: item?.theme?.id ? item?.theme?.course?.direction ?? {} : item?.work?.material?.course?.direction ?? {},
				lesson: {
					...(item?.theme?.id ? item?.theme : item?.work?.material),
					lessonTasks: item?.theme?.id ? item?.theme.controlTasks : item?.work?.material?.controlTasks,
				},
				group_id: item?.work?.group_id,
				course_id: item?.work?.course_id,
				work_id: item?.work_id,
				decided_right: item?.decided_right,
				homeWorkResults: item.controlWorkResults,
				mark: item?.mark,

				created_at: item?.created_at,
				deadline_date: item?.deadline_date,
				verified_date: item?.verified_date,
			}));
			break;
		case 'abstract':
			state = res?.map((item) => ({
				...item,
				type: 'abstract',
				direction: item?.plan?.lesson?.topic?.course?.direction ?? {},
				lesson: { ...item?.plan?.lesson },
			}));
			break;
		default: {
			state = res?.map((item) => {
				if (item?.homeWorkUniqueTasks?.length < 1) return item;
				return {
					...item,
					type: 'unique',
					lesson: {
						lessonTasks: item?.homeWorkUniqueTasks,
						name: 'Уникальное',
					},
				};
			});
			break;
		}
	}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		getState();
	}, [type, filter]);

	const empty = !loading && state?.length === 0 ? 'Таких работ нет' : null;

	return { get: state, ref, loading, loadingAll, empty, totalCount };
};
