import { CheckBoxLabel } from '@UI/CheckBox'

export const CantUploadFiles = ({ data, edit }) => {
    return <CheckBoxLabel
        value={data.cant_upload_files}
        onClick={() =>
            edit((prev) => ({
                ...prev,
                cant_upload_files: !data.cant_upload_files,
            }))
        }
        label='Учащийся не может загрузить файл ответом на это задание'
    />
}
