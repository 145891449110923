
import { useParams } from 'react-router-dom';
import Button from '@UI/Button';
import s from './index.module.css';
import { useAddAlert } from '@/hooks/useAddAlert';
import { surveyApi } from '@/api'
import { appendToFormData } from '@/helpers/new/appendToFormData'
import Block from '../../../../Block';
import Task from '../../Question'
import { useCallback, useMemo, useState } from 'react';
import Add from '../../Common/Add';
import { InputSelect } from "@UI/InputNew/Input";
import { InputWhite } from '@UI/New/InputWhite';
import classNames from 'classnames';
import { PlusIcon } from '@/utils/icons';
import JsonPars from "@UI/JsonPars/JsonPars";

const Filter = ({task, tasks, chengeTasks, 
                 openModal, clickBottom, clickTop, 
                 clickDelete, deletion, changeOrder, 
                 clickEdit, deleteFromModifiedQuestions,
                 modifiedQuestions, showUnsavedItems, pages
                }) => {
    const addAlert = useAddAlert();
    const { id } = useParams();

    const tasksForFilter = useMemo(() => {
        return tasks?.filter(el => 
            (el.type === 'test' || el.type === 'range' || el.type === 'filter') && el.order < task.order
        ).flatMap(el => {
            if (el.type === 'filter') {
                return el.questions.map(elem => ({
                    ...elem // Вернуть все свойства элемента вопроса
                }));
            }
            return el;
        });
    }, [tasks]);

    console.log(tasksForFilter)

    const add = () => {
        openModal('addTaskModal', {
            inFilter: true,
            filterId: task.id
        })
    }

    const addCondition = useCallback(() => chengeTasks({taskId: task.id, task, action:'add', field: 'conditions', value: {field: '', value: '', questionId: ''} }), [chengeTasks, task.id]);
    
    const save = () => {
      
        const formData = new FormData();
        formData.append('type', task.type);
        formData.append('id', task.id);
        formData.append('conditions', JSON.stringify(task.conditions));
        formData.append('surveyId', id);

        surveyApi.updateTask(formData)
                .then(({data}) => {
                    if(data.status == "success") {
                        deleteFromModifiedQuestions(task.id)
                        addAlert({title: 'Успешно', text: 'Фильтр успешно сохранен'})
                    }
                })
                .catch(()=>{
                    addAlert({title: 'Ошибка', text: 'При сохранении произошла ошибка', type: 'error'})
                })
    };

    return <div className={s.wrapper}>
        <div className={s.title}>Фильтр</div>
        <Add className={s.addCondition} textTooltip='Если добавить несколько условий, то вопросы из фильтра будут отображаться при соблюдении любого из них' text='Добавить условие' add={addCondition} />
        {
            task.conditions.map((el) => {
                return (
                    <div key={el.id} className={s.option}>
                        <InputSelect
                            className={s.inputSelect}
                            classNameBox={s.inputSelectBox}
                            classSelectItem={s.selectItem}
                            newDesign 
                            options={ tasksForFilter.map((el) => ({label: <JsonPars json={el.questionText} />, value: el.id})) } 
                            value={el.questionId} 
                            onChange={(e)=> chengeTasks({taskId: task.id, idInArr: el.id , task, field: 'conditions', value: {field: '', questionId: e.target.value} })}
                        />
                        <span className={s.designElement}>-</span>
                        {
                            el.questionId && <Setting key={el.id} tasksForFilter={tasksForFilter} condition={el} task={task} chengeTasks={chengeTasks} questionId={el.questionId} />
                        } 
                        <div onClick={()=> chengeTasks({taskId: task.id, idInArr: el.id, task, field: 'conditions', action: 'remove'})} className={classNames(s.remove)}><PlusIcon /></div>
                    </div>
                )
            })
        }
        {
            modifiedQuestions.includes(task.id) && <Button 
            onClick={save}
            newDesign
            className={s.save}
            >Сохранить условия фильтра</Button>
        }
       
        <div className={s.blocks}>
            {
                task.questions.map((task) => <Block
                    classNameText={classNames({[s.red]: modifiedQuestions.includes(task.id) && showUnsavedItems})} 
                    clickTop={()=>{
                        if(changeOrder) return;
                        clickTop(task)
                    }} 
                    clickBottom={()=>{
                        if(changeOrder) return;
                        clickBottom(task)
                    }} 
                    clickDelete={() => {
                        if(deletion.includes(task.id)) return;
                        clickDelete(task)
                    }}
                    clickEdit={()=>{clickEdit(task)}}
                    className={s.block}
                    key={task.id}> 
                        <Task {...{task, chengeTasks, clickEdit, openModal, deleteFromModifiedQuestions, pages}} /> 
                </Block>)
            }
        </div>

        <Button 
            onClick={add}
            newDesign
            className={s.save}
            >Добавить вопрос в фильтр</Button>
    </div>
}

export default Filter;

const Setting = ({task, condition, tasksForFilter, chengeTasks, questionId}) => {
    const question = tasksForFilter.filter((el) => el.id == questionId)[0];

    if(question?.type=='test'){
        return(
            <InputSelect 
                className={s.inputSelect}
                classNameBox={s.inputSelectBox}
                newDesign 
                classSelectItem={s.selectItem}
                options={ question.answerOptions.map(({id: value, option: label}) => ({label: <JsonPars json={label} />, value})) } 
                value={condition.value} 
                onChange={(e)=> chengeTasks({taskId: task.id, idInArr: condition.id, task, field: 'conditions', value: {field: 'answerOptions', value: e.target.value} })}
            />
        )
    }
    if(question?.type=='range'){
        return(
            <>  
                <button
                    className={classNames(s.bottom, {[s.bottomActive]: condition.direction=='bottom'})}
                    onClick={() => chengeTasks({taskId: task.id, idInArr: condition.id, task, field: 'conditions', value: {direction: 'bottom'} }) }
                >Меньше</button>
                
                <button
                    className={classNames(s.bottom, {[s.bottomActive]: condition.direction=='top'})}
                    onClick={() => chengeTasks({taskId: task.id, idInArr: condition.id, task, field: 'conditions', value: {direction: 'top'} }) }
                >Больше</button>

                <InputWhite 
                    value={condition.value} 
                    onChange={(value) => chengeTasks({taskId: task.id, idInArr: condition.id, task, field: 'conditions', value: {field: 'range', value: value} })} />
            </>
        )
    }
    return(
        <></>
    )
}